// @flow

/**
 * Module dependencies.
 */

import { Icon, Link, color, media, units } from 'pmint-design-system';
import { Route, Switch } from 'react-router-dom';
import Footer from 'client/components/footer';
import React, { type Node } from 'react';
import logoIcon from 'client/assets/svg/logo.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray200')};
  display: flex;
  grid-area: sidebar;
  overflow-x: hidden;
  padding-bottom: ${units(3.25)};

  ${media.min('md')`
    padding-bottom: ${units(2)};
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.header`
  display: grid;
  flex: 1;
  grid-row-gap: ${units(8.125)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content auto;

  ${media.min('md')`
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows:  min-content auto min-content;
  `}
`;

/**
 * Logo style.
 */

const logoStyle = css`
  grid-column: 1 / -1;
  padding-left: ${units(3)};
  padding-top: 18px;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;

  ${logoStyle}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  ${logoStyle}
`;

/**
 * `StyledFooter` styled component.
 */

const StyledFooter = styled(Footer)`
  grid-column: 1 / -1;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `Sidebar` component.
 */

const Sidebar = ({ children }: Props): Node => {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <Switch>
          <Route
            path={[
              routes.register.base,
              routes.setPassword,
              routes.signIn.base
            ]}
          >
            <StyledIcon
              aria-label={translate('sidebar.logoLabel')}
              color={color('bender6')}
              icon={logoIcon}
              size={units(15.75)}
            />
          </Route>

          <Route path={'*'}>
            <StyledLink to={routes.home}>
              <Icon
                aria-label={translate('sidebar.logoLabel')}
                color={color('bender6')}
                icon={logoIcon}
                size={units(15.75)}
              />
            </StyledLink>
          </Route>
        </Switch>

        {children}

        <StyledFooter />
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `Sidebar` component.
 */

export default Sidebar;
