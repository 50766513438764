// @flow

/**
 * Module dependencies.
 */

import { Icon, Type, color, media, states, units } from 'pmint-design-system';
import { Image } from 'react-components/media';
import { theme } from 'styled-tools';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import cardLeft from 'client/assets/images/card-left@2x.png';
import cardRight from 'client/assets/images/card-right@2x.png';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  className?: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('bender6')};
  border-radius: ${units(1)};
  min-height: ${units(17)};
  opacity: 0.87;
  position: relative;
  text-align: center;
  transition: opacity ${theme('transitions.defaultTransition')};

  ${states.interaction`
    opacity: 1;
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.Label).attrs({ as: 'h4' })`
  color: ${color('white')};
  letter-spacing: 0;
  margin-bottom: 23px;
`;

/**
 * `LeftImage` styled component.
 */

const LeftImage = styled(Image)`
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
`;

/**
 * `RightImage` styled component.
 */

const RightImage = styled(Image)`
  height: 100%;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  padding: 26px ${units(5.5)} 23px;
  position: relative;
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * `ArrowIcon` styled component.
 */

const ArrowIcon = styled(Icon)`
  flex-shrink: 0;
  transition: color ${theme('transitions.defaultTransition')};
`;

/**
 * `TakeALookButton` styled component.
 */

const TakeALookButton = styled.a`
  ${theme('typography.styles.label')};

  appearance: none;
  background-color: transparent;
  border: 1px solid ${color.transparentize('gray100', 0.6)};
  border-radius: ${units(1)};
  box-shadow: none;
  color: ${color('white')};
  cursor: pointer;
  display: inline-block;
  flex: 1 1 56.22%;
  font-weight: 400;
  min-height: ${units(5)};
  outline: 0;
  padding: 0 ${units(3)};
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: border-color, opacity;
  width: 100%;

  ${states.interaction`
    border-color: ${color.transparentize('bender1', 1)};

    ${ArrowIcon} {
      color: ${color.transparentize('white', 1)};
    }
  `}

  ${media.min('xs')`
    flex-grow: 0;
  `}

  ${media.min('md')`
    flex-grow: 1;
  `}

  ${media.min('lg')`
    flex-basis: 70%;
    flex-grow: 0;
  `}

  ${media.min('xl')`
    flex-basis: 56.22%;
    flex-grow: 0;
  `}
`;

/**
 * `ButtonContent` styled component.
 */

const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
`;

/**
 * `WalletCard` component.
 */

const WalletCard = (props: Props): Node => {
  const { translate } = useTranslate();

  return (
    <Wrapper {...props}>
      <LeftImage
        defaultUrl={cardLeft}
        maxWidth={'fit-content'}
      />

      <RightImage
        defaultUrl={cardRight}
        maxWidth={'fit-content'}
      />

      <ContentWrapper>
        <RawHtml element={Title}>
          {translate('walletCard.title')}
        </RawHtml>

        <ButtonWrapper>
          <TakeALookButton
            href={config.get('walletUrl')}
            rel={'noopener'}
            target={'_blank'}
          >
            <ButtonContent>
              <Type.Label color={color('white')}>
                {translate('walletCard.takeALookButton')}
              </Type.Label>

              <ArrowIcon
                color={color.transparentize('white', 0.6)}
                icon={arrowIcon}
                size={units(3)}
              />
            </ButtonContent>
          </TakeALookButton>
        </ButtonWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `WalletCard` component.
 */

export default WalletCard;
