// @flow

/**
 * Module dependencies.
 */

import { schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  user: {
    password
  }
} = schemaValidations;

/**
 * Export `changePasswordSchema`.
 */

export default {
  properties: {
    newPassword: password,
    oldPassword: {
      type: 'string'
    },
    repeatPassword: {
      const: {
        $data: '1/newPassword'
      },
      type: 'string'
    }
  },
  required: ['oldPassword', 'newPassword', 'repeatPassword']
};
