// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getDeleteDocumentState`.
 */

export const getDeleteDocumentState = createRequestStateSelector('deleteDocument');
