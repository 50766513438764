// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { submitKycType } from 'client/core/redux/action-types/submit-kyc';

/**
 * Export `submitKyc`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: submitKycType
}));
