// @flow

/**
 * Module dependencies.
 */

import { Alert, Box } from 'pmint-design-system';
import { units } from 'react-components/styles';
import React, { type Node, useCallback, useEffect } from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  id: number,
  onDismiss: (id: number) => void,
  options: Object
|};

/**
 * `SnackbarContent` container.
 */

const SnackbarContent = (props: Props): Node => {
  const { children, id, onDismiss, options } = props;
  const { translate } = useTranslate();
  const handleDismiss = useCallback(() => {
    onDismiss(id);
  }, [id, onDismiss]);

  useEffect(() => {
    setTimeout(() => {
      onDismiss(id);
    }, 3000);
  }, [id, onDismiss]);

  return (
    <Alert
      closeButtonAriaLabel={'common.actions.close'}
      data-test={'snackbarAlert'}
      onDismiss={handleDismiss}
      role={'alert'}
      size={'small'}
      type={options.type}
    >
      <Box
        as={'b'}
        marginRight={units(0.5)}
        textTransform={'uppercase'}
      >
        {translate(`common.alerts.${options.type}`)}
      </Box>

      {children}
    </Alert>
  );
};

/**
 * Export `SnackbarContent` container.
 */

export default SnackbarContent;
