// @flow

/**
 * Module dependencies.
 */

import type { Dispatch } from 'redux';
import { addUserNameAndImageType } from 'client/core/redux/action-types/user';
import { session } from 'client/core/utils/session';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `addUserNameAndImage`.
 */

export function addUserNameAndImage(user: Object) {
  return {
    payload: { user },
    type: addUserNameAndImageType
  };
}

/**
 * Export `addUser`.
 */

export const addUser = (user: Object) => (dispatch: Dispatch) => {
  dispatch(addUserNameAndImage(user));

  session.set(storageKeys.user, JSON.stringify(user));
};
