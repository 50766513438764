// @flow

/**
 * Module dependencies.
 */

import { changePasswordType } from 'client/core/redux/action-types/change-password';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

/**
 * `Options` type.
 */

type Options = {|
  newPassword: string,
  oldPassword: string
|};

/**
 * Export `changePassword`.
 */

export const changePassword = ({ newPassword, oldPassword }: Options) => {
  return createRequestAction({
    apiName: 'app',
    data: { oldPassword, password: newPassword },
    endpoint: 'changePassword',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: changePasswordType
  });
};

/**
 * Export `resetChangePassword`.
 */

export const resetChangePassword = createResetRequestAction({
  method: 'PATCH',
  type: changePasswordType
});
