// @flow

/**
 * Module dependencies.
 */

import { Route, Switch } from 'react-router-dom';
import ConditionalRoute from 'client/components/routing/conditional-route';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import IdentityInformation from './identity-information';
import IdentityVerification from './identity-verification';
import IdentityVerificationSuccess from './identity-verification-success';
import MobileKycRedirect from 'client/containers/mobile-kyc-redirect';
import React, { type Node } from 'react';
import Redirect from 'client/components/routing/redirect';
import TaxInformation from './tax-information';
import config from 'config';
import routes from 'core/routes';

/**
 * Use mobile kyc config.
 */

const useMobileKyc = config.get('useMobileKyc');

/**
 * `Register` container.
 */

const Register = (): Node => {
  if (useMobileKyc) {
    return <MobileKycRedirect />;
  }

  return (
    <ErrorBoundary errorComponent={DefaultErrorMessage}>
      <Switch>
        <Route
          component={IdentityInformation}
          exact
          path={routes.register.identityInformation}
        />

        <Route
          component={TaxInformation}
          exact
          path={routes.register.taxInformation}
        />

        <Route
          component={IdentityVerification}
          exact
          path={routes.register.identityVerification.base}
        />

        <ConditionalRoute
          component={IdentityVerificationSuccess}
          exact
          path={routes.register.identityVerification.success}
        />

        <Redirect
          status={302}
          to={routes.register.identityInformation}
        />
      </Switch>
    </ErrorBoundary>
  );
};

/**
 * Export `Register` container.
 */

export default Register;
