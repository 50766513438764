// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import React, { type Node } from 'react';
import UserCard from './user-card';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  onSignIn: () => void,
  visible: boolean
|};

/**
 * `StyledUserCard` styled component.
 */

const StyledUserCard = styled(UserCard)`
  cursor: pointer;
  padding: 0;
`;

/**
 * `LoginButton` styled component.
 */

const LoginButton = styled(Type.Small).attrs({ as: 'div' })`
  color: ${color('textColor')};
  font-weight: 400;
  min-width: ${units(15.25)};
  padding: ${units(1.25)} ${units(2)};
`;

/**
 * `UserLoggedOut` component.
 */

const UserLoggedOut = ({ onSignIn, visible }: Props): Node => {
  const { translate } = useTranslate();

  return (
    <StyledUserCard
      forwardedAs={'button'}
      isClickable
      onClick={onSignIn}
      visible={visible}
    >
      <LoginButton>
        {translate('common.actions.login')}
      </LoginButton>
    </StyledUserCard>
  );
};

/**
 * Export `UserLoggedOut` component.
 */

export default UserLoggedOut;
