// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { confirmEmailTypes } from 'client/core/redux/action-types/confirm-email';
import { deleteAccountAndIdentityTypes } from 'client/core/redux/action-types/delete-account-and-identity';
import { fetchAccountTypes } from 'client/core/redux/action-types/fetch-account';
import { logoutTypes } from 'client/core/redux/action-types/logout';
import { refreshTokenTypes } from 'client/core/redux/action-types/refresh-token';
import { setAuthenticatedType } from 'client/core/redux/action-types/set-authenticated';
import { signInTypes } from 'client/core/redux/action-types/sign-in';
import { signInWithOptionsTypes } from 'client/core/redux/action-types/sign-in-with-options';
import { submitKycTypes } from 'client/core/redux/action-types/submit-kyc';

/**
 * Export `AuthenticationStatus` type.
 */

export type AuthenticationStatus = 'unknown' | 'unauthenticated' | 'authenticated';

/**
 * Status.
 */

function status(state: AuthenticationStatus = 'unknown', action: Object = {}): AuthenticationStatus {
  const { payload, type } = action;

  switch (type) {
    case confirmEmailTypes.successType:
      return payload?.jwt ? 'authenticated' : 'unauthenticated';

    case refreshTokenTypes.successType:
      return payload?.token ? 'authenticated' : 'unauthenticated';

    case deleteAccountAndIdentityTypes.failureType:
    case setAuthenticatedType:
      return 'authenticated';

    case deleteAccountAndIdentityTypes.successType:
    case fetchAccountTypes.failureType:
    case logoutTypes.successType:
    case refreshTokenTypes.failureType:
      return 'unauthenticated';

    default:
      return state;
  }
}

/**
 * Token.
 */

function token(state: ?string = null, action: Object = {}): ?string {
  const { payload, type } = action;

  switch (type) {
    case confirmEmailTypes.successType:
    case submitKycTypes.successType:
      return payload?.jwt ?? null;

    case refreshTokenTypes.successType:
    case signInWithOptionsTypes.successType:
    case signInTypes.successType:
      return payload?.token ?? null;

    case confirmEmailTypes.failureType:
    case confirmEmailTypes.requestType:
    case deleteAccountAndIdentityTypes.successType:
    case fetchAccountTypes.failureType:
    case logoutTypes.successType:
    case refreshTokenTypes.failureType:
    case signInTypes.failureType:
    case submitKycTypes.failureType:
      return null;

    default:
      return state;
  }
}

/**
 * Export `authentication`.
 */

export default combineReducers({
  status,
  token
});
