// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  children?: Node,
  subtitle?: string,
  title?: string
|};

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  max-width: ${units(41)};
`;

/**
 * `SidebarContent` component.
 */

const SidebarContent = (props: Props): Node => {
  const { children, subtitle, title } = props;
  const { translate } = useTranslate();

  return (
    <>
      <Type.H1
        color={color('bender6')}
        fontWeight={400}
        marginBottom={units(3.5)}
      >
        {title ? title : translate('sidebar.title')}
      </Type.H1>

      <RawHtml element={SubTitle}>
        {subtitle ? subtitle : translate('sidebar.subtitle')}
      </RawHtml>

      {children}
    </>
  );
};

/**
 * Export `SidebarContent` component.
 */

export default SidebarContent;
