// @flow

/**
 * Module dependencies.
 */

import { Box, Type, color, states, units } from 'pmint-design-system';
import { type DocumentType } from 'client/core/types/kyc-documents';
import { documentTypes } from 'core/types/document-types';
import { theme } from 'styled-tools';
import DocumentIcon from './document-icon';
import React, { type Node } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  onSelect: DocumentType => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray100')};
  border-radius: ${units(1)} ${units(1)} 0 0;
`;

/**
 * `Header` styled component.
 */

const Header = styled(Type.Label).attrs({ as: 'div' })`
  align-items: center;
  background-color: ${color('gray300')};
  border-radius: ${units(1)};
  color: ${color('textColor')};
  display: flex;
  font-weight: 400;
  min-height: ${units(8)};
  padding: ${units(2)} ${units(3)};
  position: relative;
`;

/**
 * `OptionList` styled component.
 */

const OptionList = styled.ul`
  margin-top: -${units(1)};
  padding-top: ${units(1)};
`;

/**
 * `OptionContent` styled component.
 */

const OptionContent = styled.div`
  align-items: center;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  min-height: ${units(8)};
  padding: ${units(2)} 0;
`;

/**
 * `StyledDocumentIcon` styled component.
 */

const StyledDocumentIcon = styled(DocumentIcon)`
  color: ${color('gray500')};
  flex-shrink: 0;
  margin-right: ${units(2)};
  transition: color ${theme('transitions.fastTransition')};
`;

/**
 * `Option` styled component.
 */

const Option = styled.li`
  color: ${color('textColorLight')};
  cursor: pointer;
  font-weight: 400;
  outline: none;
  padding: 0 ${units(3)};
  transition: ${theme('transitions.fastTransition')};
  transition-property: background-color, color;

  ${states.interaction`
    background-color: ${color('gray200')};
    color: ${color('textColor')};

    ${StyledDocumentIcon} {
      color: ${color('gray600')};
    }
  `}

  &:first-child {
    margin-top: -${units(1)};
    padding-top: ${units(1)};
  }

  &:not(:last-child) > ${OptionContent} {
    border-bottom: 1px solid ${color('gray200')};
  }
`;

/**
 * `DocumentTypePicker` component.
 */

function DocumentTypePicker({ onSelect }: Props): Node {
  const { translate } = useTranslate();
  const options = [{
    label: translate('kyc.documents.types.identityCard'),
    value: documentTypes.identityCard
  }, {
    label: (
      <span>
        <Box
          as={'span'}
          marginRight={'1ch'}
        >
          {translate('kyc.documents.types.passport')}
        </Box>

        <Type.Small
          as={'span'}
          display={'inline-block'}
          fontWeight={400}
          opacity={0.8}
        >
          {translate('kyc.documents.typeSelection.proofOfAddress')}
        </Type.Small>
      </span>
    ),
    value: documentTypes.passport
  }, {
    label: translate('kyc.documents.types.driverLicense'),
    value: documentTypes.driverLicense
  }, {
    label: translate('kyc.documents.types.residencePermit'),
    value: documentTypes.residencePermit
  }, {
    label: translate('kyc.documents.types.other'),
    value: documentTypes.other
  }];

  return (
    <Wrapper>
      <Header id={'typeSelectionHeader'}>
        {translate('kyc.documents.typeSelection.chooseButtonLabel')}
      </Header>

      <OptionList
        aria-labelledby={'typeSelectionHeader'}
        onFocus={event => {
          event.preventDefault();
          event.target.firstElementChild.focus();
        }}
        role={'listbox'}
        tabIndex={0}
      >
        {options.map(({ label, value }) => (
          <Option
            key={value}
            onClick={() => onSelect(value)}
            onKeyUp={event => {
              switch (event.key) {
                case 'ArrowDown':
                  return event.target.nextElementSibling?.focus();

                case 'ArrowUp':
                  return event.target.previousElementSibling?.focus();

                case 'Enter':
                case 'Space':
                  return onSelect(value);

                default:
                  return;
              }
            }}
            role={'option'}
            tabIndex={0}
          >
            <OptionContent>
              <StyledDocumentIcon type={value} />

              {label}
            </OptionContent>
          </Option>
        ))}
      </OptionList>
    </Wrapper>
  );
}

/**
 * Export `DocumentTypePicker` component.
 */

export default DocumentTypePicker;
