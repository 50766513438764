// @flow

/**
 * Module dependencies.
 */

import { type Node, useEffect } from 'react';
import { getAuthenticationStatus } from 'client/core/redux/selectors/authentication';
import { getLogoutState } from 'client/core/redux/selectors/logout';
import { getUserPreviousSession } from 'client/core/utils/cookies';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import routes from 'core/routes';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `SessionListener` container.
 */

const SessionListener = (): Node => {
  const { data, errors: logoutErrors, isSuccessful } = useSelector(getLogoutState);
  const { accountDelete } = data ?? {};
  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const authenticationStatus = useSelector(getAuthenticationStatus);
  const history = useHistory();

  useEffect(() => {
    if (isSuccessful) {
      showSuccessMessage(
        translate(accountDelete ? 'removeAccountMessage' : 'logoutMessage')
      );
    }
  }, [accountDelete, isSuccessful, showSuccessMessage, translate]);

  useEffect(() => {
    if (authenticationStatus === 'unauthenticated') {
      const previousUser = getUserPreviousSession();

      // Restore previous session.
      if (previousUser) {
        return history.push(routes.signIn.welcomeBack);
      }
    }
  }, [authenticationStatus, history]);

  useNetworkErrorMessage('logout.errors.network', logoutErrors);

  return null;
};

/**
 * Export `SessionListener` container.
 */

export default SessionListener;
