// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getLogoutState`.
 */

export const getLogoutState = createRequestStateSelector('logout');
