// @flow

/**
 * Module dependencies.
 */

import {
  Button,
  Icon,
  Loader,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import type { KycDocument } from 'client/core/types/kyc-documents';
import {
  addDocumentType,
  cancelUploadDocument,
  uploadDocument
} from 'client/core/redux/actions/upload-documents';

import { get, isEmpty, isNil } from 'lodash';
import { getSubmitDocumentsState } from 'client/core/redux/selectors/submit-documents';
import { useDispatch, useSelector } from 'react-redux';
import DocumentPicker from 'client/components/documents/document-picker';
import DocumentTypePicker from 'client/components/documents/document-type-picker';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  documents: Array<KycDocument>,
  isSubmitting: boolean,
  onDeleteDocument?: (id: string) => void,
  onSubmitDocuments: () => Promise<any>
|};

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Paragraph).attrs({ as: 'h2' })`
  color: ${color('textColorLight')};
  margin-bottom: 52px;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  float: right;
  justify-content: space-between;
  margin-top: 57px;
  min-height: ${units(8)};
  width: 100%;

  ${media.min('xs')`
    width: ${units(30)};
  `}
`;

/**
 * `UploadDocuments` component.
 */

const UploadDocuments = (props: Props): Node => {
  const {
    documents,
    isSubmitting,
    onDeleteDocument,
    onSubmitDocuments
  } = props;

  const dispatch = useDispatch();
  const remoteDocuments = documents.filter(document => document.isRemote);
  const uploadedDocuments = documents.filter(document => document.isCompleted);
  const isDisabled = uploadedDocuments.length !== 2 && isEmpty(remoteDocuments) || uploadedDocuments.length !== 1 && remoteDocuments.length === 1;
  const [documentsErrors, setDocumentsErrors] = useState(null);
  const [documentType, setDocumentType] = useState(() => documents?.[0]?.type ?? null);
  const { errors, isLoading } = useSelector(getSubmitDocumentsState);
  const { translate } = useTranslate();
  const handleAddDocumentType = useCallback(documentType => {
    if (documentType) {
      dispatch(addDocumentType(documentType));

      return setDocumentType(documentType);
    }
  }, [dispatch]);

  const handleChangeDocumentType = useCallback(() => {
    setDocumentsErrors(null);

    return setDocumentType(null);
  }, []);

  const handleCancelUploadDocument = useCallback(id => {
    setDocumentsErrors(null);

    return dispatch(cancelUploadDocument(id));
  }, [dispatch]);

  const handleUploadDocument = useCallback((id, file) => {
    return dispatch(uploadDocument({ file, id }));
  }, [dispatch]);

  useEffect(() => {
    if (errors) {
      return setDocumentsErrors(errors);
    }
  }, [errors]);

  const fileExtensionErrors = get(documentsErrors, 'errors.documents', [])
    .find(document => document.errorCode === 'invalid_file_extension_provided');

  const networkDocumentsErrors = fileExtensionErrors ? { code: fileExtensionErrors.errorCode } : documentsErrors;

  useNetworkErrorMessage('kyc.documents.errors.submitDocuments', networkDocumentsErrors);

  return (
    <>
      <RawHtml element={SubTitle}>
        {translate('kyc.documents.typeSelection.description')}
      </RawHtml>

      {isNil(documentType) ? (
        <DocumentTypePicker onSelect={handleAddDocumentType} />
      ) : (
        <>
          <DocumentPicker
            documents={documents}
            isSubmitting={isSubmitting}
            onCancelUploadDocument={handleCancelUploadDocument}
            onChangeDocumentType={handleChangeDocumentType}
            onDeleteDocument={onDeleteDocument}
            onUploadDocument={handleUploadDocument}
          />

          <StyledButton
            disabled={isDisabled}
            onClick={onSubmitDocuments}
          >
            {translate('common.actions.submit')}

            {isLoading || isSubmitting ? <Loader /> : (
              <Icon
                color={color('white')}
                icon={arrowIcon}
                size={units(3)}
              />
            )}
          </StyledButton>
        </>
      )}
    </>
  );
};

/**
 * Export `UploadDocuments` component.
 */

export default UploadDocuments;
