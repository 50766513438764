// @flow

/**
 * Module dependencies.
 */

import * as Sentry from '@sentry/browser';
import type {
  ConfigureScopeOptions,
  InitClientOptions,
  Options
} from 'core/types/sentry';

import type { ErrorInfo } from 'client/components/core/error-boundaries/error-boundary';
import { isNil, isUndefined, omitBy } from 'lodash';
import config from 'config';
import packageJson from 'package.json';
import yn from 'yn';

/**
 * `InitOptions` type.
 */

type InitOptions = {|
  dsn: ?boolean | string,
  options: Options,
  reportDialog: boolean
|};

/**
 * `ShowReportDialogOptions` type.
 */

type ShowReportDialogOptions = {|
  sentryEventId: ?string
|};

/**
 * `User` type.
 */

type User = {|
  email: ?string
|};

/**
 * `CaptureExceptionOptions` type.
 */

type CaptureExceptionOptions = {|
  error: ?Error,
  errorInfo?: ErrorInfo,
  severity?: string,
  user: User
|};

/**
 * Sentry configurations.
 */

const {
  dsn: {
    client: dsn
  },
  extras,
  init,
  reportDialog,
  tags
} = config.get('sentry');

/**
 * `SentryBrowserClient`.
 */

class SentryBrowserClient {

  /**
   * Is dsn enabled.
   */

  isDsnEnabled: boolean;

  /**
   * Is report dialog enabled.
   */

  isReportDialogEnabled: boolean;

  /**
   * Constructor.
   */

  constructor(isDsnEnabled: boolean, isReportDialogEnabled: boolean) {
    this.isDsnEnabled = isDsnEnabled;
    this.isReportDialogEnabled = isReportDialogEnabled;
  }

  /**
   * Initializes the `SentryBrowserClient`.
   */

  init = (initOptions: InitOptions) => {
    const { dsn, options, reportDialog = false } = initOptions ?? {};

    if (isNil(dsn) || !dsn) {
      return;
    }

    const init = options?.init ?? {};
    const tags = options?.tags ?? {};
    const extras = options?.extras ?? {};

    this.isDsnEnabled = true;
    this.isReportDialogEnabled = yn(reportDialog);
    this.initClient({ dsn, init });
    this.configureScope({ extras, tags });
  };

  /**
   * Initializes the `Sentry` client with the given `dsn` and `init` options.
   */

  initClient = (initClientOptions: InitClientOptions) => {
    const { dsn, init = {} } = initClientOptions;

    Sentry.init({ dsn, ...init });
  }

  /**
   * Configures the global scope for the client events according to the given
   * `extras` and `tags` options.
   */

  configureScope = (configureScopeOptions: ConfigureScopeOptions) => {
    const { extras = {}, tags = {} } = configureScopeOptions;

    Sentry.setTags({
      name: `${packageJson?.name}:client`,
      version: packageJson?.version,
      ...tags
    });

    Sentry.setExtras({
      dependencies: packageJson?.dependencies,
      ...extras
    });
  }

  /**
   * Informs if the report dialog feature is enabled or disabled.
   */

  isReportDialogToShow = () => this.isDsnEnabled && this.isReportDialogEnabled;

  /**
   * Shows the event report dialog through `Sentry`.
   */

  showReportDialog = (showReportDialogOptions: ShowReportDialogOptions) => {
    const { sentryEventId } = showReportDialogOptions;

    Sentry.showReportDialog({ eventId: sentryEventId });
  }

  /**
   * Captures exception with specific event scope.
   */

  captureException = (captureExceptionOptions: CaptureExceptionOptions) => {
    const {
      error,
      errorInfo,
      severity = 'error',
      user
    } = captureExceptionOptions;

    if (!this.isDsnEnabled) {
      return;
    }

    let eventId;

    Sentry.withScope(scope => {
      scope.setLevel(severity);
      scope.setUser(omitBy(user, isUndefined));
      scope.setExtras(errorInfo);

      eventId = Sentry.captureException(error);
    });

    return eventId;
  }

}

/**
 * Sentry.
 */

const sentry = new SentryBrowserClient(false, false);

/**
 * Sentry initialization.
 */

sentry.init({ dsn, options: { extras, init, tags }, reportDialog });

/**
 * Export `sentry`.
 */

export default sentry;
