// @flow

/**
 * Module dependencies.
 */

import { addUser } from 'client/core/redux/actions/user';
import {
  fetchAccount,
  resetFetchAccount
} from 'client/core/redux/actions/fetch-account';

import { getIsInitialized } from 'client/core/redux/selectors/initialize';
import { getUserPreviousSession } from 'client/core/utils/cookies';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { session } from 'client/core/utils/session';
import { storageKeys } from 'core/utils/storage-keys';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from 'client/components/core/loading';
import React, { type Node, useEffect } from 'react';
import routes from 'core/routes';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `FetchUser` container.
 */

const FetchUser = ({ children }: Props): Node => {
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const isInitialized = useSelector(getIsInitialized);

  useEffect(() => {
    if (!isInitialized) {
      dispatch(fetchAccount())
        .then(() => {
          const user = session.get(storageKeys.user);

          if (user) {
            dispatch(addUser(JSON.parse(user)));
          }

          dispatch(resetFetchAccount());
        })
        .catch(error => {
          const previousUser = getUserPreviousSession();

          // Restore previous session on initialize.
          if (error?.code === 'invalid_token' && previousUser) {
            return history.push(routes.signIn.welcomeBack);
          }
        });
    }
  }, [dispatch, history, isAuthenticated, isInitialized]);

  if (!isInitialized && isAuthenticated) {
    return <Loading isVisible />;
  }

  return children;
};

/**
 * Export `FetchUser` container.
 */

export default FetchUser;
