// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { fetchDepositType } from 'client/core/redux/action-types/fetch-deposit';

/**
 * `Props` type.
 */

type Props = {|
  followUpToken: string
|};

/**
 * Export `fetchDeposit`.
 */

export const fetchDeposit = ({ followUpToken }: Props) => {
  return createRequestAction({
    apiName: 'app',
    endpoint: {
      name: 'depositByFollowUpToken',
      params: { followUpToken }
    },
    handleResponse: ({ data }) => data,
    type: fetchDepositType
  });
};

/**
 * Export `resetFetchDeposit`.
 */

export const resetFetchDeposit = createResetRequestAction({
  type: fetchDepositType
});
