// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchUserInformationType } from 'client/core/redux/action-types/fetch-user-information';
import { omit } from 'lodash';

/**
 * Export `fetchUserInformation`.
 */

export default combineReducers(omit(createRequestReducers({
  type: fetchUserInformationType
}), ['data']));
