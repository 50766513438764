// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { submitIdentityType } from 'client/core/redux/action-types/submit-identity';

/**
 * Export `submitIdentity`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: submitIdentityType
}));
