// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import {
  resetPasswordTokenType
} from 'client/core/redux/action-types/fetch-reset-password-token';

/**
 * Export `fetchResetPasswordToken`.
 */

export default combineReducers(createRequestReducers({
  type: resetPasswordTokenType
}));
