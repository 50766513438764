// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { fetchAccountTypes } from 'client/core/redux/action-types/fetch-account';

/**
 * Export `isInitialized`.
 */

export function isInitialized(state: boolean = false, action: Object = {}): boolean {
  const { type } = action;

  switch (type) {
    case fetchAccountTypes.successType:
      return true;

    case fetchAccountTypes.failureType:
      return false;

    default:
      return state;
  }
}

/**
 * Export `initialize`.
 */

export default combineReducers({
  isInitialized
});
