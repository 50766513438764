// @flow

/**
 * Module dependencies.
 */

import { Icon, Type, color, units } from 'pmint-design-system';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import alertIcon from 'client/assets/svg/alert-72px.svg';
import useTranslate from 'client/hooks/use-translate';

/**
 * `RemoveAccountConfirm` component.
 */

const RemoveAccountConfirm = (): Node => {
  const { translate } = useTranslate();

  return (
    <>
      <Icon
        color={color('warning')}
        icon={alertIcon}
        size={'89px'}
      />

      <Type.H5
        as={'h2'}
        color={color('warning')}
        fontWeight={400}
        marginBottom={units(5)}
        paddingTop={units(1.5)}
      >
        {translate('profile.removeAccount.confirm.title')}
      </Type.H5>

      <RawHtml
        as={'p'}
        color={color('textColorLight')}
        element={Type.Paragraph}
        fontWeight={400}
        marginBottom={units(6.5)}
      >
        {translate('profile.removeAccount.confirm.description')}
      </RawHtml>
    </>
  );
};

/**
 * Export `RemoveAccountConfirm` component.
 */

export default RemoveAccountConfirm;
