// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchAccountType`.
 */

export const fetchAccountType = 'FETCH_ACCOUNT';

/**
 * Export `fetchAccountTypes`.
 */

export const fetchAccountTypes = createRequestActionTypes({
  type: fetchAccountType
});
