// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import Loading from 'client/components/core/loading';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `PrivacyPolicyPageContent` styled component.
 */

const PrivacyPolicyPageContent = styled.article`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-top: 67px;
  text-align: left;

  ${media.min('md')`
    margin-bottom: 67px;
  `}

  header > p {
    font-size: 18px;
    line-height: 28px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: ${units(5)};
    margin-top: 63px;

    ~ p {
      ${media.min('sm')`
        margin-left: 31px;
      `}
    }
  }

  ol {
    margin-bottom: ${units(5)};
    margin-top: ${units(5)};
    padding-left: 0;

    ${media.min('sm')`
      padding-left: 31px;
    `}

    li {
      margin-bottom: ${units(1)};
    }
  }

  ol[type='i'] {
    counter-reset: roman;
    list-style-type: none;

    > li {
      counter-increment: roman;

      &::before {
        content: "(" counters(roman, ".", lower-roman) ")";
        display: inline-block;
        width: 30px;
      }
    }
  }

  ol[type='a'] {
    counter-reset: alpha;
    list-style-type: none;

    > li {
      counter-increment: alpha;

      &::before {
        content: counters(alpha, ".", lower-alpha) ")";
        display: inline-block;
        width: 30px;
      }
    }
  }

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;

    &:hover, &:focus {
      color: ${color('gray600')};
    }
  }
`;

/**
 * `PrivacyPolicyContent` component.
 */

const PrivacyPolicyContent = (): Node => {
  const { ready, translate } = useTranslate('privacy-policy');

  if (!ready) {
    return <Loading isVisible />;
  }

  return (
    <RawHtml element={PrivacyPolicyPageContent}>
      {translate('contentPage', {
        supportEmail: config.get('supportEmail')
      })}
    </RawHtml>
  );
};

/**
 * Export `PrivacyPolicyContent` component.
 */

export default PrivacyPolicyContent;
