// @flow

/**
 * Module dependencies.
 */

import {
  Box,
  Link,
  Separator,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import { Trans } from 'react-i18next';
import { cookiesStorage, getUserPreviousSession } from 'client/core/utils/cookies';
import {
  fetchAuthProviders,
  resetFetchAuthProviders
} from 'client/core/redux/actions/fetch-auth-providers';

import { filter } from 'lodash';
import {
  getFetchAuthProvidersState
} from 'client/core/redux/selectors/fetch-auth-providers';

import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { storageKeys } from 'core/utils/storage-keys';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from 'client/components/core/loading';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useMemo } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import SignInForm from 'client/components/sign-in/sign-in-form';
import SignInOptions from 'client/components/sign-in/sign-in-options';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMesssage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;
  height: 100%;

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-self: center;
  grid-column: 2;
  justify-self: center;
  max-width: ${units(60)};
  position: relative;
  width: 100%;
`;

/**
 * `DifferentAccountLabel` styled component.
 */

const DifferentAccountLabel = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  line-height: ${units(3)};
  margin-right: ${units(0.5)};
  padding-bottom: 27px;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  font-weight: 500;
  text-decoration: underline;
`;

/**
 * `Separator` styled component.
 */

const StyledSeparator = styled(Separator)`
  margin-bottom: ${units(4)};
`;

/**
 * `WelcomeBack` container.
 */

const WelcomeBack = (): Node => {
  const { translate } = useTranslate();
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const previousUser = useMemo(getUserPreviousSession, []);
  const isPreviousSignInEmailPassword = previousUser?.identityProvider === 'pmint';
  const authProvidersState = useSelector(getFetchAuthProvidersState);
  const filteredOptions = useMemo(() => filter(
    authProvidersState?.data,
    { key: previousUser?.identityProvider }
  ), [previousUser, authProvidersState]);

  const deletePreviousSession = useCallback(() => {
    cookiesStorage.remove(storageKeys.previousSession);
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      return history.push(routes.profile.base);
    }

    if (!isAuthenticated && !previousUser) {
      return history.push(routes.profile.base);
    }
  }, [history, isAuthenticated, previousUser]);

  useEffect(() => {
    dispatch(fetchAuthProviders());

    return () => dispatch(resetFetchAuthProviders());
  }, [dispatch]);

  useNetworkErrorMesssage('signIn.authProviders.errors', authProvidersState?.errors);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper>
        <Line />

        <ContentWrapper>
          {authProvidersState?.isLoading ? <Loading isVisible /> : (
            <>
              <Type.H4
                as={'h1'}
                color={color('bender2')}
                marginBottom={units(1)}
              >
                {translate('signIn.welcomeBack.title', { name: previousUser?.name ?? '' })}
              </Type.H4>

              <RawHtml
                element={Type.Paragraph}
                marginBottom={units(4.5)}
              >
                {translate(`signIn.welcomeBack.lead.${isPreviousSignInEmailPassword ? 'email' : 'provider'}`, {
                  email: previousUser?.email,
                  provider: previousUser?.identityProvider
                })}
              </RawHtml>

              <Box marginBottom={units(4)}>
                {isPreviousSignInEmailPassword ? (
                  <SignInForm
                    hideEmail
                    initialValues={{
                      email: previousUser?.email,
                      password: null
                    }}
                  />
                ) : (
                  <SignInOptions options={filteredOptions} />
                )}
              </Box>

              <StyledSeparator />

              <DifferentAccountLabel>
                <Trans
                  components={[
                    <StyledLink
                      colorTheme={'secondary'}
                      key={'register'}
                      onClick={deletePreviousSession}
                      to={routes.signIn.base}
                    />,
                    <StyledLink
                      colorTheme={'secondary'}
                      key={'register'}
                      onClick={deletePreviousSession}
                      to={routes.register.base}
                    />
                  ]}
                  i18nKey={'signIn.welcomeBack.differentAccount'}
                />
              </DifferentAccountLabel>
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `WelcomeBack` container.
 */

export default WelcomeBack;
