// @flow

/**
 * Module dependencies.
 */

import type { KycDocument } from 'client/core/types/kyc-documents';
import { Type, color, units } from 'pmint-design-system';
import { isEmpty, isNil, noop, partition } from 'lodash';
import Document from './document';
import DocumentUploader from './document-uploader';
import FilePicker from './file-picker';
import IconButton from 'client/components/core/icon-button';
import React, { type Node } from 'react';
import backIcon from 'client/assets/svg/back-32px.svg';
import config from 'config';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  documents: Array<KycDocument>,
  isSubmitting: boolean,
  onCancelUploadDocument: (id: string) => void,
  onChangeDocumentType: () => void,
  onDeleteDocument?: (id: string) => void,
  onUploadDocument: (id: string, file: File) => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray100')};
  border-radius: ${units(1)};
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  background-color: ${color('gray200')};
  border-radius: ${units(1)};
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  min-height: ${units(8)};
  padding: ${units(1)} ${units(2)} ${units(1)} ${units(3)};
  text-align: left;
  width: 100%;
`;

/**
 * `FilesWrapper` styled component.
 */

const FilesWrapper = styled.div`
  padding: ${units(3)};

  > :not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `FilePickersWrapper` styled component.
 */

const FilePickersWrapper = styled.div`
  display: grid;
  grid-gap: ${units(2)};
  grid-template-columns: repeat(auto-fit, minmax(${units(20)}, 1fr));
`;

/**
 * `UploadsWrapper` styled component.
 */

const UploadsWrapper = styled.div`
  > :not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `DocumentPicker` component.
 */

function DocumentPicker(props: Props): Node {
  const {
    documents,
    isSubmitting,
    onCancelUploadDocument,
    onChangeDocumentType,
    onDeleteDocument = noop,
    onUploadDocument
  } = props;

  const { translate } = useTranslate();
  const documentErrors = documents.find(document => !isNil(document.errors));
  const documentsType = documents[0].type;
  const maxFileSize = config.get('fileSizes.userDocuments');
  const [fileUploads, filePickers] = partition(documents, document => {
    return document.isRemote || document.isCompleted || document.isLoading;
  });

  useNetworkErrorMessage('kyc.documents.upload.errors', documentErrors?.errors);

  return (
    <Wrapper>
      <Header>
        <Type.Paragraph
          as={'h3'}
          fontWeight={500}
        >
          {translate(`kyc.documents.types.${documentsType}`)}
        </Type.Paragraph>

        <IconButton
          aria-label={translate('kyc.documents.changeDocumentTypeLabel')}
          disabled={isSubmitting || documents.some(({ isLoading, isRemote }) => isLoading || isRemote)}
          icon={backIcon}
          onClick={onChangeDocumentType}
        />
      </Header>

      <FilesWrapper>
        {!isEmpty(filePickers) && (
          <>
            <FilePickersWrapper>
              {filePickers.map(({ id, side, type }) => (
                <FilePicker
                  id={id}
                  key={id}
                  label={translate([
                    `kyc.documents.fileLabels.${type}.${side}`,
                    `kyc.documents.fileLabels.other.${side}`
                  ])}
                  maxFileSize={maxFileSize}
                  name={side}
                  onFile={file => onUploadDocument(id, file)}
                />
              ))}
            </FilePickersWrapper>

            <Type.Small
              as={'p'}
              color={color('textColor')}
              fontWeight={400}
            >
              {translate('kyc.documents.fileUpload.maxFileSize', { maxFileSize })}
            </Type.Small>
          </>
        )}

        {!isEmpty(fileUploads) && (
          <UploadsWrapper>
            {fileUploads.map(document => {
              return document.isRemote ? (
                <Document
                  document={document}
                  key={document.id}
                  onDeleteDocument={() => onDeleteDocument(document.id)}
                />
              ) : (
                <DocumentUploader
                  errors={document.errors}
                  // $FlowFixMe
                  file={document.file}
                  isSubmitting={isSubmitting}
                  key={document.id}
                  onCancel={() => onCancelUploadDocument(document.id)}
                  progress={document.progress}
                />
              );
            })}
          </UploadsWrapper>
        )}
      </FilesWrapper>
    </Wrapper>
  );
}

/**
 * Export `DocumentPicker` component.
 */

export default DocumentPicker;
