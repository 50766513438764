// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { logoutType } from 'client/core/redux/action-types/logout';
import { removeUserSession } from 'client/core/utils/cookies';
import { session } from 'client/core/utils/session';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `logout`.
 */

export const logout = (options: ?{| accountDelete: boolean |}) => {
  return createRequestAction({
    apiName: 'account',
    endpoint: 'revokeToken',
    handleResponse: ({ data }) => {
      removeUserSession();

      if (session.get(storageKeys.user)) {
        session.remove(storageKeys.user);
      }

      return {
        ...data,
        accountDelete: options?.accountDelete
      };
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: logoutType
  });
};

/**
 * Export `resetLogout`.
 */

export const resetLogout = createResetRequestAction({
  method: 'POST',
  type: logoutType
});
