// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `deleteDocumentType`.
 */

export const deleteDocumentType = 'DELETE_DOCUMENT';

/**
 * Export `deleteDocumentTypes`.
 */

export const deleteDocumentTypes = createRequestActionTypes({
  method: 'DELETE',
  type: deleteDocumentType
});
