// @flow

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { getDeleteAccountAndIdentityState } from 'client/core/redux/selectors/delete-account-and-identity';
import { getFetchAccountState } from 'client/core/redux/selectors/fetch-account';
import { getLogoutState } from 'client/core/redux/selectors/logout';
import { getSubmitKycState } from 'client/core/redux/selectors/submit-kyc';
import { ifProp } from 'styled-tools';
import { isNil } from 'lodash';
import { logout } from 'client/core/redux/actions/logout';
import { resetSubmitIdentity } from 'client/core/redux/actions/submit-identity';
import { resetSubmitKyc } from 'client/core/redux/actions/submit-kyc';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslatedOptions } from 'core/utils/locales';
import AddressCountryField from './address-country-field';
import AddressStateField from './address-state-field';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import React, { type Node, useCallback, useEffect } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import StateField from './state-field';
import SubmitButton from 'client/components/core/forms/submit-button';
import routes from 'core/routes';
import styled from 'styled-components';
import taxInformationSchema from 'client/components/register/tax-information-schema';
import useNetworkErrorMesssage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * Initial values.
 */

const initialValues = {
  'address.city': undefined,
  'address.country': undefined,
  'address.postalCode': undefined,
  'address.state': undefined,
  'address.street': undefined,
  'address.street2': undefined,
  'tax.country': undefined,
  'tax.idNumber': undefined,
  'tax.state': undefined
};

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;

  ${media.min('sm')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(${ifProp('threeColumns', 3, 2)}, 1fr);
  `}
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 45px;
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  flex: 1 1 ${units(30)};

  ${media.min('xs')`
    flex-grow: 0;
  `}
`;

/**
 * `TaxInformationForm` component.
 */

const TaxInformationForm = (): Node => {
  const { errors } = useSelector(getSubmitKycState);
  const {
    isLoading: isDeleteAccountAndIdentityLoading,
    isSuccessful: isDeleteAccountAndIdentitySuccessful
  } = useSelector(getDeleteAccountAndIdentityState);

  const {
    isLoading: isFetchAccountLoading
  } = useSelector(getFetchAccountState);

  const {
    isLoading: isLogoutLoading
  } = useSelector(getLogoutState);

  const { translate } = useTranslate();
  const countriesOptions = useTranslatedOptions('countries');
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const handleSubmit = useCallback(values => {
    if (state) {
      return history.push(routes.register.identityVerification.base, {
        taxData: values,
        userData: state.userData
      });
    }
  }, [history, state]);

  const isDisabled = isFetchAccountLoading || isDeleteAccountAndIdentityLoading || isLogoutLoading;

  useEffect(() => {
    if (isDeleteAccountAndIdentitySuccessful) {
      dispatch(logout());
    }
  }, [dispatch, isDeleteAccountAndIdentitySuccessful]);

  useEffect(() => {
    if (isNil(state)) {
      return history.push(routes.register.identityInformation);
    }
  }, [history, state]);

  useEffect(() => {
    return () => {
      dispatch(resetSubmitIdentity());
      dispatch(resetSubmitKyc());
    };
  }, [dispatch]);

  useNetworkErrorMesssage(translate('register.network.errors'), errors);

  return (
    <Form
      errorsBasePath={'register.form.errors.taxInformation'}
      initialValues={initialValues}
      jsonSchema={taxInformationSchema}
      onSubmit={handleSubmit}
    >
      <FieldsWrapper>
        <SelectField
          disabled={isDisabled}
          items={countriesOptions}
          label={translate('kyc.form.fields.country')}
          name={'tax.country'}
        />

        <StateField
          countryFieldName={'tax.country'}
          disabled={isDisabled}
          stateFieldName={'tax.state'}
        />
      </FieldsWrapper>

      <InputField
        disabled={isDisabled}
        label={translate('kyc.form.fields.idNumber')}
        name={'tax.idNumber'}
      />

      <Type.Label
        as={'h2'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={'27px'}
        marginTop={'21px'}
      >
        {translate('kyc.homeAddressInformation')}
      </Type.Label>

      <FieldsWrapper>
        <AddressCountryField
          disabled={isDisabled}
          items={countriesOptions}
        />
      </FieldsWrapper>

      <InputField
        disabled={isDisabled}
        label={translate('kyc.form.fields.street1')}
        name={'address.street'}
      />

      <InputField
        disabled={isDisabled}
        label={translate('kyc.form.fields.street2')}
        name={'address.street2'}
      />

      <FieldsWrapper threeColumns>
        <InputField
          disabled={isDisabled}
          label={translate('kyc.form.fields.city')}
          name={'address.city'}
        />

        <InputField
          disabled={isDisabled}
          label={translate('kyc.form.fields.postalCode')}
          name={'address.postalCode'}
        />

        <AddressStateField
          countryFieldName={'address.country'}
          disabled={isDisabled}
          stateFieldName={'address.state'}
        />
      </FieldsWrapper>

      <SubmitButtonWrapper>
        <StyledSubmitButton fullWidth>
          {translate('common.actions.next')}
        </StyledSubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
};

/**
 * Export `TaxInformationForm` component.
 */

export default TaxInformationForm;
