// @flow

/**
 * Module dependencies.
 */

import { type Dispatch, State } from 'react-redux';
import { changeImage } from 'client/core/redux/actions/change-image';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { getUploadedImage } from 'client/core/redux/selectors/upload-image';
import { uploadImage } from 'client/core/redux/actions/upload-image';

/**
 * `Values` type.
 */

type Values = {|
  file: File
|};

/**
 * Export `performChangeImage`.
 */

export const performChangeImage = (values: Values) => (dispatch: Dispatch, getState: State) => {
  return Promise.resolve(dispatch(uploadImage(values))).then(() => {
    const { fields } = getUploadedImage(getState());

    if (fields) {
      return dispatch(changeImage({ pathKey: fields.key })).then(() => {
        return dispatch(fetchAccount());
      });
    }

    return;
  });
};
