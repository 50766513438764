// @flow

/**
 * Module dependencies.
 */

import { Route, Redirect as RouterRedirect } from 'react-router-dom';
import React, { type Node } from 'react';

/**
 * `Props` type.
 *
 * These are all props from react-router's Redirect plus `status`.
 */

type Props = {|
  exact?: boolean,
  from?: string,
  push?: boolean,
  status: number,
  strict?: boolean,
  to: string | Object
|};

/**
 * `Redirect` component.
 */

function Redirect(props: Props): Node {
  const { status, ...rest } = props;

  return (
    <Route
      render={({ staticContext }: Object): Node => {
        // The `staticContext` object only exists on the server and in this case
        // is used to store the HTTP status code that should be set in the response.
        if (staticContext) {
          staticContext.status = status;
        }

        return <RouterRedirect {...rest} />;
      }}
    />
  );
}

/**
 * Export `Redirect` component.
 */

export default Redirect;
