// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { recoverPasswordType } from 'client/core/redux/action-types/recover-password';

/**
 * `Options` type.
 */

type Options = {
  email: string
};

/**
 * Export `recoverPassword`.
 */

export const recoverPassword = ({ email }: Options) => createRequestAction({
  apiName: 'app',
  data: { email },
  endpoint: 'requestPasswordReset',
  handleResponse: response => response.data,
  method: 'POST',
  type: recoverPasswordType
});

/**
 * Export `resetRecoverPassword`.
 */

export const resetRecoverPassword = createResetRequestAction({
  method: 'POST',
  type: recoverPasswordType
});
