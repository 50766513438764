// @flow

/**
 * Module dependencies.
 */

import { Icon, Type, color, units } from 'pmint-design-system';
import Avatar from 'client/components/core/avatar';
import React, { type Node } from 'react';
import errorIcon from 'client/assets/svg/error-64px.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  title: string
|};

/**
 * `AvatarWrapper` styled component.
 */

const AvatarWrapper = styled.div`
  margin-bottom: ${units(2)};
  text-align: center;
`;

/**
 * `ErrorMessage` component.
 */

function ErrorMessage({ children, title }: Props): Node {
  return (
    <>
      <AvatarWrapper>
        <Avatar ariaHidden>
          <Icon
            color={color('errorDark')}
            icon={errorIcon}
            size={units(8)}
          />
        </Avatar>
      </AvatarWrapper>

      <Type.H5
        as={'h2'}
        color={color('errorDark')}
        fontWeight={400}
        marginBottom={units(1.5)}
        textAlign={'center'}
      >
        {title}
      </Type.H5>

      {children}
    </>
  );
}

/**
 * Export `ErrorMessage` component.
 */

export default ErrorMessage;
