// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import { theme } from 'styled-tools';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  grid-column: 2;
`;

/**
 * `FollowUpLayout` container.
 */

const FollowUpLayout = ({ children }: Props): Node => {
  return (
    <Wrapper>
      <Line />

      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `FollowUpLayout` container.
 */

export default FollowUpLayout;
