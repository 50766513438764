// @flow

/**
 * Module dependencies.
 */

import { get } from 'lodash';

/**
 * Export `getIsInitialized`.
 */

export const getIsInitialized = (state: Object): boolean => {
  return get(state, 'initialize.isInitialized');
};
