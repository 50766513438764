// @flow

/**
 * Module dependencies.
 */

import { changeImageType } from 'client/core/redux/action-types/change-image';
import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `changeImage`.
 */

export default combineReducers(createRequestReducers({
  method: 'PATCH',
  type: changeImageType
}));
