// @flow

/**
 * Module dependencies.
 */

import type { Node } from 'react';
import { canUseDOM } from './dom';
import { createPortal } from 'react-dom';

/**
 * Export `createReactPortal`.
 */

export function createReactPortal(children: Node, elementId: string) {
  if (!canUseDOM()) {
    return null;
  }

  // $FlowFixMe
  return createPortal(children, document.getElementById(elementId));
}
