// @flow

/**
 * Module dependencies.
 */

import { Route, Switch } from 'react-router-dom';
import CancelDeposit from 'client/containers/follow-up/cancel-deposit';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import DepositExecuted from 'client/containers/follow-up/deposit-executed';
import DepositExecutedSoon from 'client/containers/follow-up/deposit-executed-soon';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import PageLayout from 'client/components/page-layout';
import React, { type Node } from 'react';
import Redirect from 'client/components/routing/redirect';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import routes from 'core/routes';

/**
 * `FollowUp` container.
 */

const FollowUp = (): Node => {
  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <ErrorBoundary errorComponent={DefaultErrorMessage}>
        <Switch>
          <Route
            component={DepositExecuted}
            exact
            path={routes.followUp.depositExecuted}
          />

          <Route
            component={DepositExecutedSoon}
            exact
            path={routes.followUp.depositExecutedSoon}
          />

          <Route
            component={CancelDeposit}
            exact
            path={routes.followUp.cancelDeposit}
          />

          <Redirect
            status={302}
            to={routes.home}
          />
        </Switch>
      </ErrorBoundary>
    </PageLayout>
  );
};

/**
 * Export `FollowUp` container.
 */

export default FollowUp;
