// @flow

/**
 * Module dependencies.
 */

import { documentTypes } from 'core/types/document-types';
import { schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  identity: {
    address,
    addressUS,
    taxPerson,
    taxPersonUS
  }
} = schemaValidations;

/**
 * Export `TaxPersonResolverRequest` type.
 */

export type TaxPersonResolverRequest = {|
  country: ?string,
  idNumber: ?string,
  state?: ?string
|};

/**
 * Export `addressFullResolver`.
 */

export const addressFullResolver = (country: ?string) => {
  if (country === 'US') {
    return {
      properties: {
        ...addressUS.properties,
        street2: { type: 'string' }
      },
      required: ['city', 'country', 'postalCode', 'state', 'street']
    };
  }

  return {
    properties: {
      ...address.properties,
      street2: { type: 'string' }
    },
    required: ['city', 'country', 'postalCode', 'street']
  };
};

/**
 * Export `addressResolver`.
 */

export const addressResolver = (country: ?string) => {
  if (country === 'US') {
    return {
      ...addressUS,
      required: ['city', 'country', 'postalCode', 'state', 'street']
    };
  }

  return {
    ...address,
    required: ['city', 'country', 'postalCode', 'street']
  };
};

/**
 * Export `DocumentResolverRequest` type.
 */

export type DocumentResolverRequest = {|
  backSide?: string,
  frontSide?: string,
  type: ?string
|};

/**
 * Document side.
 */

const documentSide = {
  properties: {
    fields: { type: 'object' }
  },
  required: ['fields'],
  type: 'object'
};

/**
 * Document type.
 */

const documentType = {
  enum: Object.values(documentTypes),
  type: 'string'
};

/**
 * Export `documentResolver`.
 */

export const documentResolver = (type: ?string) => {
  if (type === documentTypes.passport) {
    return {
      properties: {
        frontSide: documentSide,
        type: documentType
      },
      required: ['frontSide', 'type']
    };
  }

  return {
    properties: {
      backSide: documentSide,
      frontSide: documentSide,
      type: documentType
    },
    required: ['backSide', 'frontSide', 'type']
  };
};

/**
 * Export `taxPersonResolver`.
 */

export const taxPersonResolver = (country: ?string) => {
  if (country === 'US') {
    return {
      ...taxPersonUS,
      required: ['country', 'idNumber', 'state']
    };
  }

  return {
    ...taxPerson,
    required: ['country', 'idNumber']
  };
};
