// @flow

/**
 * Module dependencies.
 */

import { Box, Button, Fill, Icon, Type, color, units } from 'pmint-design-system';
import { getChangeImageState } from 'client/core/redux/selectors/change-image';
import { ifProp, theme } from 'styled-tools';
import { isEmpty, isNil } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useField, useFormState } from '@seegno/react-forms';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import Avatar from 'client/components/core/avatar';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import accept from 'attr-accept';
import dragDropIcon from 'client/assets/svg/drag-n-drop-32px.svg';
import styled, { css } from 'styled-components';
import useNetworkErrorMesssage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isModalVisible: boolean,
  maxFileSize: string,
  name: string
|};

/**
 * `imageAcceptTypes` configuration.
 */

const imageAcceptTypes = ['.jpeg', '.jpg', '.png', '.gif'];

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  border: 1px dashed ${color('gray300')};
  border-radius: ${units(0.5)};
  min-height: 100%;
  overflow: hidden;
  position: relative;
  transition: border-color ${theme('transitions.defaultTransition')};

  ${ifProp('hasError', css`
    border-color: ${color('errorDark')};
  `)}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${units(3.5)};
  padding-top: 23px;
`;

/**
 * `AvatarWrapper` styled component.
 */

const AvatarWrapper = styled.div`
  margin-bottom: 27px;
  text-align: center;
`;

/**
 * `StyledAvatar` styled component.
 */

const StyledAvatar = styled(Avatar)`
  &::before {
    transition: transform ${theme('transitions.defaultTransition')};
  }

  ${ifProp('isDragActive', css`
    &::before {
      transform: scale(5);
    }
  `)}
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  border-radius: 50%;
  height: 100%;
  max-height: ${units(13)};
  max-width: ${units(13)};
  object-fit: cover;
  width: 100%;
  z-index: 5;
`;

/**
 * `Input` styled component.
 */

const Input = styled(Fill).attrs({ as: 'input' })`
  cursor: pointer;
  height: 100%;
  opacity: 0;
  width: 100%;
  z-index: 1;

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `FlexWrapper` styled component.
 */

const FlexWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * `BrowseButtonWrapper` styled component.
 */

const BrowseButtonWrapper = styled(Button)`
  font-size: 15px;
  margin-top: 6px;
  padding: 7px 30px;
`;

/**
 * `ChangeImageModalContent` component.
 */

const ChangeImageModalContent = (props: Props): Node => {
  const { isModalVisible, maxFileSize, name } = props;
  const { errors } = useSelector(getChangeImageState);
  const { isSubmitting } = useFormState();
  const { onBlur, onChange, onFocus } = useField(name);
  const { showErrorMessage } = useSnackbar();
  const { translate } = useTranslate();
  const [rejectedImages, setRejectedImages] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const textDropzoneColor = color.transparentize('textColor', isSubmitting ? 0.5 : 1);
  const handleDrop = useCallback(files => {
    if (isEmpty(files) && !isNil(selectedFile)) {
      return;
    }

    const file = files[0];

    onBlur();
    onChange(file);
    setSelectedFile(URL.createObjectURL(file));
  }, [selectedFile, onBlur, onChange]);

  const {
    getInputProps,
    getRootProps,
    isDragActive,
    rejectedFiles
  } = useDropzone({
    accept: imageAcceptTypes,
    maxSize: maxFileSize,
    multiple: false,
    onDrop: handleDrop
  });

  useEffect(() => {
    if (!isEmpty(rejectedFiles)) {
      return setRejectedImages(rejectedFiles);
    }

    return setRejectedImages(null);
  }, [rejectedFiles]);

  useEffect(() => {
    if (isNil(rejectedImages)) {
      return;
    }

    const [rejectedImage] = rejectedImages;

    if (rejectedImage.size > maxFileSize) {
      showErrorMessage(translate('profile.changeImage.errors.dropzoneErrors.maxFileSize'));

      return;
    }

    if (!accept(rejectedImages, imageAcceptTypes)) {
      showErrorMessage(translate('profile.changeImage.errors.dropzoneErrors.fileType', { fileTypes: imageAcceptTypes.join(', ') }));

      return;
    }

    showErrorMessage(translate('profile.changeImage.errors.dropzoneErrors.defaultError'));
  }, [maxFileSize, rejectedImages, showErrorMessage, translate]);

  useEffect(() => {
    if (!isModalVisible) {
      onChange(null);
      setSelectedFile(null);
      setRejectedImages(null);
    }
  }, [isModalVisible, onChange]);

  useNetworkErrorMesssage('profile.changeImage.errors', errors);

  return (
    <Wrapper
      {...getRootProps()}
      hasError={!isNil(rejectedImages)}
    >
      <Input
        aria-describedby={'drag-drop-label'}
        disabled={isSubmitting}
        id={name}
        name={name}
        {...getInputProps()}
        onBlur={onBlur}
        onFocus={onFocus}
        style={{ display: 'initial' }}
      />

      <ContentWrapper>
        <AvatarWrapper>
          <StyledAvatar isDragActive={isDragActive}>
            {selectedFile ? <Image src={selectedFile} /> : (
              <Icon
                color={color('bender2')}
                icon={dragDropIcon}
                size={units(7.5)}
              />
            )}
          </StyledAvatar>
        </AvatarWrapper>

        <Box position={'relative'}>
          <Type.Small
            as={'p'}
            color={textDropzoneColor}
            fontWeight={500}
            id={'drag-drop-label'}
            lineHeight={'24px'}
            textAlign={'center'}
          >
            {translate('profile.changeImage.dragDrop')}
          </Type.Small>

          <Type.Small
            as={'p'}
            color={textDropzoneColor}
            fontSize={'11px'}
            fontWeight={400}
            lineHeight={'16px'}
            textAlign={'center'}
          >
            {translate('profile.changeImage.maxSize', { maxFileSize })}
          </Type.Small>

          <Type.Small
            as={'p'}
            color={textDropzoneColor}
            fontWeight={500}
            lineHeight={'24px'}
            textAlign={'center'}
          >
            {translate('profile.changeImage.or')}
          </Type.Small>

          <FlexWrapper>
            <BrowseButtonWrapper
              colorTheme={'secondary'}
              disabled={isSubmitting}
              variant={'outlined'}
            >
              {translate('profile.changeImage.browseButton')}
            </BrowseButtonWrapper>
          </FlexWrapper>
        </Box>
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `ChangeImageModalContent` component.
 */

export default ChangeImageModalContent;
