// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { requestAccountDeletionType } from 'client/core/redux/action-types/request-account-deletion';

/**
 * Export `requestAccountDeletion`.
 */

export const requestAccountDeletion = () => createRequestAction({
  apiName: 'app',
  endpoint: 'requestAccountDeletion',
  handleResponse: ({ data }) => data,
  meta: {
    auth: { isAuthenticated: true }
  },
  method: 'POST',
  type: requestAccountDeletionType
});

/**
 * Export `resetRequestAccountDeletion`.
 */

export const resetRequestAccountDeletion = createResetRequestAction({
  method: 'POST',
  type: requestAccountDeletionType
});
