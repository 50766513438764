// @flow

/**
 * Export `signInSchema`.
 */

export default {
  additionalProperties: false,
  properties: {
    email: {
      format: 'email',
      type: 'string'
    },
    password: {
      type: 'string'
    }
  },
  required: ['email', 'password'],
  type: 'object'
};
