// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchDepositType } from 'client/core/redux/action-types/fetch-deposit';

/**
 * Export `fetchDeposit`.
 */

export default combineReducers(createRequestReducers({
  type: fetchDepositType
}));
