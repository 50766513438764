// @flow

/**
 * Module dependencies.
 */

import { checkIfEmailExistsType } from 'client/core/redux/action-types/check-if-email-exists';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

/**
 * `Options` type.
 */

type Options = {
  email: string
};

/**
 * Export `checkIfEmailExists`.
 */

export const checkIfEmailExists = ({ email }: Options) => {
  return createRequestAction({
    apiName: 'app',
    data: { email },
    endpoint: 'checkEmail',
    handleResponse: response => response.data,
    method: 'POST',
    type: checkIfEmailExistsType
  });
};

/**
 * Export `resetCheckIfEmailExists`.
 */

export const resetCheckIfEmailExists = createResetRequestAction({
  method: 'POST',
  type: checkIfEmailExistsType
});
