// @flow

/**
 * Module dependencies.
 */

import {
  Icon,
  Link,
  Type,
  color,
  media,
  states,
  units
} from 'pmint-design-system';

import { Link as RouterLink } from 'react-router-dom';
import type { User } from 'client/core/types/user';
import { ifProp } from 'styled-tools';
import { times } from 'lodash';
import FieldValue from 'client/components/core/field-value';
import Header from 'client/components/core/header';
import React, { type Node } from 'react';
import editIcon from 'client/assets/svg/edit-32px.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  hideChangePassword: boolean,
  icon: string,
  onLogout: () => void,
  onRemoveAccount: () => void,
  title: string,
  user: User
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;

  ${media.min('md')`
    grid-row-gap: 0;
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2;
  overflow: hidden;
`;

/**
 * `Fieldset` styled component.
 */

const Fieldset = styled.div`
  margin-bottom: ${units(7)};
`;

/**
 * `PasswordWrapper` styled component.
 */

const PasswordWrapper = styled.div`
  display: flex;
  padding-top: 47px;
`;

/**
 * `CirclesWrapper` styled component.
 */

const CirclesWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

/**
 * `Circle` styled component.
 */

const Circle = styled.div`
  background-color: ${color('gray500')};
  border-radius: 50%;
  flex-shrink: 0;
  height: 10px;
  width: 10px;

  &:not(:last-child) {
    margin-right: ${units(1)};
  }
`;

/**
 * `ChangePasswordButton` styled component.
 */

const ChangePasswordButton = styled.button`
  background-color: ${color('gray200')};
  border: none;
  border-radius: ${units(1)};
  cursor: pointer;
  height: ${units(8)};
  margin-left: ${units(2)};
  outline: 0;
  padding: ${units(2)};
  width: ${units(8)};

  ${ifProp('disabled', css`
    cursor: not-allowed;
    opacity: 0.7;
  `, css`
    ${states.interaction`
      background-color: ${color('gray300')};
    `}
  `)}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('gray300')};
  height: 1px;
  margin-bottom: 29px;
`;

/**
 * `RemoveAccountLink` styled component.
 */

const RemoveAccountLink = styled(Link)`
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
`;

/**
 * `ProfileContent` component.
 */

const ProfileContent = (props: Props): Node => {
  const {
    hideChangePassword,
    icon,
    onLogout,
    onRemoveAccount,
    title,
    user
  } = props;
  const { email, emailConfirmed, name } = user;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Header
        icon={icon}
        onLogout={onLogout}
        title={title}
      />

      <Content>
        <Type.H4
          color={color('bender2')}
          marginBottom={units(6)}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
        >
          {translate('profile.welcome', { username: name })}
        </Type.H4>

        <Fieldset>
          <FieldValue label={translate('profile.fullName')}>
            {name}
          </FieldValue>

          <FieldValue label={translate('profile.email')}>
            {email}
          </FieldValue>

          {!hideChangePassword && (
            <PasswordWrapper>
              <FieldValue label={translate('profile.password')}>
                <CirclesWrapper>
                  {times(8, index => <Circle key={index} />)}
                </CirclesWrapper>
              </FieldValue>

              <ChangePasswordButton
                aria-label={translate('profile.changePassword.title')}
                as={RouterLink}
                disabled={!emailConfirmed}
                to={routes.profile.changePassword}
              >
                <Icon
                  aria-hidden
                  color={color('gray500')}
                  icon={editIcon}
                  size={units(4)}
                />
              </ChangePasswordButton>
            </PasswordWrapper>
          )}
        </Fieldset>

        <Line />

        <RemoveAccountLink
          aria-disabled={!emailConfirmed}
          colorTheme={'secondary'}
          disabled={!emailConfirmed}
          href={emailConfirmed ? '#' : null}
          onClick={emailConfirmed ? onRemoveAccount : null}
        >
          {translate('profile.removeAccount.title')}
        </RemoveAccountLink>
      </Content>
    </Wrapper>
  );
};

/**
 * Export `ProfileContent` component.
 */

export default ProfileContent;
