// @flow

/**
 * Module dependencies.
 */

import type { UploadImage } from 'client/core/redux/reducer/upload-image';
import { get } from 'lodash';

/**
 * Export `getUploadedImage`.
 */

export const getUploadedImage = (state: Object): UploadImage => {
  return get(state, 'uploadImage');
};
