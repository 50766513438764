// @flow

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import AboutContent from 'client/components/about/about-content';
import Header from 'client/components/core/header';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import aboutIcon from 'client/assets/svg/about.svg';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content auto;
  min-height: 100%;

  ${media.min('md')`
    grid-template-columns: repeat(8, 1fr);
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2 / -2;
  position: relative;
`;

/**
 * `SupportEmail` styled component.
 */

const SupportEmail = styled(Type.Paragraph)`
  display: flex;
  margin-top: ${units(6)};

  a {
    color: ${color('textColor')};
    margin-left: ${units(0.5)};
    text-decoration: none;

    &:hover, &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `About` container.
 */

const About = (): Node => {
  const { translate } = useTranslate();

  return (
    <PageLayout
      sidebarContent={(
        <SidebarContent
          subtitle={translate('about.sidebar.content')}
          title={translate('about.sidebar.title')}
        >
          <RawHtml element={SupportEmail}>
            {translate('about.sidebar.supportEmail', {
              email: config.get('supportEmail')
            })}
          </RawHtml>
        </SidebarContent>
      )}
    >
      <ContentWrapper>
        <Header
          icon={aboutIcon}
          title={translate('about.header.title')}
        />

        <Content>
          <AboutContent />
        </Content>
      </ContentWrapper>
    </PageLayout>
  );
};

/**
 * Export `About` container.
 */

export default About;
