// @flow

/**
 * Module dependencies.
 */

import type {
  AppleLoginResponse,
  AuthProviderKey,
  GoogleLoginResponse
} from 'client/core/types/auth-provider';

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { signInWithOptionsType } from 'client/core/redux/action-types/sign-in-with-options';

/**
 * `AuthProviderSignInValues` type.
 */

export type AuthProviderSignInValues = {|
  data: GoogleLoginResponse | AppleLoginResponse,
  identityProvider: AuthProviderKey
|};

/**
 * Export `signInWithOptions`.
 */

export const signInWithOptions = ({ data, identityProvider }: AuthProviderSignInValues) => {
  return createRequestAction({
    apiName: 'account',
    data: {
      data,
      identityProvider
    },
    endpoint: 'createSSOToken',
    handleResponse: ({ data }) => data,
    method: 'POST',
    type: signInWithOptionsType
  });
};

/**
 * Export `resetSignInWithOptions`.
 */

export const resetSignInWithOptions = createResetRequestAction({
  method: 'POST',
  type: signInWithOptionsType
});
