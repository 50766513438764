// @flow

/**
 * Module dependencies.
 */

import { DateInput as PmintDateInput } from 'pmint-design-system';
import { getFieldValue } from 'client/core/utils/form';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  id?: string,
  label: string,
  name: string
|};

/**
 * `DateInput` component.
 */

function DateInput(props: Props): Node {
  const { disabled, id, label, name, ...rest } = props;
  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);
  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleBlur = useCallback(event => {
    if (onBlur) {
      onBlur(event);
      onChange(getFieldValue(value));
    }
  }, [onBlur, onChange, value]);

  const handleChange = useCallback(value => {
    onChange(value);
  }, [onChange]);

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const inputLabel = useLabel(label, name);

  return (
    <PmintDateInput
      {...rest}
      autoComplete={'off'}
      disabled={isDisabled}
      hasError={hasError}
      id={id}
      label={inputLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={value}
    />
  );
}

/**
 * Export `DateInput` component.
 */

export default DateInput;
