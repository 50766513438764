// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { resetPasswordType } from 'client/core/redux/action-types/reset-password';

/**
 * Export `resetPassword`.
 */

export default combineReducers(createRequestReducers({
  method: 'PATCH',
  type: resetPasswordType
}));
