// @flow

/**
 * Export `documentTypes`.
 */

export const documentTypes = {
  driverLicense: 'driverLicense',
  identityCard: 'identityCard',
  other: 'other',
  passport: 'passport',
  residencePermit: 'residencePermit',
  utilityBill: 'utilityBill'
};
