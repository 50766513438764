// @flow

/**
 * Module dependencies.
 */

import type {
  AppleLoginResponse,
  AuthProvider,
  GoogleLoginResponse
} from 'client/core/types/auth-provider';

import { Badge, Box, SocialNetworkButton, media, units } from 'pmint-design-system';
import { authProviders } from 'core/constants/auth-providers';
import { getNetworkErrorPaths } from 'client/core/utils/errors';
import { ifProp } from 'styled-tools';
import { performProviderSignIn } from 'client/core/redux/actions/perform-sign-in';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import AppleModal from './apple-modal';
import GoogleModal from './google-modal';
import React, { type Node, useCallback, useState } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  options: Array<AuthProvider>
|};

/**
 * `SignInResponse` type.
 */

type SignInResponse = AppleLoginResponse | GoogleLoginResponse;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${units(2)};
  justify-content: center;

  ${media.min('sm')`
    grid-template-columns: repeat(2, 1fr);
  `};
`;

/**
 * `StyledSocialNetworkButton` styled component.
 */

const StyledSocialNetworkButton = styled(SocialNetworkButton)`
  display: grid;
  grid-auto-flow: column;
  opacity: ${ifProp('disabled', 0.5, 1)};
`;

/**
 * `ComingSoon` styled component.
 */

const ComingSoon = styled(Badge)`
  position: absolute;
  right: 0;
  top: -2px;
`;

/**
 * `SignInOptions` component.
 */

const SignInOptions = ({ options }: Props): Node => {
  const [identityProvider, setIdentityProvider] = useState(null);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showErrorMessage } = useSnackbar();
  const handleSignInFailure = useCallback(error => {
    showErrorMessage(translate(getNetworkErrorPaths(
      'connectAccount.errors',
      error?.code
    )));

    setIdentityProvider(null);

    return history.push(routes.signIn.base);
  }, [history, showErrorMessage, translate]);

  const handleSignInSuccess = useCallback((data: SignInResponse) => {
    if (!identityProvider) {
      return;
    }

    dispatch(performProviderSignIn({ data, identityProvider }))
      .catch(handleSignInFailure);
  }, [dispatch, handleSignInFailure, identityProvider]);

  return (
    <>
      <Wrapper>
        {options.map(({ comingSoon, key }) => (
          <Box
            key={key}
            position={'relative'}
          >
            <StyledSocialNetworkButton
              colorTheme={key}
              disabled={comingSoon}
              fullWidth
              onClick={() => setIdentityProvider(key)}
              variant={'outlined'}
            >
              {translate(`signIn.options.${key}`)}
            </StyledSocialNetworkButton>

            {comingSoon && (
              <ComingSoon>
                {translate('signIn.comingSoon')}
              </ComingSoon>
            )}
          </Box>
        ))}
      </Wrapper>

      <GoogleModal
        isModalVisible={identityProvider === authProviders.google}
        onSignInFailure={handleSignInFailure}
        onSignInSuccess={handleSignInSuccess}
      />

      <AppleModal
        isModalVisible={identityProvider === authProviders.apple}
        onSignInFailure={handleSignInFailure}
        onSignInSuccess={handleSignInSuccess}
      />
    </>
  );
};

/**
 * Export `SignInOptions` component.
 */

export default SignInOptions;
