// @flow

/**
 * Module dependencies.
 */

import {
  checkIfEmailExists,
  resetCheckIfEmailExists
} from 'client/core/redux/actions/check-if-email-exists';

import { useDispatch } from 'react-redux';
import { useFieldState } from '@seegno/react-forms';
import InputField from 'client/components/core/forms/fields/input';
import React, { type Node, useEffect } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  isDisabled: boolean,
  label: string,
  name: string
|};

/**
 * `EmailField` component.
 */

const EmailField = ({ isDisabled, label, name }: Props): Node => {
  const { meta, value } = useFieldState(name);
  const { active, dirty, touched } = meta;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isDisabled && !active && dirty && touched) {
      dispatch(checkIfEmailExists({ email: value }));

      return () => dispatch(resetCheckIfEmailExists());
    }
  }, [active, dirty, dispatch, isDisabled, touched, value]);

  return (
    <InputField
      disabled={isDisabled}
      label={label}
      name={name}
      type={'email'}
    />
  );
};

/**
 * Export `EmailField` component.
 */

export default EmailField;
