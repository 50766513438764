// @flow

/**
 * Module dependencies.
 */

import { Box, Type, color, units } from 'pmint-design-system';
import { switchProp } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  items: Array<{
    label: string,
    state: string
  }>
|};

/**
 * `Line` styled component.
 */

const Line = styled.div`
  height: ${units(1)};

  ${switchProp('state', {
    complete: css`
      background-color: ${color('bender2')};

      + ${Type.Paragraph} {
        color: ${color('bender2')};
      }
    `,
    initial: css`
      background-color: ${color('gray200')};

      + ${Type.Paragraph} {
        color: ${color('gray600')};
      }
    `,
    progress: css`
      background-color: ${color('gray600')};

      + ${Type.Paragraph} {
        color: ${color('gray700')};
      }
    `
  })}
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  &:not(:last-child) {
    padding-right: ${units(0.5)};
  }
`;

/**
 * `ProgressBar` component.
 */

const ProgressBar = (props: Props): Node => {
  const { items, ...rest } = props;

  return (
    <Box
      {...rest}
      display={'flex'}
    >
      {items.map(item => (
        <Wrapper key={item.label}>
          <Line state={item.state} />

          <Type.Paragraph
            marginTop={units(2.65)}
            paddingLeft={units(1.5)}
            paddingLeftMd={units(3)}
          >
            {item.label}
          </Type.Paragraph>
        </Wrapper>
      ))}
    </Box>
  );
};

/**
 * Export `ProgressBar` component.
 */

export default ProgressBar;
