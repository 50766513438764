// @flow

/**
 * Module dependencies.
 */

import { changePasswordType } from 'client/core/redux/action-types/change-password';
import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `changePassword`.
 */

export default combineReducers(createRequestReducers({
  method: 'PATCH',
  type: changePasswordType
}));
