// @flow

/**
 * Set body overflow.
 */

function setBodyOverflow(enabled: boolean) {
  const body = document.body;

  if (!body) {
    return;
  }

  body.style.overflow = enabled ? '' : 'hidden';
}

/**
 * Prevent default event.
 */

function preventDefaultTouchmove(event: Object) {
  event.preventDefault();
}

/**
 * Add prevent default touchmove event listener.
 */

function disableBodyTouchmove() {
  const body = document.body;

  if (!body) {
    return;
  }

  body.addEventListener('touchmove', preventDefaultTouchmove);
}

/**
 * Remove prevent default touchmove event listener.
 */

function enableBodyTouchmove() {
  const body = document.body;

  if (!body) {
    return;
  }

  body.removeEventListener('touchmove', preventDefaultTouchmove);
}

/**
 * Export `setBodyScroll`.
 */

export const setBodyScroll = (enabled: boolean) => {
  setBodyOverflow(enabled);

  if (enabled) {
    return enableBodyTouchmove();
  }

  return disableBodyTouchmove();
};
