// @flow

/**
 * Module dependencies.
 */

import { IconButton, Loader, color, units } from 'pmint-design-system';
import type {
  KycContactInformation,
  KycHomeAddressInformation,
  KycIdentityInformation,
  KycTaxInformation
} from 'client/core/types/kyc';

import { useFormActions, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import checkIcon from 'client/assets/svg/check-32px.svg';
import closeIcon from 'client/assets/svg/close-32px.svg';
import editIcon from 'client/assets/svg/edit-32px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  formValues: KycContactInformation | KycHomeAddressInformation | KycIdentityInformation | KycTaxInformation,
  isEditing: boolean,
  isEditingOtherFields: boolean,
  onCloseEditFields: () => void,
  onEditFields: () => void,
  scroll: () => void
|};

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  padding: ${units(0.5)};
`;

/**
 * `CheckIconButton` styled component.
 */

const CheckIconButton = styled(StyledIconButton)`
  margin-left: ${units(1)};
`;

/**
 * `KycButtons` component.
 */

const KycButtons = (props: Props): Node => {
  const {
    formValues,
    isEditing,
    isEditingOtherFields,
    onCloseEditFields,
    onEditFields,
    scroll
  } = props;

  const { isSubmitting, meta } = useFormState();
  const { dirty, hasErrors } = meta;
  const { reset, submit } = useFormActions();
  const { translate } = useTranslate();
  const isDisabled = hasErrors || !dirty;
  const resetFieldsValues = useCallback(() => {
    return reset(formValues);
  }, [formValues, reset]);

  if (!isEditing) {
    return (
      <StyledIconButton
        aria-label={translate('common.actions.edit')}
        colorTheme={'default'}
        disabled={isEditingOtherFields}
        icon={editIcon}
        onClick={() => {
          scroll();
          onEditFields();
        }}
      />
    );
  }

  if (isSubmitting) {
    return (
      <Loader
        colorTheme={color('bender2')}
        size={units(4)}
        stroke={units(0.5)}
      />
    );
  }

  return (
    <ButtonsWrapper>
      <StyledIconButton
        aria-label={translate('common.actions.cancel')}
        colorTheme={'default'}
        icon={closeIcon}
        onClick={() => {
          resetFieldsValues();
          onCloseEditFields();
        }}
      />

      <CheckIconButton
        aria-label={translate('common.actions.save')}
        colorTheme={'default'}
        disabled={isDisabled}
        icon={checkIcon}
        onClick={() => {
          scroll();
          submit();
        }}
        type={'submit'}
      />
    </ButtonsWrapper>
  );
};

/**
 * Export `KycButtons` component.
 */

export default KycButtons;
