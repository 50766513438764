// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import {
  resetPasswordTokenType
} from 'client/core/redux/action-types/fetch-reset-password-token';

/**
 * `Options` type.
 */

type Options = {
  email: string,
  token: string
};

/**
 * Export `fetchResetPasswordToken`.
 */

export function fetchResetPasswordToken({ email, token }: Options) {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'resetPasswordToken',
    getQuery: () => ({
      email,
      token
    }),
    handleResponse: response => response.data,
    type: resetPasswordTokenType
  });
}

/**
 * Export `resetFetchResetPasswordToken`.
 */

export const resetFetchResetPasswordToken = createResetRequestAction({
  type: resetPasswordTokenType
});
