// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getSubmitDocumentsState`.
 */

export const getSubmitDocumentsState = createRequestStateSelector('submitDocuments');
