// @flow

/**
 * Module dependencies.
 */

import {
  addImageFileType,
  addUploadErrorsType,
  addUploadFileProgressType,
  addUploadedFileType,
  resetUploadImageType
} from 'client/core/redux/action-types/upload-image';

/**
 * Export `UploadImage` type.
 */

export type UploadImage = {
  errors: ?Object,
  fields: ?Object,
  file: ?Object,
  isSubmitting: boolean,
  progress: number
};

/**
 * Export `uploadImage`.
 */

export function uploadImage(state: UploadImage = {}, action: Object = {}): UploadImage {
  const { payload, type } = action;

  switch (type) {
    case addImageFileType:
      return {
        errors: null,
        fields: null,
        file: payload.file,
        isSubmitting: true,
        progress: 0
      };

    case addUploadFileProgressType:
      return {
        ...state,
        progress: payload.progress
      };

    case addUploadedFileType:
      return {
        ...state,
        fields: payload.fields
      };

    case addUploadErrorsType:
      return {
        ...state,
        errors: payload.errors
      };

    case resetUploadImageType:
      return {
        errors: null,
        fields: null,
        file: null,
        isSubmitting: false,
        progress: 0
      };

    default:
      return state;
  }
}

/**
 * Export `uploadImage`.
 */

export default uploadImage;
