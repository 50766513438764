// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import { theme } from 'styled-tools';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-areas: ". content .";
  grid-template-columns: 1fr 10fr 1fr;
  min-height: 100%;
  padding-top: ${units(6)};

  ${media.min('md')`
    grid-template-areas:
      "line line    line"
      ".    content .";
    grid-template-columns: 1fr 6fr 1fr;
    grid-template-rows: min-content auto;
    min-height: 100vh;
    padding-bottom: ${units(6)};
    padding-top: 0;
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  display: none;

  ${media.min('md')`
    background-color: ${color('bender1')};
    display: block;
    grid-area: line;
    height: ${theme('layout.header.height.line')};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-area: content;
  width: 100%;

  ${media.min('md')`
    justify-self: center;
    max-width: ${units(90)};
  `}
`;

/**
 * `PageContent` component.
 *
 * NOTE: Try not to add ifs or other such behavior to this component.
 * Avoid adding "variants" and things like that.
 * Color themes for the line are ok.
 * If you need a different layout for any page, then copy-paste the above
 * styled component and do the changes you need in the page.
 */

const PageContent = ({ children }: Props): Node => (
  <Wrapper>
    <Line />

    <Content>
      {children}
    </Content>
  </Wrapper>
);

/**
 * Export `PageContent` component.
 */

export default PageContent;
