// @flow

/**
 * Module dependencies.
 */

import { Type } from 'pmint-design-system';
import {
  formatIncompletePhoneNumber,
  parsePhoneNumberFromString
} from 'libphonenumber-js';

import { isNil } from 'lodash';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  phoneNumber: string
|};

/**
 * Get phone number.
 */

function getPhoneNumber(phoneNumber: string) {
  const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber);

  if (isNil(parsedPhoneNumber)) {
    return {
      number: formatIncompletePhoneNumber(phoneNumber),
      prefix: '+000'
    };
  }

  const number = parsedPhoneNumber.formatNational();
  const prefix = '+'.concat(parsedPhoneNumber.countryCallingCode);

  return {
    number,
    prefix
  };
}

/**
 * `KycPhone` component.
 */

const KycPhone = ({ phoneNumber }: Props): Node => {
  const phoneNumberValue = getPhoneNumber(phoneNumber);
  const { number, prefix } = phoneNumberValue;

  return (
    <>
      <Type.Label
        as={'span'}
        color={'inherit'}
        fontWeight={'inherit'}
        marginRight={'29px'}
      >
        {prefix}
      </Type.Label>

      {number}
    </>
  );
};

/**
 * Export `KycPhone` component.
 */

export default KycPhone;
