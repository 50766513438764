// @flow

/**
 * Module dependencies.
 */

import { Fill, Loader, color, units } from 'pmint-design-system';
import { ifNotProp, theme } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean
|};

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')} ${theme('transitions.defaultTransition')} both;
  animation-delay: 500ms;
  display: flex;
  justify-content: center;
  opacity: 1;
  transition: opacity ${theme('transitions.defaultTransition')};

  ${ifNotProp('isVisible', css`
    animation-name: none;
    opacity: 0;
    pointer-events: none;
  `)}
`;

/**
 * `Loading` component.
 */

const Loading = (props: Props): Node => {
  return (
    <LoaderWrapper {...props}>
      <Loader
        colorTheme={color('bender2')}
        size={units(6)}
        stroke={units(0.5)}
      />
    </LoaderWrapper>
  );
};

/**
 * Export `Loading` component.
 */

export default Loading;
