// @flow

/**
 * Module dependencies.
 */

import type { KycSubmitDocument } from 'client/core/types/kyc-documents';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { denormalizeDocumentTypeAndSide } from 'client/core/utils/kyc-documents';
import { submitDocumentsType } from 'client/core/redux/action-types/submit-documents';

/**
 * `Options` type.
 */

type Options = {
  kycDocuments: Array<KycSubmitDocument>
};

/**
 * Export `submitDocuments`.
 */

export const submitDocuments = ({ kycDocuments }: Options) => {
  const documents = kycDocuments.map(document => {
    return {
      ...document,
      ...denormalizeDocumentTypeAndSide(document.type, document.side)
    };
  });

  return createRequestAction({
    apiName: 'app',
    data: { documents },
    endpoint: 'submitKycDocuments',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: submitDocumentsType
  });
};

/**
 * Export `resetSubmitDocuments`.
 */

export const resetSubmitDocuments = createResetRequestAction({
  method: 'POST',
  type: submitDocumentsType
});
