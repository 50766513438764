// @flow

/**
 * Module dependencies.
 */

import { Box, Icon, Type, color, units } from 'pmint-design-system';
import { get, isNil, trim } from 'lodash';
import { regex } from 'pmint_core_validator';
import { useField } from '@seegno/react-forms';
import RawHtml from 'react-components/raw-html';
import React, { Fragment, type Node } from 'react';
import checkIcon from 'pmint-design-system/icons/check-28px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  fieldname: string
|};

/**
 * Requirements order.
 */

const requirementsOrder = [
  'upperCaseOrLowerCase',
  'specialCharacterOrNumber',
  'passwordSize'
];

/**
 * Requirements.
 */

const requirements = requirementsOrder.map(requirement => ({
  name: requirement,
  regex: new RegExp(get(regex.passwordRequirements, requirement))
}));

/**
 * Get password score.
 */

function getPasswordScore(value) {
  const levels = [null, 'weak', 'good', 'strong'];
  let counter = 0;

  requirements.forEach(({ regex }) => {
    if (regex.test(value)) {
      counter += 1;
    }
  });

  return levels[counter];
}

/**
 * Get password value.
 */

function getPasswordValue(value: string) {
  if (isNil(value) || trim(value).length === 0) {
    return '';
  }

  return value;
}

/**
 * Password score icon.
 */

const passwordScoreIcon = {
  good: '😄',
  strong: '😎',
  weak: '😔'
};

/**
 * `PasswordScoreWrapper` styled component.
 */

const PasswordScoreWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(2)};
`;

/**
 * `RequirementsWrapper` styled component.
 */

const RequirementsWrapper = styled.div`
  border: 1px solid ${color('gray300')};
  border-radius: ${units(1)};
  padding: ${units(2)} ${units(2.5)};
`;

/**
 * `PasswordRequirementsWrapper` styled component.
 */

const PasswordRequirementsWrapper = styled.div`
  display: grid;
  grid-gap: ${units(0.5)} ${units(1)};
  grid-template-columns: repeat(2, auto);
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  align-self: center;
  height: ${units(2)};
  justify-self: end;
`;

/**
 * `PasswordValidation` component.
 */

const PasswordValidation = ({ fieldname }: Props): Node => {
  const { value } = useField(fieldname);
  const { translate } = useTranslate();
  const passwordValue = getPasswordValue(value);
  const passwordScore = getPasswordScore(passwordValue);

  return (
    <>
      <PasswordScoreWrapper>
        <RawHtml
          as={'h6'}
          element={Type.Small}
        >
          {translate('profile.changePassword.passwordRequirements.title')}
        </RawHtml>

        {passwordScore !== null && (
          <Box>
            <Type.Small
              as={'p'}
              color={color('bender2')}
              display={'inline'}
              marginRight={units(1)}
            >
              {translate(`common.password.level.${passwordScore}`)}
            </Type.Small>

            <Icon
              icon={passwordScoreIcon[passwordScore]}
              size={units(2)}
            />
          </Box>
        )}
      </PasswordScoreWrapper>

      <RequirementsWrapper>
        <PasswordRequirementsWrapper>
          {requirements.map(({ name, regex }) => {
            const isValid = regex.test(passwordValue);
            const textColor = isValid ? color('bender2') : color('textColorLight');

            return (
              <Fragment key={name}>
                <Type.Small
                  as={'p'}
                  color={textColor}
                >
                  {translate(`profile.changePassword.passwordRequirements.${name}`)}
                </Type.Small>

                <StyledIcon
                  color={textColor}
                  icon={checkIcon}
                  size={units(2)}
                />
              </Fragment>
            );
          })}
        </PasswordRequirementsWrapper>
      </RequirementsWrapper>
    </>
  );
};

/**
 * Export `PasswordValidation` component.
 */

export default PasswordValidation;
