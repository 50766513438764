// @flow

/**
 * Module dependencies.
 */

import {
  Button,
  Icon,
  Loader,
  Modal,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import {
  deleteDocument,
  resetDeleteDocument
} from 'client/core/redux/actions/delete-document';

import { getDeleteDocumentState } from 'client/core/redux/selectors/delete-document';
import { useDispatch, useSelector } from 'react-redux';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect } from 'react';
import alertIcon from 'client/assets/svg/alert-32px.svg';
import arrowIcon from 'client/assets/svg/arrow.svg';
import backIcon from 'client/assets/svg/back-32px.svg';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  documentId: ?string,
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(5)} ${units(4)};
  text-align: center;

  ${media.min('xs')`
    padding: ${units(10)} ${units(2.5)};
  `}

  ${media.min('lg')`
    padding: ${units(10)} 69px;
  `}
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: ${units(2)} 0;

  ${media.min('xs')`
    grid-gap: 0 ${units(2)};
    grid-template-columns: repeat(2, minmax(0, ${units(29)}));
    justify-content: center;
  `}
`;

/**
 * `ButtonContent` styled component.
 */

const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

/**
 * `DeleteDocumentModal` component.
 */

const DeleteDocumentModal = (props: Props): Node => {
  const { documentId, isVisible, onRequestClose } = props;
  const { errors, isFailed, isLoading } = useSelector(getDeleteDocumentState);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleDeleteDocument = useCallback(() => {
    if (documentId) {
      return dispatch(deleteDocument({ documentId })).then(() => {
        onRequestClose();
      });
    }
  }, [dispatch, documentId, onRequestClose]);

  useEffect(() => {
    return () => dispatch(resetDeleteDocument());
  }, [dispatch]);

  useEffect(() => {
    if (isFailed) {
      onRequestClose();
    }
  }, [isFailed, onRequestClose]);

  useNetworkErrorMessage('kyc.documents.errors.deleteDocument', errors);

  return (
    <Modal isVisible={isVisible}>
      <Wrapper>
        <Icon
          color={color('warning')}
          icon={alertIcon}
          size={'89px'}
        />

        <Type.H5
          as={'h2'}
          color={color('warning')}
          fontWeight={400}
          marginBottom={'30px'}
          paddingTop={units(4)}
        >
          {translate('kyc.documents.delete.confirm.title')}
        </Type.H5>

        <RawHtml
          as={'p'}
          color={color('textColorLight')}
          element={Type.Label}
          fontWeight={400}
          marginBottom={'45px'}
        >
          {translate('kyc.documents.delete.confirm.description')}
        </RawHtml>

        <ButtonsWrapper>
          <Button
            fullWidth
            icon={backIcon}
            onClick={onRequestClose}
          >
            {translate('common.actions.cancel')}
          </Button>

          <Button
            colorTheme={'secondary'}
            fullWidth
            onClick={handleDeleteDocument}
            variant={'outlined'}
          >
            <ButtonContent>
              {translate('common.actions.proceed')}

              {isLoading ? <Loader /> : (
                <Icon
                  color={'inherit'}
                  icon={arrowIcon}
                  size={units(3)}
                />
              )}
            </ButtonContent>
          </Button>
        </ButtonsWrapper>
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `DeleteDocumentModal` component.
 */

export default DeleteDocumentModal;
