// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `signInWithOptionsType`.
 */

export const signInWithOptionsType = 'SIGN_IN_WITH_OPTIONS';

/**
 * Export `signInWithOptionsTypes`.
 */

export const signInWithOptionsTypes = createRequestActionTypes({
  method: 'POST',
  type: signInWithOptionsType
});
