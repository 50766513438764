// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import { resetFetchResetPasswordToken } from 'client/core/redux/actions/fetch-reset-password-token';
import { resetResetPassword } from 'client/core/redux/actions/reset-password';
import { useDispatch } from 'react-redux';
import PageContent from 'client/components/page-layout/page-content';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useEffect } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ResetPasswordSuccess` container.
 */

function ResetPasswordSuccess(): Node {
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetFetchResetPasswordToken());
      dispatch(resetResetPassword());
    };
  }, [dispatch]);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <PageContent>
        <Type.H4
          as={'h2'}
          color={color('bender2')}
          marginBottom={units(2)}
          textAlign={'center'}
        >
          {translate('resetPasswordSuccess.title')}
        </Type.H4>

        <RawHtml
          as={'p'}
          color={color('textColor')}
          element={Type.Label}
          fontWeight={400}
          letterSpacing={0}
          marginBottom={units(6)}
          marginBottomMd={units(8)}
          textAlign={'center'}
        >
          {translate(`resetPasswordSuccess.description`)}
        </RawHtml>
      </PageContent>
    </PageLayout>
  );
}

/**
 * Export `ResetPasswordSuccess` container.
 */

export default ResetPasswordSuccess;
