// @flow

/**
 * Module dependencies.
 */

import { units } from 'pmint-design-system';

/**
 * Export `layout`.
 */

export const layout = {
  avatar: {
    sizeDesktop: units(26),
    sizeMobile: units(24)
  },
  header: {
    height: {
      line: units(1)
    }
  }
};
