// @flow

/**
 * Module dependencies.
 */

import { Type, color } from 'pmint-design-system';
import { useLocation } from 'react-router-dom';
import React, { type Node } from 'react';
import RegisterLayout from 'client/components/register/register-layout';
import TaxInformationForm from 'client/components/register/tax-information-form';
import routes from 'core/routes';
import useTranslate from 'client/hooks/use-translate';

/**
 * `TaxInformation` container.
 */

const TaxInformation = (): Node => {
  const { translate } = useTranslate();
  const location = useLocation();
  const progressBarItems = [{
    label: translate('progressBar.register.identityInformation'),
    state: 'complete'
  }, {
    label: translate('progressBar.register.taxInformation'),
    state: 'progress'
  }, {
    label: translate('progressBar.register.identityVerification'),
    state: 'initial'
  }];

  return (
    <RegisterLayout
      progressBarItems={progressBarItems}
      to={{
        pathname: routes.register.identityInformation,
        state: {
          ...location.state?.userData
        }
      }}
    >
      <Type.Label
        as={'h1'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={'17px'}
      >
        {translate('kyc.taxInformation')}
      </Type.Label>

      <TaxInformationForm />
    </RegisterLayout>
  );
};

/**
 * Export `TaxInformation` container.
 */

export default TaxInformation;
