// @flow

/**
 * Module dependencies.
 */

import { camelCase, compact } from 'lodash';

/**
 * Export `getErrorPaths`.
 */

export const getErrorPaths = (basePath: ?string, errorRule: string, field: string): Array<string> => {
  return compact([
    basePath && field && errorRule ? `${basePath}.${field}.${errorRule}` : null,
    basePath && field ? `${basePath}.${field}.defaultError` : null,
    field && errorRule ? `defaultErrors.form.${field}.${errorRule}` : null,
    field ? `defaultErrors.form.${field}.defaultError` : null,
    basePath && errorRule ? `${basePath}.fallbacks.${errorRule}` : null,
    basePath ? `${basePath}.fallbacks.defaultError` : null,
    errorRule ? `defaultErrors.form.fallbacks.${errorRule}` : null,
    'defaultErrors.form.fallbacks.defaultError'
  ]);
};

/**
 * Export `getNetworkErrorPaths`.
 */

export const getNetworkErrorPaths = (basePath: ?string, errorCode: Object): Array<string> => {
  const code = camelCase(errorCode);

  return compact([
    basePath && `${basePath}.network.${code}`,
    basePath && `${basePath}.network.defaultError`,
    'defaultErrors.network.defaultError'
  ]);
};

/**
 * Export `isSessionExpiredError`.
 */

export function isSessionExpiredError(error: Object): boolean {
  return error.status === 401 && error.data?.code === 'invalid_token';
}
