// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { setPasswordType } from 'client/core/redux/action-types/set-password';

/**
 * `Options` type.
 */

type Options = {|
  confirmPassword: string,
  password: string
|};

/**
 * Export `setPassword`.
 */

export const setPassword = ({ confirmPassword, password }: Options) => {
  return createRequestAction({
    apiName: 'app',
    data: { confirmPassword, password },
    endpoint: 'setPassword',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: setPasswordType
  });
};

/**
 * Export `resetSetPassword`.
 */

export const resetSetPassword = createResetRequestAction({
  method: 'PATCH',
  type: setPasswordType
});
