// @flow

/**
 * Export `addDocumentFileType`.
 */

export const addDocumentFileType = 'ADD_DOCUMENT_FILE';

/**
 * Export `addInitialFieldsType`.
 */

export const addInitialFieldsType = 'ADD_INITIAL_FIELDS';

/**
 * Export `addUploadDocumentErrorsType`.
 */

export const addUploadDocumentErrorsType = 'ADD_UPLOAD_DOCUMENT_ERRORS';

/**
 * Export `addUploadProgressType`.
 */

export const addUploadProgressType = 'ADD_UPLOAD_PROGRESS';

/**
 * Export `addUploadedDocumentType`.
 */

export const addUploadedDocumentType = 'ADD_UPLOADED_DOCUMENT';

/**
 * Export `cancelUploadDocumentType`.
 */

export const cancelUploadDocumentType = 'CANCEL_UPLOAD_DOCUMENT';

/**
 * Export `presignUrlType`.
 */

export const presignUrlType = 'PRESIGN_URL';
