// @flow

/**
 * Module dependencies.
 */

import { Link, color, media, units } from 'pmint-design-system';
import React, { type Node } from 'react';
import config from 'config';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  className?: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, auto);
  padding: 0 ${units(1.5)} 18px;

  ${media.min('xs')`
    grid-column-gap: ${units(1)};
    grid-template-columns: repeat(5, auto);
  `}

  ${media.min('xl')`
    padding: 0 ${units(6)} 18px;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  border-top: 1px solid ${color('gray300')};
  font-weight: 400;
  grid-column: 2 / -2;
  padding: ${units(1)} 0;
  text-align: center;

  &:last-child {
    padding-bottom: 0;
  }

  ${media.min('xs')`
    border-top: none;
    grid-column: auto;
    padding: 0;
  `}
`;

/**
 * `Footer` component.
 */

const Footer = (props: Props): Node => {
  const { translate } = useTranslate();
  const items = [{
    label: translate('footer.about'),
    to: routes.about
  }, {
    href: config.get('externalLinks.faqs'),
    label: translate('footer.faqs'),
    rel: 'noopener',
    target: '_blank'
  }, {
    label: translate('footer.privacy'),
    to: routes.privacyPolicy
  }, {
    href: config.get('externalLinks.termsConditions'),
    label: translate('footer.termsConditions'),
    rel: 'noopener',
    target: '_blank'
  }, {
    href: config.get('externalLinks.contactUs'),
    label: translate('footer.contactUs'),
    rel: 'noopener',
    target: '_blank'
  }];

  return (
    <Wrapper {...props}>
      {items.map(({ label, ...rest }, index) => (
        <StyledLink
          {...rest}
          colorTheme={'secondary'}
          key={index}
          size={'small'}
        >
          {label}
        </StyledLink>
      ))}
    </Wrapper>
  );
};

/**
 * Export `Footer` component.
 */

export default Footer;
