// @flow

/**
 * Module dependencies.
 */

import { Button, Icon, Type, color, media, units } from 'pmint-design-system';
import { logout } from 'client/core/redux/actions/logout';
import { useDispatch } from 'react-redux';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled from 'styled-components';
import successIcon from 'client/assets/svg/success-64px.svg';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  flex: 1 1 ${units(30)};

  ${media.min('xs')`
    flex-grow: 0;
  `}
`;

/**
 * `RemoveAccountSuccess` component.
 */

const RemoveAccountSuccess = (): Node => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleDismiss = useCallback(() => {
    return dispatch(logout());
  }, [dispatch]);

  return (
    <>
      <Icon
        color={color('bender1')}
        icon={successIcon}
        size={units(11)}
      />

      <Type.H5
        as={'h2'}
        color={color('bender1')}
        fontWeight={400}
        marginTop={units(4)}
      >
        {translate('profile.removeAccount.done.title')}
      </Type.H5>

      <RawHtml
        as={'p'}
        color={color('textColorLight')}
        element={Type.Label}
        fontWeight={400}
        marginBottom={'77px'}
        marginTop={units(5)}
      >
        {translate('profile.removeAccount.done.description')}
      </RawHtml>

      <ButtonWrapper>
        <StyledButton
          fullWidth
          icon={arrowIcon}
          onClick={handleDismiss}
        >
          {translate('common.actions.dismiss')}
        </StyledButton>
      </ButtonWrapper>
    </>
  );
};

/**
 * Export `RemoveAccountSuccess` component.
 */

export default RemoveAccountSuccess;
