// @flow

/**
 * Module dependencies.
 */

import { Helmet } from 'react-helmet';
import { ThemeContext } from 'styled-components';
import { color } from 'pmint-design-system';
import React, { type Node, useContext } from 'react';
import config from 'config';
import useTranslate from 'client/hooks/use-translate';

/**
 * `SEO` container.
 */

const SEO = (): Node => {
  const { translate } = useTranslate();
  const theme = useContext(ThemeContext);
  const title = translate('metatags.title');
  const description = translate('metatags.description');

  return (
    <Helmet
      defaultTitle={title}
      titleTemplate={'%s - Public Mint Accounts'}
    >
      <meta
        content={description}
        name={'description'}
      />

      <meta
        content={translate('metatags.keywords')}
        name={'keywords'}
      />

      <meta
        content={'PublicMint'}
        name={'author'}
      />

      <meta
        content={translate('metatags.ogTitle')}
        property={'og:title'}
      />

      <meta
        content={require('client/assets/images/og-image.jpg')}
        property={'og:image'}
      />

      <meta
        content={config.get('baseUrl')}
        property={'og:url'}
      />

      <meta
        content={'website'}
        property={'og:type'}
      />

      <meta
        content={title}
        property={'og:title'}
      />

      <meta
        content={description}
        property={'og:description'}
      />

      <link
        href={require('client/assets/favicons/android-chrome-192x192.png')}
        rel={'icon'}
        sizes={'192x192'}
        type={'image/png'}
      />

      <link
        href={require('client/assets/favicons/android-chrome-512x512.png')}
        rel={'icon'}
        sizes={'512x512'}
        type={'image/png'}
      />

      <link
        href={require('client/assets/favicons/apple-touch-icon.png')}
        rel={'apple-touch-icon'}
        sizes={'180x180'}
      />

      <link
        href={require('client/assets/favicons/favicon-16x16.png')}
        rel={'icon'}
        sizes={'16x16'}
        type={'image/png'}
      />

      <link
        href={require('client/assets/favicons/favicon-32x32.png')}
        rel={'icon'}
        sizes={'32x32'}
        type={'image/png'}
      />

      <link
        href={require('client/assets/favicons/mstile-150x150.png')}
        rel={'icon'}
        sizes={'150x150'}
        type={'image/png'}
      />

      <link
        // $FlowFixMe
        href={require('client/assets/favicons/favicon.ico')}
        rel={'shortcut icon'}
      />

      <link
        content={color('bender6')({ theme })}
        href={require('client/assets/favicons/safari-pinned-tab.svg')}
        rel={'mask-icon'}
      />

      <meta
        content={color('bender1')({ theme })}
        name={'msapplication-TileColor'}
      />
    </Helmet>
  );
};

/**
 * Export `SEO` container.
 */

export default SEO;
