// @flow

/**
 * Module dependencies.
 */

import { Link, Type, color, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import { isString } from 'lodash';
import { useFormActions } from '@seegno/react-forms';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import Input from 'client/components/core/forms/fields/input';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  countdownDate: string,
  email: string,
  onRequestDeleteCode: () => Promise<Object>
|};

/**
 * Filter digits.
 */

const filterDigits = value => {
  return isString(value) ? value.replace(/\D/g, '') : value;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(7)};
  text-align: left;
`;

/**
 * `Subtitle` styled component.
 */

const Subtitle = styled(Type.Paragraph)`
  color: ${color('textColorLight')};
  margin-bottom: ${units(3.5)};
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
`;

/**
 * `ResentCodeLink` styled component.
 */

const ResentCodeLink = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-size: 11px;
  font-weight: 400;
  margin: -6px ${units(0.5)} 45px 0;
`;

/**
 * `convertDateToMilliseconds` util.
 */

function convertDateToMilliseconds(timestamp: string): number {
  return moment().diff(timestamp, 'seconds') * -1000;
}

/**
 * `useCountdown` hook.
 */

const useCountdown = (milliseconds: number) => {
  const [time, setTime] = useState(milliseconds);

  useEffect(() => {
    const tick = setInterval(() => setTime(current => current - 1000), 1000);

    if (time <= 0) {
      clearInterval(tick);

      return;
    }

    return () => {
      clearInterval(tick);
    };
  }, [time]);

  return [time, setTime];
};

/**
 * `RemoveAccountFormContent` component.
 */

function RemoveAccountFormContent(props: Props): Node {
  const { countdownDate, email, onRequestDeleteCode } = props;
  const { translate } = useTranslate();
  const { showInfoMessage } = useSnackbar();
  const [countdown, resetCountdown] = useCountdown(convertDateToMilliseconds(countdownDate));
  const { reset } = useFormActions();
  const handleResendAuthCode = useCallback((event: Event) => {
    event.preventDefault();

    onRequestDeleteCode().then(response => {
      const expirationDate = response?.value?.deleteAccountCodeExpirationDate;

      showInfoMessage(translate('profile.removeAccount.resendSuccess'));
      resetCountdown(convertDateToMilliseconds(expirationDate));
    });
  }, [onRequestDeleteCode, resetCountdown, showInfoMessage, translate]);

  const handleResendClick = useCallback((event: Event) => {
    reset();
    handleResendAuthCode(event);
  }, [handleResendAuthCode, reset]);

  return (
    <Wrapper>
      <Type.Label
        as={'h3'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={units(2)}
        textAlign={'start'}
      >
        {translate('profile.removeAccount.form.title')}
      </Type.Label>

      <RawHtml
        as={'p'}
        element={Subtitle}
      >
        {translate('profile.removeAccount.form.subtitle', {
          email,
          timer: moment.utc(countdown).format('m:ss')
        })}
      </RawHtml>

      <Input
        disabled={!countdown}
        label={translate('profile.removeAccount.form.oneTimeCode')}
        name={'oneTimeCode'}
        onChange={filterDigits}
      />

      <ResentCodeLink>
        <Trans
          components={[
            <StyledLink
              colorTheme={'secondary'}
              key={'recover'}
              onClick={handleResendClick}
              size={'small'}
            />
          ]}
          i18nKey={'profile.removeAccount.form.resendCode'}
        />
      </ResentCodeLink>

      <Input
        label={translate('profile.removeAccount.form.reasonLabel')}
        name={'reason'}
      />
    </Wrapper>
  );
}

/**
 * Export `RemoveAccountFormContent` component.
 */

export default RemoveAccountFormContent;
