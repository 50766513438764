// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { deleteDocumentType } from 'client/core/redux/action-types/delete-document';
import { omit } from 'lodash';

/**
 * Export `deleteDocument`.
 */

export default combineReducers(omit(createRequestReducers({
  method: 'DELETE',
  type: deleteDocumentType
}), ['data']));
