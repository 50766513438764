// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchAuthProvidersState`.
 */

export const getFetchAuthProvidersState = createRequestStateSelector('fetchAuthProviders');
