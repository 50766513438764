// @flow

/**
 * Module dependencies.
 */

import { type User } from 'client/core/types/user';
import React, { Component, type ComponentType, type Node } from 'react';
import sentry from 'core/clients/sentry-browser-client';

/**
 * Export `ErrorInfo` type.
 */

export type ErrorInfo = {
  componentStack: string
};

/**
 * Export `ErrorProps` type.
 */

export type ErrorProps = {|
  error: ?Error,
  errorInfo: ?ErrorInfo,
  sentryEventId: ?string
|};

/**
 * `Props` type.
 */

type Props = {
  children: Node,
  errorComponent: ComponentType<ErrorProps>,
  user?: ?User
};

/**
 * `State` type.
 */

type State = {
  error: ?Error,
  errorInfo: ?ErrorInfo,
  hasError: boolean,
  sentryEventId: ?string
};

/**
 * `ErrorBoundary` component.
 */

class ErrorBoundary extends Component<Props, State> {

  /**
   * Initial state.
   */

  state = {
    error: null,
    errorInfo: null,
    hasError: false,
    sentryEventId: null
  };

  /**
   * Component did catch.
   */

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const sentryEventId = sentry.captureException({
      error,
      errorInfo,
      user: { email: this.props.user?.email }
    });

    this.setState({
      error,
      errorInfo,
      hasError: true,
      sentryEventId
    });
  }

  /**
   * Render.
   */

  render(): Node {
    const { error, errorInfo, hasError, sentryEventId } = this.state;

    if (hasError) {
      const ErrorComponent = this.props.errorComponent;

      return (
        <ErrorComponent
          error={error}
          errorInfo={errorInfo}
          sentryEventId={sentryEventId}
        />
      );
    }

    return this.props.children;
  }

}

/**
 * Export `ErrorBoundary` component.
 */

export default ErrorBoundary;
