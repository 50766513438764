// @flow

/**
 * Module dependencies.
 */

import type { GoogleLoginResponse } from 'client/core/types/auth-provider';
import { Modal } from 'pmint-design-system';
import { camelCase } from 'lodash';
import GoogleLogin from 'react-google-login';
import ModalPortal from 'client/components/core/modal/modal-portal';
import ProviderLoadingIcon from './provider-loading-icon';
import React, { type Node } from 'react';
import config from 'config';
import googleIcon from 'client/assets/svg/google-account-40px.svg';

/**
 * `Props` type.
 */

type Props = {|
  isModalVisible: boolean,
  onSignInFailure: ({ code: string }) => void,
  onSignInSuccess: GoogleLoginResponse => void
|};

/**
 * Google colors.
 */

const googleColors = ['#4285F5', '#EA4436', '#FBBD06', '#34A952'];

/**
 * `SignInWithGoogle` component.
 */

function SignInWithGoogle(props: Props): Node {
  const { isModalVisible, onSignInFailure, onSignInSuccess } = props;

  return (
    <ModalPortal isOpen={isModalVisible}>
      <Modal isVisible={isModalVisible}>
        {isModalVisible && (
          <GoogleLogin
            accessType={'offline'}
            autoLoad
            clientId={config.get('authProvider.google.clientId')}
            onFailure={({ error }) => onSignInFailure({ code: camelCase(error) })}
            onSuccess={({ tokenId }) => onSignInSuccess({ token: tokenId })}
            prompt={'select_account'}
            render={() => (
              <ProviderLoadingIcon
                colors={googleColors}
                icon={googleIcon}
              />
            )}
            responseType={'token'}
          />
        )}
      </Modal>
    </ModalPortal>
  );
}

/**
 * Export `SignInWithGoogle` component.
 */

export default SignInWithGoogle;
