// @flow

/**
 * Module dependencies.
 */

import { get, identity } from 'lodash';
import prettyBytes from 'pretty-bytes';

/**
 * Bytes.
 */

function bytes(value: string) {
  if (!value) {
    return '';
  }

  return prettyBytes(parseInt(value, 10));
}

/**
 * Formats.
 */

const formats = {
  bytes
};

/**
 * Export `format`.
 */

export function format(value: any, format: string, locale: string): string {
  const formatter = get(formats, format, identity);

  return formatter(value, locale);
}
