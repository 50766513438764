// @flow

/**
 * Module dependencies.
 */

import { IconButton, Type, color, media, units } from 'pmint-design-system';
import type { User } from 'client/core/types/user';
import { isEqual } from 'lodash';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import AccountMenuContent from './account-menu-content';
import Avatar from 'client/components/core/avatar';
import React, { type Node } from 'react';
import UserImage from 'client/components/core/user-image';
import WalletCard from './wallet-card';
import editIcon from 'client/assets/svg/edit-32px.svg';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  onChangeImage?: () => void,
  user: User
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-self: center;
  grid-column: 2 / -2;
  margin: 0;

  ${media.min('md')`
    grid-column: 1 / -1;
    margin: 0 ${units(3)};
  `}
`;

/**
 * `AvatarWrapper` styled component.
 */

const AvatarWrapper = styled.div`
  text-align: center;
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  background-color: ${color('white')};
  box-shadow: 0 4px 4px 0 rgba(105, 122, 137, 0.05), 0 6px 18px 0 rgba(105, 122, 137, 0.1), 0 0 1px 0 rgba(105, 122, 137, 0.5);
  left: ${units(10)};
  padding: ${units(0.5)};
  position: absolute;
  top: ${units(9)};
  z-index: 10;
`;

/**
 * `StyledWalletCard` styled component.
 */

const StyledWalletCard = styled(WalletCard)`
  margin-top: ${units(8)};
`;

/**
 * `AccountMenu` component.
 */

const AccountMenu = ({ onChangeImage, user }: Props): Node => {
  const { email, emailConfirmed, identityConfirmed, name, pictureUrl } = user;
  const { translate } = useTranslate();
  const location = useLocation();
  const isNameEqualToEmail = isEqual(name, email);
  const isVisible = matchPath(location.pathname, {
    exact: true,
    path: routes.profile.base
  });

  return (
    <Wrapper>
      <AvatarWrapper>
        <Avatar>
          <UserImage pictureUrl={pictureUrl} />

          {isVisible && (
            <StyledIconButton
              aria-label={translate('profile.changeImage.title')}
              colorTheme={'secondary'}
              icon={editIcon}
              onClick={onChangeImage}
            />
          )}
        </Avatar>
      </AvatarWrapper>

      <Type.Label
        as={'p'}
        color={color('textColor')}
        letterSpacing={0}
        marginBottom={!isNameEqualToEmail ? units(0.5) : units(5)}
        marginTop={'34px'}
        textAlign={'center'}
      >
        {name}
      </Type.Label>

      {!isNameEqualToEmail && (
        <Type.Paragraph
          color={color('textColorLight')}
          lineHeight={'24px'}
          marginBottom={units(5)}
          textAlign={'center'}
        >
          {email}
        </Type.Paragraph>
      )}

      <AccountMenuContent
        emailConfirmed={emailConfirmed}
        identityConfirmed={identityConfirmed}
      />

      <StyledWalletCard />
    </Wrapper>
  );
};

/**
 * Export `AccountMenu` component.
 */

export default AccountMenu;
