// @flow

/**
 * Module dependencies.
 */

import { Container, media, units } from 'pmint-design-system';
import Footer from 'client/components/footer';
import React, { type Node } from 'react';
import Sidebar from './sidebar';
import SidebarWithCircles from './sidebar-with-circles';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  hasCirclesSidebar?: boolean,
  sidebarContent: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    'sidebar'
    'content';
  grid-template-rows: min-content 1fr;
  min-height: 100vh;

  ${media.min('md')`
    grid-template-areas: 'sidebar content';
    grid-template-columns: 33.5% 66.5%;
    grid-template-rows: auto;
    height: 100vh;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: content;

  ${media.min('md')`
    overflow-y: auto;
  `}
`;

/**
 * `Main` styled component.
 */

const Main = styled.main`
  flex: 1;
`;

/**
 * `StyledFooter` styled component.
 */

const StyledFooter = styled(Footer)`
  margin-top: ${units(8)};

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `PageLayout` component.
 */

const PageLayout = (props: Props): Node => {
  const { children, hasCirclesSidebar = true, sidebarContent } = props;

  return (
    <Container>
      <Wrapper>
        {hasCirclesSidebar ? (
          <SidebarWithCircles>
            {sidebarContent}
          </SidebarWithCircles>
        ) : (
          <Sidebar>
            {sidebarContent}
          </Sidebar>
        )}

        <ContentWrapper>
          <Main>
            {children}
          </Main>

          <StyledFooter />
        </ContentWrapper>
      </Wrapper>
    </Container>
  );
};

/**
 * Export `PageLayout` component.
 */

export default PageLayout;
