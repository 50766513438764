// @flow

/**
 * Module dependencies.
 */

import { useFieldActions, useFieldState } from '@seegno/react-forms';
import InputField from 'client/components/core/forms/fields/input';
import React, { type Node, useEffect, useState } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  condition: (value: string, previousValue: string) => boolean,
  disabled?: boolean,
  label: string,
  relatedFieldName: string,
  stateFieldName: string
|};

/**
 * `DependentField` component.
 */

const DependentField = (props: Props): Node => {
  const { condition, relatedFieldName, stateFieldName, ...rest } = props;
  const { value } = useFieldState(relatedFieldName);
  const [previousValue, setPreviousValue] = useState(value);
  const { setFieldValue } = useFieldActions();

  useEffect(() => {
    if (condition(value, previousValue)) {
      setFieldValue(stateFieldName, null);
    }
    setPreviousValue(value);
  }, [condition, previousValue, setFieldValue, stateFieldName, value]);

  return (
    <InputField
      name={stateFieldName}
      {...rest}
    />
  );
};

/**
 * Export `DependentField` component.
 */

export default DependentField;
