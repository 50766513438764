// @flow

/**
 * Module dependencies.
 */

import { type KycInformation } from 'client/core/types/kyc';
import { get } from 'lodash';

/**
 * Export `getKycInformation`.
 */

export const getKycInformation = (state: Object): KycInformation => {
  return get(state, 'kyc');
};
