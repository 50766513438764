// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `confirmEmailType`.
 */

export const confirmEmailType = 'CONFIRM_EMAIL';

/**
 * Export `confirmEmailTypes`.
 */

export const confirmEmailTypes = createRequestActionTypes({
  type: confirmEmailType
});
