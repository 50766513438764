// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { removeAccountType } from 'client/core/redux/action-types/remove-account';

/**
 * Export `removeAccount`.
 */

export default combineReducers(createRequestReducers({
  method: 'DELETE',
  type: removeAccountType
}));
