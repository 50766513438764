// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import { theme } from 'styled-tools';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  ariaHidden?: boolean,
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: inline-flex;
  height: ${theme('layout.avatar.sizeMobile')};
  justify-content: center;
  position: relative;
  width: ${theme('layout.avatar.sizeMobile')};

  ${media.min('xs')`
    height: ${theme('layout.avatar.sizeDesktop')};
    width: ${theme('layout.avatar.sizeDesktop')};
  `}

  &::before {
    background-color: ${color('gray100')};
    border-radius: 50%;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  height: ${units(14)};
  justify-content: center;
  position: relative;
  width: ${units(14)};

  &::before {
    background-color: ${color('white')};
    border-radius: 50%;
    box-shadow: 0 4px 4px 0 rgba(105, 122, 137, 0.05), 0 6px 18px 0 rgba(105, 122, 137, 0.1), 0 0 1px 0 rgba(105, 122, 137, 0.5);
    content: '';
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

/**
 * `Avatar` component.
 */

const Avatar = ({ ariaHidden, children }: Props): Node => {
  return (
    <Wrapper aria-hidden={ariaHidden}>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `Avatar` component.
 */

export default Avatar;
