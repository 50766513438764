// @flow

/**
 * Module dependencies.
 */

import {
  confirmEmail,
  resetConfirmEmail
} from 'client/core/redux/actions/confirm-email';

import { getConfirmEmailState } from 'client/core/redux/selectors/confirm-email';
import { performSetPassword } from 'client/core/redux/actions/perform-set-password';
import { resetSetPassword } from 'client/core/redux/actions/set-password';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageLayout from 'client/components/page-layout';
import React, { type Node, useCallback, useEffect } from 'react';
import SetPasswordForm from 'client/components/set-password/set-password-form';
import SetPasswordLayout from 'client/components/set-password/set-password-layout';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import routes from 'core/routes';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `SetPassword` container.
 */

const SetPassword = (): Node => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { translate } = useTranslate();
  const { email, token } = useQueryString();
  const {
    isFailed: isConfirmEmailFailed
  } = useSelector(getConfirmEmailState);

  const handleSubmit = useCallback(values => {
    const { confirmPassword, password } = values;

    return dispatch(performSetPassword({ confirmPassword, password })).then(() => {
      return history.push(routes.setPasswordSuccess);
    });
  }, [dispatch, history]);

  useEffect(() => {
    if (email && token) {
      dispatch(confirmEmail({ email, token }));
    }
  }, [dispatch, email, token]);

  useEffect(() => {
    return () => {
      dispatch(resetConfirmEmail());
      dispatch(resetSetPassword());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!email || !token) {
      // TODO: Add some error message instead.
      return history.push(routes.home);
    }
  }, [email, history, token]);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <SetPasswordLayout
        description={translate('setPassword.description')}
        errorMessage={translate('setPassword.alreadyVerified')}
        isFailed={isConfirmEmailFailed}
        title={translate('setPassword.title')}
      >
        <SetPasswordForm onSubmit={handleSubmit} />
      </SetPasswordLayout>
    </PageLayout>
  );
};

/**
 * Export `SetPassword` container.
 */

export default SetPassword;
