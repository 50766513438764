// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchDocumentsType } from 'client/core/redux/action-types/fetch-documents';
import { omit } from 'lodash';

/**
 * Export `fetchDocuments`.
 */

export default combineReducers(omit(createRequestReducers({
  type: fetchDocumentsType
}), ['data']));
