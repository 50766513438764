// @flow

/**
 * Module dependencies.
 */

import type { Kyc } from 'client/core/types/kyc';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { denormalizeDocumentTypeAndSide } from 'client/core/utils/kyc-documents';
import { denormalizeTaxInformation } from 'client/core/utils/normalizer';
import { pick } from 'lodash';
import {
  submitIdentityType
} from 'client/core/redux/action-types/submit-identity';

/**
 * Export `submitIdentity`.
 */

export const submitIdentity = ({ kycDocuments, taxData, userData }: Kyc) => {
  const {
    addressInformation,
    taxInformation
  } = denormalizeTaxInformation({ taxData });

  const documents = kycDocuments.map(document => ({
    ...document,
    ...denormalizeDocumentTypeAndSide(document.type, document.side)
  }));

  return createRequestAction({
    apiName: 'app',
    data: {
      ...addressInformation,
      ...taxInformation,
      ...pick(userData, ['birthday', 'gender', 'name']),
      documents,
      phone: {
        number: userData.phone,
        sms: false
      }
    },
    endpoint: 'submitIdentity',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: submitIdentityType
  });
};

/**
 * Export `resetSubmitIdentity`.
 */

export const resetSubmitIdentity = createResetRequestAction({
  method: 'POST',
  type: submitIdentityType
});
