// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import { performDepositExecutedSoonFollowUp } from 'client/core/redux/actions/perform-deposit-executed-soon-follow-up';
import { resetCreateScheduleJob } from 'client/core/redux/actions/create-schedule-job';
import { resetFetchDeposit } from 'client/core/redux/actions/fetch-deposit';
import { resetUpdateDeposit } from 'client/core/redux/actions/update-deposit';
import { useDispatch } from 'react-redux';
import FollowUpLayout from './follow-up-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useEffect } from 'react';
import config from 'config';
import styled from 'styled-components';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4).attrs({ as: 'h2' })`
  color: ${color('bender2')};
  font-weight: 400;
  margin-bottom: ${units(4)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;

  a {
    color: ${color('textColor')};
    font-weight: 500;
    text-decoration: none;

    &:hover, &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `DepositExecutedSoon` container.
 */

const DepositExecutedSoon = (): Node => {
  const dispatch = useDispatch();
  const { followUpToken } = useQueryString();
  const { translate } = useTranslate();

  useEffect(() => {
    if (!followUpToken) {
      return;
    }

    dispatch(performDepositExecutedSoonFollowUp({ followUpToken }));
  }, [dispatch, followUpToken]);

  useEffect(() => {
    return () => {
      dispatch(resetFetchDeposit());
      dispatch(resetCreateScheduleJob());
      dispatch(resetUpdateDeposit());
    };
  }, [dispatch]);

  return (
    <FollowUpLayout>
      <Title>
        {translate('followUp.depositExecutedSoon.title')}
      </Title>

      <RawHtml element={Description}>
        {translate('followUp.depositExecutedSoon.description', {
          email: config.get('supportEmail')
        })}
      </RawHtml>
    </FollowUpLayout>
  );
};

/**
 * Export `DepositExecutedSoon` container.
 */

export default DepositExecutedSoon;
