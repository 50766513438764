// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getRecoverPasswordState`.
 */

export const getRecoverPasswordState = createRequestStateSelector('recoverPassword');
