// @flow

/**
 * Module dependencies.
 */

import { Link, Type, color, media, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import { getCheckIfEmailExistsState } from 'client/core/redux/selectors/check-if-email-exists';
import { getUserPreviousSession } from 'client/core/utils/cookies';
import {
  identityInformationSchema,
  validationOptions
} from 'client/components/register/identity-information-schema';

import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { pick } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import CheckboxField from 'client/components/core/forms/fields/checkbox';
import DateField from 'client/components/core/forms/fields/date-input';
import EmailField from './email-field';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import PhoneNumberField from 'client/components/core/forms/fields/phone-number';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import SubmitButton from 'client/components/core/forms/submit-button';
import config from 'config';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * Initial values.
 */

const initialValues = {
  agreeTermsPrivacy: false,
  birthday: undefined,
  email: undefined,
  gender: undefined,
  name: undefined,
  phone: undefined
};

/**
 * `FieldsWrapper` styled component.
 */

const FieldsWrapper = styled.div`
  display: grid;

  ${media.min('sm')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('gray300')};
  height: 1px;
  margin: 31.5px 0 27.5px;
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 45px;
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  flex: 1 1 ${units(30)};

  ${media.min('xs')`
    flex-grow: 0;
  `}
`;

/**
 * `IdentityInformationForm` component.
 */

const IdentityInformationForm = (): Node => {
  const { translate } = useTranslate();
  const { data } = useSelector(getCheckIfEmailExistsState);
  const [emailExists, setEmailExists] = useState(false);
  const user = getUserPreviousSession();
  const { showErrorMessage } = useSnackbar();
  const isAuthenticated = useSelector(isUserAuthenticated);
  const genderOptions = [{
    label: translate('gender.female'),
    value: 'Female'
  }, {
    label: translate('gender.male'),
    value: 'Male'
  }, {
    label: translate('gender.undefined'),
    value: 'Undefined'
  }];

  const history = useHistory();
  const location = useLocation();
  const handleSubmit = useCallback(values => {
    return history.push(routes.register.taxInformation, {
      userData: values
    });
  }, [history]);

  useEffect(() => {
    if (data) {
      return setEmailExists(data.emailExists);
    }
  }, [data]);

  useEffect(() => {
    if (data?.emailExists) {
      showErrorMessage(translate('kyc.form.errors.fields.email.exists'));
    }
  }, [data, showErrorMessage, translate]);

  return (
    <Form
      errorsBasePath={'register.form.errors.identityInformation'}
      initialValues={pick(location.state ?? user, Object.keys(initialValues)) ?? initialValues}
      jsonSchema={identityInformationSchema}
      onSubmit={handleSubmit}
      validationOptions={validationOptions(emailExists)}
    >
      <InputField
        label={translate('kyc.form.fields.name')}
        name={'name'}
      />

      <FieldsWrapper>
        <DateField
          label={translate('kyc.form.fields.birthday')}
          name={'birthday'}
        />

        <SelectField
          items={genderOptions}
          label={translate('kyc.form.fields.gender')}
          name={'gender'}
        />
      </FieldsWrapper>

      <Type.Label
        as={'h2'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={'27px'}
        marginTop={'21px'}
      >
        {translate('kyc.contactInformation')}
      </Type.Label>

      <FieldsWrapper>
        <PhoneNumberField
          label={translate('kyc.form.fields.phone')}
          name={'phone'}
        />

        <EmailField
          isDisabled={isAuthenticated}
          label={translate('kyc.form.fields.email')}
          name={'email'}
        />
      </FieldsWrapper>

      <Line />

      <CheckboxField
        label={
          <Trans
            components={[
              <Link
                href={config.get('externalLinks.termsConditions')}
                key={'termsAndConditions'}
                rel={'noopener noreferrer'}
                target={'_blank'}
              >
                {' '}
              </Link>,
              <Link
                key={'privacyPolicy'}
                rel={'noopener noreferrer'}
                target={'_blank'}
                to={routes.privacyPolicy}
              >
                {' '}
              </Link>
            ]}
            i18nKey={'kyc.form.fields.agreeTermsPrivacy'}
          />}
        name={'agreeTermsPrivacy'}
      />

      <SubmitButtonWrapper>
        <StyledSubmitButton
          fullWidth
          isSubmitting={false}
        >
          {translate('common.actions.next')}
        </StyledSubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
};

/**
 * Export `IdentityInformationForm` component.
 */

export default IdentityInformationForm;
