// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { fetchAccountType } from 'client/core/redux/action-types/fetch-account';
import { normalizeUser } from 'client/core/utils/normalizer';
import { session } from 'client/core/utils/session';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `fetchAccount`.
 */

export const fetchAccount = () => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'account',
    handleResponse: ({ data }) => {
      const normalizedUser = normalizeUser(data);
      const user = session.get(storageKeys.user);

      if (user) {
        const { name, pictureUrl } = JSON.parse(user);

        return {
          ...normalizedUser,
          name: normalizedUser.name ?? name,
          pictureUrl: normalizedUser.pictureUrl ?? pictureUrl
        };
      }

      return normalizedUser;
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchAccountType
  });
};

/**
 * Export `resetFetchAccount`.
 */

export const resetFetchAccount = createResetRequestAction({
  type: fetchAccountType
});
