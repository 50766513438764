// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import type { KycDocument } from 'client/core/types/kyc-documents';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { setAuthenticated } from 'client/core/redux/actions/set-authenticated';
import { submitKyc } from 'client/core/redux/actions/submit-kyc';

/**
 * `Values` type.
 */

type Values = {|
  kycDocuments: Array<KycDocument>,
  taxData: {
    'address.city': string,
    'address.country': string,
    'address.postalCode': string,
    'address.state'?: string,
    'address.street': string,
    'address.street2': string,
    'tax.country': string,
    'tax.idNumber': string,
    'tax.state'?: string
  },
  userData: {
    birthday: string,
    email: string,
    gender: string,
    name: string,
    phone: string
  }
|};

/**
 * Export `performSubmitKyc`.
 */

export const performSubmitKyc = (values: Values) => (dispatch: Dispatch) => {
  return dispatch(submitKyc(values)).then(() => {
    return dispatch(fetchAccount()).then(() => {
      return dispatch(setAuthenticated());
    });
  });
};
