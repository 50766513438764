// @flow

/**
 * Module dependencies.
 */

import { Loader, Type, color, media, units } from 'pmint-design-system';
import {
  fetchDocuments,
  resetFetchDocuments
} from 'client/core/redux/actions/fetch-documents';

import {
  getDocuments,
  getRemoteDocuments,
  getUploadedDocuments
} from 'client/core/redux/selectors/documents';

import { getFetchDocumentsState } from 'client/core/redux/selectors/fetch-documents';
import { getLogoutState } from 'client/core/redux/selectors/logout';
import { getUser } from 'client/core/redux/selectors/user';
import { isEmpty } from 'lodash';
import { isKycMissingError } from 'client/core/utils/kyc';
import { logout, resetLogout } from 'client/core/redux/actions/logout';
import { performSubmitDocuments } from 'client/core/redux/actions/perform-submit-documents';
import { resetSubmitDocuments } from 'client/core/redux/actions/submit-documents';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountMenu from 'client/components/core/account-menu/account-menu';
import BackLink from 'client/components/core/back-link';
import DeleteDocumentModal from 'client/components/documents/delete-document-modal';
import DocumentsList from 'client/components/documents/documents-list';
import ErrorMessage from 'client/components/core/error-message';
import Header from 'client/components/core/header';
import ModalPortal from 'client/components/core/modal/modal-portal';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import UploadDocuments from 'client/components/documents/upload-documents';
import UploadDocumentsModalSuccess from 'client/components/documents/upload-documents-modal-success';
import config from 'config';
import documentIcon from 'client/assets/svg/document-32px.svg';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content min-content auto;

  ${media.min('md')`
    grid-template-columns: 1fr 6fr 1fr;
    min-height: 100vh;
  `}
`;

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-left: ${units(3)};
  margin-top: 25px;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2;
  padding-top: ${units(11)};

  ${media.min('xxl')`
    justify-self: center;
    max-width: ${units(90)};
    width: 100%;
  `}
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  text-align: center;

  > a {
    color: ${color('bender2')};
  }
`;

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Paragraph).attrs({ as: 'h2' })`
  color: ${color('textColorLight')};
  margin-bottom: 52px;
`;

/**
 * `Documents` container.
 */

const Documents = (): Node => {
  const { errors, isLoading, isSuccessful } = useSelector(getFetchDocumentsState);
  const { errors: logoutErrors } = useSelector(getLogoutState);
  const { translate } = useTranslate();
  const [documentIdToDelete, setDocumentIdToDelete] = useState(null);
  const [documentsErrors, setDocumentsErrors] = useState(null);
  const [isAwaitingDocumentStatus, setIsAwaitingDocumentStatus] = useState(false);
  const [isPageLoading, setPageLoading] = useState(isLoading);
  const remoteDocuments = useSelector(getRemoteDocuments);
  const allDocuments = useSelector(getDocuments);
  const history = useHistory();
  const alreadyHaveAllDocuments = remoteDocuments.length > 1;
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const uploadedDocuments = useSelector(getUploadedDocuments);
  const user = useSelector(getUser);

  const [
    isDeleteDocumentModalVisible,
    setDeleteDocumentModalVisible
  ] = useState(false);

  const [
    isUploadDocumentsModalVisible,
    setUploadDocumentsModalVisible
  ] = useState(false);

  const handleCloseDeleteDocumentModal = useCallback(() => {
    setDocumentIdToDelete(null);
    setDeleteDocumentModalVisible(false);
  }, []);

  const handleCloseUploadDocumentsModal = useCallback(() => {
    return setUploadDocumentsModalVisible(false);
  }, []);

  const handleOpenDeleteDocumentModal = useCallback(documentId => {
    setDocumentIdToDelete(documentId);
    setDeleteDocumentModalVisible(true);
  }, []);

  const handleSubmitDocuments = useCallback(() => {
    const kycDocuments = uploadedDocuments.map(({ side, type, uploadFields }) => ({
      key: uploadFields.key,
      side,
      type
    }));

    setIsAwaitingDocumentStatus(true);

    return dispatch(performSubmitDocuments({ kycDocuments })).then(() => {
      setUploadDocumentsModalVisible(true);
    }).catch(error => {
      setDocumentsErrors(error);
    }).finally(() => {
      setIsAwaitingDocumentStatus(false);
    });
  }, [dispatch, uploadedDocuments]);

  useEffect(() => {
    if (isEmpty(allDocuments)) {
      setPageLoading(true);

      dispatch(fetchDocuments())
        .catch(error => {
          if (isKycMissingError(error?.code)) {
            history.push(routes.register.identityInformation);
          }
        })
        .finally(() => setPageLoading(false));
    }
  }, [dispatch, allDocuments, history]);

  useEffect(() => {
    return () => {
      dispatch(resetLogout());
      dispatch(resetSubmitDocuments());
      dispatch(resetFetchDocuments());
    };
  }, [dispatch]);

  useEffect(() => {
    if (documentsErrors) {
      setDocumentsErrors(null);
    }
  }, [documentsErrors]);

  useNetworkErrorMessage('logout.errors.network', logoutErrors);
  useNetworkErrorMessage('kyc.documents.errors.submitDocuments', documentsErrors);

  return (
    <>
      <PageLayout
        hasCirclesSidebar={false}
        sidebarContent={<AccountMenu user={user} />}
      >
        <Wrapper>
          <Header
            icon={documentIcon}
            onLogout={handleLogout}
            title={translate('kyc.documents.title')}
          />

          <BackLinkWrapper>
            <BackLink to={routes.profile.base} />
          </BackLinkWrapper>

          <Content>
            {isPageLoading && (
              <LoaderWrapper>
                <Loader
                  colorTheme={color('bender2')}
                  size={units(6)}
                  stroke={units(0.5)}
                />
              </LoaderWrapper>
            )}

            {errors && (
              <ErrorMessage title={translate('kyc.documents.errors.loadDocuments.title')}>
                <RawHtml element={Description}>
                  {translate('kyc.documents.errors.loadDocuments.description', {
                    email: config.get('supportEmail')
                  })}
                </RawHtml>
              </ErrorMessage>
            )}

            {(isSuccessful || !isEmpty(allDocuments)) && isEmpty(errors) && (
              <>
                <Type.Label
                  as={'h2'}
                  color={color('textColor')}
                  fontWeight={500}
                  marginBottom={units(3)}
                >
                  {translate('kyc.documents.subtitle')}
                </Type.Label>

                {alreadyHaveAllDocuments ? (
                  <>
                    <RawHtml element={SubTitle}>
                      {translate('kyc.documents.view.description')}
                    </RawHtml>

                    <DocumentsList
                      documents={remoteDocuments}
                      onDeleteDocument={handleOpenDeleteDocumentModal}
                    />
                  </>
                ) : (
                  <UploadDocuments
                    documents={allDocuments}
                    isSubmitting={isAwaitingDocumentStatus}
                    onDeleteDocument={handleOpenDeleteDocumentModal}
                    onSubmitDocuments={handleSubmitDocuments}
                  />
                )}
              </>
            )}
          </Content>
        </Wrapper>
      </PageLayout>

      <ModalPortal isOpen={isDeleteDocumentModalVisible}>
        <DeleteDocumentModal
          documentId={documentIdToDelete}
          isVisible={isDeleteDocumentModalVisible}
          onRequestClose={handleCloseDeleteDocumentModal}
        />
      </ModalPortal>

      <ModalPortal isOpen={isUploadDocumentsModalVisible}>
        <UploadDocumentsModalSuccess
          isVisible={isUploadDocumentsModalVisible}
          onRequestClose={handleCloseUploadDocumentsModal}
        />
      </ModalPortal>
    </>
  );
};

/**
 * Export `Documents` container.
 */

export default Documents;
