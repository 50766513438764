// @flow

/**
 * Module dependencies.
 */

import { type KycDocument } from 'client/core/types/kyc-documents';
import {
  addDocumentFileType,
  addInitialFieldsType,
  addUploadDocumentErrorsType,
  addUploadProgressType,
  addUploadedDocumentType,
  cancelUploadDocumentType
} from 'client/core/redux/action-types/upload-documents';

import { deleteDocumentTypes } from 'client/core/redux/action-types/delete-document';
import { documentSides } from 'client/core/utils/kyc-documents';
import { fetchDocumentsTypes } from 'client/core/redux/action-types/fetch-documents';
import { isEmpty } from 'lodash';
import { nanoid } from 'nanoid';

/**
 * Sides.
 */

const sides = [documentSides.frontSide, documentSides.backSide];

/**
 * Empty document.
 */

const emptyDocument = {
  errors: null,
  file: null,
  id: null,
  isCompleted: false,
  isLoading: false,
  isRemote: false,
  label: null,
  progress: 0,
  side: null,
  status: null,
  type: null,
  uploadFields: null,
  wasCancelled: false
};

/**
 * Documents.
 */

function documents(state: Array<KycDocument> = [], action: Object = {}): Array<KycDocument> {
  const { payload, type } = action;

  switch (type) {
    case addDocumentFileType:
      return sides.map(documentSide => {
        return {
          ...emptyDocument,
          id: nanoid(),
          side: documentSide,
          type: payload.documentType
        };
      });

    case addInitialFieldsType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...document,
            errors: null,
            file: payload.file,
            wasCancelled: false
          };
        }

        return document;
      });

    case addUploadDocumentErrorsType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...document,
            errors: payload.errors
          };
        }

        return document;
      });

    case addUploadProgressType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...document,
            isLoading: true,
            progress: payload.progress
          };
        }

        return document;
      });

    case addUploadedDocumentType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...document,
            isCompleted: true,
            isLoading: false,
            uploadFields: payload.fields
          };
        }

        return document;
      });

    case cancelUploadDocumentType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...document,
            errors: null,
            file: null,
            isCompleted: false,
            isLoading: false,
            progress: 0,
            uploadFields: null,
            wasCancelled: true
          };
        }

        return document;
      });

    case deleteDocumentTypes.successType:
      return state.map(document => {
        if (document.id === payload.id) {
          return {
            ...emptyDocument,
            ...document,
            id: nanoid(),
            isRemote: false,
            label: null,
            status: null
          };
        }

        return document;
      });

    case fetchDocumentsTypes.successType: {
      if (!isEmpty(payload)) {
        return sides.map(documentSide => {
          const document = payload.find(document => document.side === documentSide);

          if (document) {
            return {
              ...emptyDocument,
              ...document,
              id: document.id,
              isRemote: true
            };
          }

          return {
            ...emptyDocument,
            id: nanoid(),
            side: documentSide,
            type: payload[0].type
          };
        });
      }

      return state;
    }

    default:
      return state;
  }
}

/**
 * Export `documents`.
 */

export default documents;
