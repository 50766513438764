// @flow

/**
 * Export `getAuthorizationHeader`.
 */

export function getAuthorizationHeader(token: ?string): Object {
  return !token ? {} : {
    Authorization: `Bearer ${token}`
  };
}
