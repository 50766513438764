// @flow

/**
 * Module dependencies.
 */

import { Route, Switch } from 'react-router-dom';
import { fetchAccount, resetFetchAccount } from 'client/core/redux/actions/fetch-account';
import { getUser } from 'client/core/redux/selectors/user';
import { getUserPreviousSession } from 'client/core/utils/cookies';
import { isNil } from 'lodash';
import { logout, resetLogout } from 'client/core/redux/actions/logout';
import { resetChangeImage } from 'client/core/redux/actions/change-image';
import { resetUploadImage } from 'client/core/redux/actions/upload-image';
import { useDispatch, useSelector } from 'react-redux';
import AccountMenu from 'client/components/core/account-menu/account-menu';
import ChangeImageModal from 'client/components/profile/change-image/change-image-modal';
import ChangePassword from './change-password';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import ModalPortal from 'client/components/core/modal/modal-portal';
import PageLayout from 'client/components/page-layout';
import ProfileContent from 'client/components/profile/profile-content';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import Redirect from 'client/components/routing/redirect';
import RemoveAccountModal from 'client/components/profile/remove-account/remove-account-modal';
import profileIcon from 'client/assets/svg/profile-32px.svg';
import routes from 'core/routes';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Profile` container.
 */

function Profile(): Node {
  const [isChangeImageModalVisible, setChangeImageModalVisible] = useState(false);
  const [isRemoveAccountModalVisible, setRemoveAccountModalVisible] = useState(false);
  const prevSession = getUserPreviousSession() ?? {};
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const user = useSelector(getUser);
  const userHasExternalIdentity = prevSession?.identityProvider !== 'pmint';
  const handleCloseChangeImageModal = useCallback(() => {
    setChangeImageModalVisible(false);
    dispatch(resetUploadImage());
    dispatch(resetChangeImage());
    dispatch(resetFetchAccount());
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(resetLogout());
  }, [dispatch]);

  useEffect(() => {
    if (!isNil(user) && !user.emailConfirmed) {
      const timeout = setTimeout(() => {
        dispatch(fetchAccount());
      }, 60000);

      return () => {
        clearTimeout(timeout);
        dispatch(resetFetchAccount());
      };
    }
  }, [dispatch, user]);

  return (
    <PageLayout
      hasCirclesSidebar={false}
      sidebarContent={
        <AccountMenu
          onChangeImage={() => setChangeImageModalVisible(true)}
          user={user}
        />
      }
    >
      <ErrorBoundary errorComponent={DefaultErrorMessage}>
        <Switch>
          {!userHasExternalIdentity && (
            <Route
              exact
              path={routes.profile.changePassword}
            >
              <ChangePassword
                icon={profileIcon}
                onLogout={handleLogout}
                title={translate('profile.title')}
              />
            </Route>
          )}

          <Route
            exact
            path={routes.profile.base}
          >
            <ProfileContent
              hideChangePassword={userHasExternalIdentity}
              icon={profileIcon}
              onLogout={handleLogout}
              onRemoveAccount={() => setRemoveAccountModalVisible(true)}
              title={translate('profile.title')}
              user={user}
            />

            <ModalPortal isOpen={isChangeImageModalVisible}>
              <ChangeImageModal
                isVisible={isChangeImageModalVisible}
                onRequestClose={handleCloseChangeImageModal}
              />
            </ModalPortal>

            <ModalPortal isOpen={isRemoveAccountModalVisible}>
              <RemoveAccountModal
                isVisible={isRemoveAccountModalVisible}
                onRequestClose={() => setRemoveAccountModalVisible(false)}
              />
            </ModalPortal>
          </Route>

          <Redirect
            status={302}
            to={routes.profile.base}
          />
        </Switch>
      </ErrorBoundary>
    </PageLayout>
  );
}

/**
 * Export `Profile` container.
 */

export default Profile;
