// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { updateDepositType } from 'client/core/redux/action-types/update-deposit';

/**
 * `Props` type.
 */

type Props = {|
  followUpToken: string,
  patchData: Object
|};

/**
 * Export `updateDeposit`.
 */

export const updateDeposit = ({ followUpToken, patchData }: Props) => {
  return createRequestAction({
    apiName: 'app',
    data: patchData,
    endpoint: {
      name: 'updateDeposit',
      params: { followUpToken }
    },
    handleResponse: ({ data }) => data,
    method: 'PATCH',
    type: updateDepositType
  });
};

/**
 * Export `resetUpdateDeposit`.
 */

export const resetUpdateDeposit = createResetRequestAction({
  method: 'PATCH',
  type: updateDepositType
});
