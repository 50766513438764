// @flow

/**
 * Module dependencies.
 */

import type { AuthenticationStatus } from 'client/core/redux/reducer/authentication';

/**
 * Export `getAuthenticationStatus`.
 */

export function getAuthenticationStatus(state: Object): AuthenticationStatus {
  return state?.authentication?.status ?? 'unknown';
}

/**
 * Export `isUserAuthenticated`.
 */

export function isUserAuthenticated(state: Object): boolean {
  return getAuthenticationStatus(state) === 'authenticated';
}

/**
 * Export `getAuthenticationToken`.
 */

export function getAuthenticationToken(state: Object): ?string {
  return state?.authentication?.token;
}
