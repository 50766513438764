// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import { createScheduleJob } from 'client/core/redux/actions/create-schedule-job';
import { fetchDeposit } from 'client/core/redux/actions/fetch-deposit';
import { updateDeposit } from 'client/core/redux/actions/update-deposit';
import config from 'config';

/**
 * `Values` type.
 */

type Values = {|
  followUpToken: string
|};

/**
 * Export `performDepositExecutedSoonFollowUp`.
 */

export const performDepositExecutedSoonFollowUp = (values: Values) => (dispatch: Dispatch) => {
  const { followUpToken } = values;
  const {
    callbackUrl,
    deposits: {
      secondFollowUp: {
        action,
        maximumAllowedAttempts,
        waitingTimeInSeconds
      }
    }
  } = config.get('scheduler');

  return dispatch(fetchDeposit({ followUpToken }))
    .then(({ value }) => {
      const { id, wireFollowUpTries } = value;

      if (wireFollowUpTries >= maximumAllowedAttempts) {
        return;
      }

      const payload = {
        action,
        resource: { id }
      };

      return dispatch(createScheduleJob({
        callbackUrl,
        payload: JSON.stringify(payload),
        waitingTimeInSeconds
      }));
    }).then(() => dispatch(updateDeposit({
      followUpToken,
      patchData: { isFollowUpHandled: true }
    })));
};
