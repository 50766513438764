// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getSignInState`.
 */

export const getSignInState = createRequestStateSelector('signIn');
