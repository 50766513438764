// @flow

import type { KycStatus } from 'client/core/types/user';
import {
  cookiesStorage,
  getUserPreviousSession,
  saveUserSession
} from 'client/core/utils/cookies';

import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `kycErrors`.
 */

export const kycErrors = [
  'user_does_not_have_an_identity_associated'
];

/**
 * Export `isKycMissingError`.
 */

export function isKycMissingError(errorCode: string): boolean {
  return kycErrors.includes(errorCode);
}

/**
 * Export `updateKycStatusSession`.
 */

export const updateSessionKycStatus = (kycStatus: KycStatus) => {
  const session = getUserPreviousSession();

  if (!session || !kycStatus) {
    return;
  }

  const { email, identityProvider, name } = session;

  saveUserSession({
    identityProvider,
    token: cookiesStorage.get(storageKeys.token),
    user: { email, kycStatus, name }
  });
};
