// @flow

/**
 * Module dependencies.
 */

import { type Context, createContext, useContext } from 'react';

/**
 * Export `FormErrorsBasePathContext` context.
 */

export const FormErrorsBasePathContext: Context<?string> = createContext();

/**
 * Export `useFormErrorsBasePath` hook.
 */

export const useFormErrorsBasePath = () => useContext(FormErrorsBasePathContext);
