// @flow

/**
 * Module dependencies.
 */

import type { KycDocument } from 'client/core/types/kyc-documents';
import { get } from 'lodash';

/**
 * Export `getDocuments`.
 */

export const getDocuments = (state: Object): Array<KycDocument> => {
  return get(state, 'documents', []);
};

/**
 * Export `getRemoteDocuments`.
 */

export const getRemoteDocuments = (state: Object): Array<KycDocument> => {
  const documents = get(state, 'documents');

  return documents.filter(document => document.isRemote);
};

/**
 * Export `getUploadedDocuments`.
 */

export const getUploadedDocuments = (state: Object): Array<KycDocument> => {
  const documents = get(state, 'documents');

  return documents.filter(document => !document.isRemote && document.isCompleted);
};
