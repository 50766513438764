// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { resetPasswordType } from 'client/core/redux/action-types/reset-password';

/**
 * `Options` type.
 */

type Options = {
  confirmPassword: string,
  id: string,
  password: string,
  resetPasswordToken: string
};

/**
 * Export `resetPassword`.
 */

export const resetPassword = (options: Options) => {
  const { confirmPassword, id, password, resetPasswordToken } = options;

  return createRequestAction({
    apiName: 'app',
    data: { confirmPassword, password, resetPasswordToken },
    endpoint: {
      name: 'resetPassword',
      params: { id }
    },
    handleResponse: response => response.data,
    method: 'PATCH',
    type: resetPasswordType
  });
};

/**
 * Export `resetResetPassword`.
 */

export const resetResetPassword = createResetRequestAction({
  method: 'PATCH',
  type: resetPasswordType
});
