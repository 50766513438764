// @flow

/**
 * Module dependencies.
 */

import { useFieldActions, useFieldState } from '@seegno/react-forms';
import React, { type Node, useEffect } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  disabled: boolean,
  items: Object
|};

/**
 * `AddressCountryField` component.
 */

const AddressCountryField = (props: Props): Node => {
  const { disabled, items } = props;
  const { value } = useFieldState('tax.country');
  const { translate } = useTranslate();
  const fieldActions = useFieldActions();

  useEffect(() => {
    if (value) {
      return fieldActions.setFieldValue('address.country', value);
    }
  }, [fieldActions, value]);

  return (
    <SelectField
      disabled={disabled}
      items={items}
      label={translate('kyc.form.fields.country')}
      name={'address.country'}
    />
  );
};

/**
 * Export `AddressCountryField` component.
 */

export default AddressCountryField;
