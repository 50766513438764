// @flow

/**
 * Module dependencies.
 */

import { type Context, createContext, useContext } from 'react';

/**
 * Export `SnackbarContext` context.
 */

export const SnackbarContext: Context<{
  showErrorMessage: (content: string, options?: Object) => void,
  showInfoMessage: (content: string, options?: Object) => void,
  showSuccessMessage: (content: string, options?: Object) => void,
  showWarningMessage: (content: string, options?: Object) => void
}> = createContext({});

/**
 * Export `useSnackbar` hook.
 */

export const useSnackbar = () => useContext(SnackbarContext);
