// @flow

/**
 * Module dependencies.
 */

import { GlobalStyle } from 'pmint-design-system';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from 'client/styles/theme';
import About from 'client/containers/about';
import Analytics from 'client/containers/core/analytics';
import ConditionalRoute from 'client/components/routing/conditional-route';
import DefaultErrorMessage from 'client/components/core/error-boundaries/default-error-message';
import Documents from 'client/containers/documents';
import ErrorBoundary from 'client/components/core/error-boundaries/error-boundary';
import Fees from 'client/containers/fees';
import FetchUser from 'client/containers/core/fetch-user';
import FollowUp from 'client/containers/follow-up';
import Home from 'client/containers/home';
import Kyc from 'client/containers/kyc';
import LocaleCookieUrlSyncer from 'client/containers/core/locale-cookie-url-syncer';
import PrivacyPolicy from 'client/containers/privacy-policy';
import Profile from 'client/containers/profile';
import React, { type Node } from 'react';
import RecoverPassword from 'client/containers/recover-password';
import Redirect from 'client/components/routing/redirect';
import Register from 'client/containers/register';
import ResetPassword from 'client/containers/reset-password';
import ResetPasswordSuccess from 'client/containers/reset-password-success';
import SEO from 'client/containers/core/seo';
import ScrollRestoration from 'client/components/routing/scroll-restoration';
import SessionListener from 'client/containers/core/session-listener';
import SetPassword from 'client/containers/set-password';
import SetPasswordSuccess from 'client/containers/set-password-success';
import SignIn from 'client/containers/sign-in';
import SignInWelcomeBack from 'client/containers/welcome-back';
import SnackbarProvider from 'client/containers/core/snackbar/snackbar-provider';
import UnauthenticatedRoute from 'client/components/routing/unauthenticated-route';
import config from 'config';
import routes from 'core/routes';

/**
 * `Root` container.
 */

function Root(): Node {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary errorComponent={DefaultErrorMessage}>
        <SnackbarProvider>
          <SEO />

          <GlobalStyle />

          <LocaleCookieUrlSyncer languages={config.get('localization.whitelist')} />

          <Analytics />

          <SessionListener />

          <ScrollRestoration />

          <ErrorBoundary errorComponent={DefaultErrorMessage}>
            <Switch>
              <Route
                component={Home}
                exact
                path={routes.home}
              />

              <Route
                component={ResetPassword}
                exact
                path={routes.resetPassword}
              />

              <Route
                component={ResetPasswordSuccess}
                exact
                path={routes.resetPasswordSuccess}
              />

              <Route
                component={SetPassword}
                exact
                path={routes.setPassword}
              />

              <ConditionalRoute
                component={SetPasswordSuccess}
                exact
                path={routes.setPasswordSuccess}
              />

              <Route
                component={RecoverPassword}
                exact
                path={routes.recoverPassword}
              />

              <Route
                component={About}
                exact
                path={routes.about}
              />

              <Route
                component={PrivacyPolicy}
                exact
                path={routes.privacyPolicy}
              />

              <Route
                component={Fees}
                exact
                path={routes.fees}
              />

              <UnauthenticatedRoute
                component={SignIn}
                exact
                isUserWithoutKycAllowed
                path={routes.signIn.base}
              />

              <UnauthenticatedRoute
                component={SignInWelcomeBack}
                exact
                path={routes.signIn.welcomeBack}
              />

              <UnauthenticatedRoute
                component={Register}
                isUserWithoutKycAllowed
                path={routes.register.base}
              />

              <Route
                component={FollowUp}
                path={routes.followUp.base}
              />

              <Route path={'*'}>
                <FetchUser>
                  <Switch>
                    <ConditionalRoute
                      component={Kyc}
                      exact
                      path={routes.kyc}
                    />

                    <ConditionalRoute
                      component={Documents}
                      exact
                      path={routes.documents}
                    />

                    <ConditionalRoute
                      component={Profile}
                      path={routes.profile.base}
                    />

                    <Redirect
                      from={'*'}
                      status={302}
                      to={routes.home}
                    />
                  </Switch>
                </FetchUser>
              </Route>
            </Switch>
          </ErrorBoundary>
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}
/**
 * Export `Root` container.
 */

export default Root;
