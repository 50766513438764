// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { fetchDocumentsType } from 'client/core/redux/action-types/fetch-documents';
import { normalizeKycDocuments } from 'client/core/utils/normalizer';

/**
 * Export `fetchDocuments`.
 */

export const fetchDocuments = () => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'documents',
    handleResponse: ({ data }) => normalizeKycDocuments(data),
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchDocumentsType
  });
};

/**
 * Export `resetFetchDocuments`.
 */

export const resetFetchDocuments = createResetRequestAction({
  type: fetchDocumentsType
});
