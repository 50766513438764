// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { omit } from 'lodash';
import { updateKycType } from 'client/core/redux/action-types/update-kyc';

/**
 * Export `updateKyc`.
 */

export default combineReducers(omit(createRequestReducers({
  method: 'PATCH',
  type: updateKycType
}), ['data']));
