// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import AccountPolling from 'client/components/register/account-polling';
import React, { type Node } from 'react';
import RegisterLayout from 'client/components/register/register-layout';
import useTranslate from 'client/hooks/use-translate';

/**
 * `IdentityVerificationSuccess` container.
 */

const IdentityVerificationSuccess = (): Node => {
  const { translate } = useTranslate();
  const progressBarItems = [{
    label: translate('progressBar.register.identityInformation'),
    state: 'complete'
  }, {
    label: translate('progressBar.register.taxInformation'),
    state: 'complete'
  }, {
    label: translate('progressBar.register.identityVerification'),
    state: 'complete'
  }];

  return (
    <RegisterLayout progressBarItems={progressBarItems}>
      <Type.Label
        as={'h1'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={units(3)}
      >
        {translate('register.identityVerificationTitle')}
      </Type.Label>

      <AccountPolling />
    </RegisterLayout>
  );
};

/**
 * Export `IdentityVerificationSuccess` container.
 */

export default IdentityVerificationSuccess;
