// @flow

/**
 * Module dependencies.
 */

import { type Dispatch, type MiddlewareAPI } from 'redux';
import { handleError as baseHandleError } from 'client/core/redux/utils/errors';
import { get, isEmpty, merge, noop } from 'lodash';
import { getAuthenticationToken } from 'client/core/redux/selectors/authentication';
import { getAuthorizationHeader } from 'client/core/utils/headers';
import { isSessionExpiredError } from 'client/core/utils/errors';
import { refreshToken } from 'client/core/redux/actions/refresh-token';

/**
 * Export `authMiddleware`.
 */

export default ({ dispatch, getState }: MiddlewareAPI<*, *, *>) => {
  return (next: Dispatch<any>) => (action: Object) => {
    if (!get(action, 'meta.request') || !get(action, 'meta.auth.isAuthenticated')) {
      return next(action);
    }

    const handleError = get(action, 'meta.request.handleError', baseHandleError);
    const getRequestHeaders = get(action, 'meta.request.getHeaders', noop);
    const getHeaders = () => {
      return Promise.resolve(getRequestHeaders()).then(headers => ({
        ...headers,
        ...getAuthorizationHeader(getAuthenticationToken(getState()))
      }));
    };

    return next(merge({}, action, {
      meta: {
        request: {
          getHeaders,
          handleError: (error: Object) => {
            if (!isEmpty(error.response)) {
              const { response } = error;

              if (isSessionExpiredError(response)) {
                return dispatch(refreshToken())
                  .then(() => next(merge({}, action, {
                    meta: {
                      request: {
                        getHeaders
                      }
                    }
                  })))
                  .then(result => get(result, 'value'));
              }
            }

            return handleError(error);
          }
        }
      }
    }));
  };
};
