// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { fetchUserInformationType } from 'client/core/redux/action-types/fetch-user-information';
import { normalizeKycInformation } from 'client/core/utils/normalizer';

/**
 * Export `fetchUserInformation`.
 */

export const fetchUserInformation = () => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'identityInformation',
    handleResponse: ({ data }) => {
      return normalizeKycInformation(data);
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    type: fetchUserInformationType
  });
};

/**
 * Export `resetFetchUserInformation`.
 */

export const resetFetchUserInformation = createResetRequestAction({
  type: fetchUserInformationType
});
