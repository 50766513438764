// @flow

/**
 * Module dependencies.
 */

import { Box, Icon, Type, color, units } from 'pmint-design-system';
import { get } from 'lodash';
import { regex } from 'pmint_core_validator';
import { useField } from '@seegno/react-forms';
import React, { type Node } from 'react';
import checkIcon from 'pmint-design-system/icons/check-28px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  name: string
};

/**
 * Requirements order.
 */

const requirementsOrder = [
  'upperCaseOrLowerCase',
  'specialCharacterOrNumber',
  'passwordSize'
];

/**
 * Requirements.
 */

const requirements = requirementsOrder.map(requirement => ({
  name: requirement,
  regex: new RegExp(get(regex.passwordRequirements, requirement))
}));

/**
 * Get password level.
 */

function getPasswordLevel(value) {
  const levels = [null, 'weak', 'good', 'strong'];
  let counter = 0;

  requirements.forEach(({ regex }) => {
    if (regex.test(value)) {
      counter += 1;
    }
  });

  return levels[counter];
}

/**
 * Password level icon.
 */

const passwordLevelIcon = {
  good: {
    icon: '😄'
  },
  strong: {
    icon: '😎'
  },
  weak: {
    icon: '😔'
  }
};

/**
 * `PasswordLevelWrapper` styled component.
 */

const PasswordLevelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${units(2.5)} ${units(2)};
`;

/**
 * `RequirementsWrapper` styled component.
 */

const RequirementsWrapper = styled.div`
  border: 1px solid ${color('gray300')};
  border-radius: ${units(1)};
  padding: ${units(2)} ${units(2.5)};
`;

/**
 * `PasswordRequirementsWrapper` styled component.
 */

const PasswordRequirementsWrapper = styled.div`
  display: grid;
  grid-gap: ${units(0.5)} ${units(1)};
  grid-template-columns: auto auto;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  align-self: center;
  height: ${units(2)};
  justify-self: end;
`;

/**
 * `PasswordValidation` component.
 */

const PasswordValidation = (props: Props): Node => {
  const { name } = props;
  const { translate } = useTranslate();
  const { value } = useField(name);
  const passwordLevel = getPasswordLevel(value);

  return (
    <>
      <PasswordLevelWrapper>
        <Type.Small
          as={'h6'}
          color={color('gray700')}
          fontWeight={500}
        >
          {translate('setPassword.form.passwordRequirements.title')}
        </Type.Small>

        {passwordLevel !== null && (
          <Box>
            <Type.Small
              as={'p'}
              color={color('bender2')}
              display={'inline'}
              marginRight={units(1)}
            >
              {translate(`common.password.level.${passwordLevel}`)}
            </Type.Small>

            <Icon
              icon={passwordLevelIcon[passwordLevel].icon}
              size={units(2)}
            />
          </Box>
        )}
      </PasswordLevelWrapper>

      <RequirementsWrapper>
        <PasswordRequirementsWrapper>
          {requirements.map(({ name, regex }) => {
            const isValid = regex.test(value);
            const textColor = isValid ? color('bender2') : color('textColorLight');

            return (
              <React.Fragment
                key={name}
              >
                <Type.Small
                  as={'p'}
                  color={textColor}
                  key={`${name}-text`}
                >
                  {translate(`setPassword.form.passwordRequirements.${name}`)}
                </Type.Small>

                <StyledIcon
                  color={textColor}
                  icon={checkIcon}
                  key={`${name}-icon`}
                  size={units(2)}
                />
              </React.Fragment>
            );
          })}
        </PasswordRequirementsWrapper>
      </RequirementsWrapper>
    </>
  );
};

/**
 * Export `PasswordValidation` component.
 */

export default PasswordValidation;
