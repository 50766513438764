// @flow

/**
 * Module dependencies.
 */

import {
  Button,
  Icon,
  Modal,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import backIcon from 'client/assets/svg/back-32px.svg';
import checkIcon from 'client/assets/svg/check-72px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * `Wrapper` styled component;
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px;

  ${media.min('sm')`
    padding: 70px ${units(7.5)} ${units(10)};
  `}

  ${media.min('lg')`
    padding-left: ${units(15)};
    padding-right: ${units(15)};
  `}
`;

/**
 * `SubTitle` styled component.
 */

const SubTitle = styled(Type.Label)`
  color: ${color('textColorLight')};
  font-weight: 400;
  margin-bottom: 51px;
  text-align: center;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  width: ${units(30)};
`;

/**
 * `RecoverPasswordSuccessModal` component.
 */

const RecoverPasswordSuccessModal = (props: Props): Node => {
  const { isVisible, onRequestClose } = props;
  const { translate } = useTranslate();

  return (
    <Modal isVisible={isVisible}>
      <Wrapper>
        <Icon
          color={color('bender2')}
          icon={checkIcon}
          size={units(13)}
        />

        <Type.H4
          color={color('bender2')}
          fontWeight={400}
          marginBottom={units(4)}
          paddingTop={units(2)}
          textAlign={'center'}
        >
          {translate('recoverPassword.success.title')}
        </Type.H4>

        <RawHtml element={SubTitle}>
          {translate('recoverPassword.success.subtitle')}
        </RawHtml>

        <StyledButton
          fullWidth
          icon={backIcon}
          onClick={onRequestClose}
        >
          {translate('recoverPassword.success.backToLoginButton')}
        </StyledButton>
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `RecoverPasswordSuccessModal` component.
 */

export default RecoverPasswordSuccessModal;
