// @flow

/**
 * Export `getAuthInitialState`.
 */

export function getAuthInitialState(token: ?string) {
  return {
    authentication: {
      status: token ? 'authenticated' : 'unauthenticated',
      token
    },
    initialize: {
      isInitialized: !token
    }
  };
}
