// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import Loading from 'client/components/core/loading';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `FeesPageContent` styled component.
 */

const FeesPageContent = styled.article`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 37px;
  margin-top: 67px;
  text-align: left;

  ${media.min('md')`
    margin-bottom: 67px;
  `}

  header > p {
    font-size: 18px;
    line-height: 28px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: ${units(5)};
    margin-top: 0;

    ~ p {
      margin-bottom: 0;
      margin-left: 30px;
      margin-top: ${units(5)};
    }
  }

  ol {
    padding-left: 0;

    p {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 30px;
      margin-left: 30px;
      margin-top: 20px;
    }
  }

  ol.main-decimal-list {
    counter-reset: main-decimal;
    list-style-type: none;
    margin-bottom: ${units(5)};
    margin-top: 63px;

    > li {
      counter-increment: main-decimal;
      font-size: 18px;
      font-weight: 500;

      &::before {
        content: counters(main-decimal, '.', decimal) '.';
        display: inline-block;
        width: 20px;
      }
    }
  }

  ol.sub-decimal-list {
    counter-reset: sub-decimal;
    list-style-type: none;
    margin-bottom: 30px;
    margin-top: 20px;

    ${media.min('sm')`
      padding-left: 31px;
    `}

    > li {
      color: ${color('textColorLight')};
      counter-increment: sub-decimal;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: ${units(1)};

      &::before {
        content: counters(sub-decimal, '.', decimal) '.';
        display: inline-block;
        width: 20px;
      }
    }
  }

  ol[type='a'] {
    counter-reset: alpha;
    list-style-type: none;
    margin-bottom: 63px;
    margin-top: ${units(5)};

    > li {
      counter-increment: alpha;
      font-size: 15px;
      font-weight: 500;

      &::before {
        content: counters(alpha, '.', lower-alpha) '.';
        display: inline-block;
        width: 20px;
      }
    }
  }

  ol[type='i'] {
    counter-reset: roman;
    list-style-type: none;
    margin-bottom: 30px;
    margin-top: 20px;

    ${media.min('sm')`
      padding-left: 31px;
    `}

    > li {
      counter-increment: roman;
      font-size: 15px;
      font-weight: 400;

      &::before {
        content: counters(roman, '.', lower-roman) '.';
        display: inline-block;
        width: 20px;
      }

      > p {
        margin-left: 0;
      }
    }
  }

  a {
    color: ${color('textColor')};
    margin-left: ${units(0.5)};
    text-decoration: none;

    &:hover, &:focus {
      color: ${color('textColorLight')};
    }
  }
`;

/**
 * `FeesContent` component.
 */

const FeesContent = (): Node => {
  const { ready, translate } = useTranslate('fees');

  if (!ready) {
    return <Loading isVisible />;
  }

  return (
    <RawHtml element={FeesPageContent}>
      {translate('contentPage', {
        email: config.get('supportEmail')
      })}
    </RawHtml>
  );
};

/**
 * Export `FeesContent` component.
 */

export default FeesContent;
