// @flow

/**
 * Module dependencies.
 */

import { Box, Type, units } from 'pmint-design-system';
import {
  EditDataWrapper,
  FieldsWrapper,
  ViewDataWrapper
} from './identity-information';

import { getKycInformation } from 'client/core/redux/selectors/kyc';
import { isUSA, useTranslatedOptions } from 'core/utils/locales';
import { taxInformationSchemaResolver } from 'client/components/kyc/tax-information-schema';
import { updateKyc } from 'client/core/redux/actions/update-kyc';
import { useDispatch, useSelector } from 'react-redux';
import DependentField from './dependent-field';
import FieldValue from 'client/components/core/field-value';
import Form from 'client/components/core/forms/form';
import KycButtons from './kyc-buttons';
import React, { type Node, useCallback, useState } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import StateField from './state-field';
import styled from 'styled-components';
import useScroller from 'client/hooks/use-scroller';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  identityConfirmed: boolean,
  isEditingFields: boolean,
  isEditingOtherFields: boolean,
  setIsEditingFields: (value: boolean) => void
|};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
  margin-top: ${units(3)};
`;

/**
 * `TaxInformation` component.
 */

const TaxInformation = (props: Props): Node => {
  const {
    identityConfirmed,
    isEditingFields,
    isEditingOtherFields,
    setIsEditingFields
  } = props;

  const { taxInformation } = useSelector(getKycInformation);
  const { country, idNumber, state } = taxInformation;
  const [countryCode, setCountryCode] = useState(country);
  const { translate } = useTranslate();
  const [ref, scroll] = useScroller();
  const countriesOptions = useTranslatedOptions('countries');
  const dispatch = useDispatch();
  const handleEditFields = useCallback(() => {
    return setIsEditingFields(true);
  }, [setIsEditingFields]);

  const handleCloseEditMode = useCallback(() => {
    return setIsEditingFields(false);
  }, [setIsEditingFields]);

  const handleSubmit = useCallback((values, { reset }) => {
    return dispatch(updateKyc({ tax: values })).then(({ value }) => {
      handleCloseEditMode();

      return reset(value.taxInformation);
    });
  }, [dispatch, handleCloseEditMode]);

  return (
    <Form
      errorsBasePath={'kyc.form.errors.fields'}
      initialValues={taxInformation}
      jsonSchema={taxInformationSchemaResolver(countryCode)}
      onSubmit={handleSubmit}
    >
      <HeaderWrapper
        // $FlowFixMe
        ref={ref}
      >
        <Type.Label
          as={'h2'}
          fontWeight={500}
        >
          {translate('kyc.taxInformation')}
        </Type.Label>

        {!identityConfirmed && (
          <KycButtons
            formValues={taxInformation}
            isEditing={isEditingFields}
            isEditingOtherFields={isEditingOtherFields}
            onCloseEditFields={handleCloseEditMode}
            onEditFields={handleEditFields}
            scroll={scroll}
          />
        )}
      </HeaderWrapper>

      <Box position={'relative'}>
        <EditDataWrapper isEditing={isEditingFields && !identityConfirmed}>
          <FieldsWrapper>
            <SelectField
              items={countriesOptions}
              label={translate('kyc.form.fields.country')}
              name={'country'}
              onChangeField={setCountryCode}
            />

            <StateField
              countryFieldName={'country'}
              label={translate('kyc.form.fields.state')}
              stateFieldName={'state'}
            />

            <DependentField
              condition={(value, previousValue) => isUSA(value) || isUSA(previousValue)}
              label={translate('kyc.form.fields.idNumber')}
              relatedFieldName={'country'}
              stateFieldName={'idNumber'}
            />
          </FieldsWrapper>
        </EditDataWrapper>

        <ViewDataWrapper isEditing={isEditingFields && !identityConfirmed}>
          <FieldsWrapper>
            <FieldValue label={translate('kyc.form.fields.country')}>
              {translate(`countries.${country}`)}
            </FieldValue>

            <FieldValue label={translate('kyc.form.fields.state')}>
              {isUSA(country) ? translate(`usaStates.${state}`) : state}
            </FieldValue>

            <FieldValue label={translate('kyc.form.fields.idNumber')}>
              {idNumber}
            </FieldValue>
          </FieldsWrapper>
        </ViewDataWrapper>
      </Box>
    </Form>
  );
};

/**
 * Export `TaxInformation` component.
 */

export default TaxInformation;
