// @flow

/**
 * Module dependencies.
 */

import { PhoneNumberInput } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node } from 'react';
import useLabel from 'client/hooks/use-label';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  label: string,
  name: string
|};

/**
 * `PhoneNumber` component.
 */

function PhoneNumber(props: Props): Node {
  const { disabled, label, name, ...rest } = props;
  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);
  const { translate } = useTranslate();
  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const inputLabel = useLabel(label, name);

  return (
    <PhoneNumberInput
      {...rest}
      disabled={isDisabled}
      getCountryName={code => translate(`countries.${code}`)}
      hasError={hasError}
      label={inputLabel}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      value={value || ''}
    />
  );
}

/**
 * Export `PhoneNumber` component.
 */

export default PhoneNumber;
