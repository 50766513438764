// @flow

/**
 * Module dependencies.
 */

import { handleError } from 'client/core/redux/utils/errors';
import { requestMiddleware } from 'core-redux/request';
import axios from 'axios';
import config from 'config';

/**
 * Export `accountRequestMiddleware`.
 */

export const accountRequestMiddleware = requestMiddleware({
  apiName: 'account',
  baseUrl: config.get('api.account.baseUrl'),
  endpoints: {
    createSSOToken: '/auth-sso/token',
    createToken: '/auth/token',
    refreshToken: '/auth/token/refresh',
    revokeToken: '/auth/token/revoke'
  },
  handleError,
  request: axios.create()
});

/**
 * Export `appRequestMiddleware`.
 */

export const appRequestMiddleware = requestMiddleware({
  apiName: 'app',
  baseUrl: config.get('api.app.baseUrl'),
  endpoints: {
    account: '/me',
    authProviders: '/auths/providers',
    changeImage: '/me/profile-picture',
    changePassword: '/me/change-password',
    checkEmail: '/users/check-if-email-exists',
    confirmEmail: '/users/confirm-email',
    deleteAccountAndIdentity: '/me?hard-delete=true',
    deleteDocument: '/documents/${id}',
    depositByFollowUpToken: '/deposits/follow-up/${followUpToken}',
    documents: '/me/documents',
    identity: '/me/identity',
    identityInformation: '/me/identity-information',
    login: '/users/login',
    presignUrl: '/documents/presign-url',
    removeAccount: '/me',
    requestAccountDeletion: '/me/request-account-deletion',
    requestPasswordReset: '/users/request-password-reset',
    resetPassword: '/users/${id}/reset-password',
    resetPasswordToken: '/users/reset-password-token',
    setPassword: '/users/set-password',
    submitIdentity: '/me/identity',
    submitKyc: '/users/submit-kyc',
    submitKycDocuments: '/me/identity',
    updateDeposit: '/deposits/follow-up/${followUpToken}',
    userInformationByFollowUp: '/users/follow-up/${followUpToken}'
  },
  handleError,
  request: axios.create()
});

/**
 * Export `schedulerRequestMiddleware`.
 */

export const schedulerRequestMiddleware = requestMiddleware({
  apiName: 'scheduler',
  baseUrl: config.get('api.scheduler.baseUrl'),
  endpoints: {
    createJob: '/jobs'
  },
  handleError,
  request: axios.create()
});
