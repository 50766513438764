// @flow

/**
 * Module dependencies.
 */

import { getSignInState } from 'client/core/redux/selectors/sign-in';
import { media } from 'pmint-design-system';
import { performSignIn } from 'client/core/redux/actions/perform-sign-in';
import { resetSignIn } from 'client/core/redux/actions/sign-in';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgotPassword from 'client/components/core/forgot-password';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import PasswordField from 'client/components/core/forms/fields/password';
import React, { type Node, useCallback, useEffect } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import routes from 'core/routes';
import signInSchema from 'client/components/sign-in/sign-in-schema';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  hideEmail?: boolean,
  initialValues?: {|
    email: ?string,
    password: ?string
  |}
|};

/**
 * `StyledForgotPassword` styled component.
 */

const StyledForgotPassword = styled(ForgotPassword)`
  margin: 18px 0 38px;
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  flex: 1 1 50%;

  ${media.min('xs')`
    flex-grow: 0;
  `}
`;

/**
 * `SignInForm` component.
 */

const SignInForm = (props: Props): Node => {
  const {
    hideEmail = false,
    initialValues = {
      email: undefined,
      password: undefined
    }
  } = props;

  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors } = useSelector(getSignInState);
  const handleSubmit = useCallback(values => {
    return dispatch(performSignIn(values)).then(() => {
      return history.push(routes.profile.base);
    });
  }, [dispatch, history]);

  useEffect(() => {
    return () => dispatch(resetSignIn());
  }, [dispatch]);

  useNetworkErrorMessage('signIn.errors', errors);

  return (
    <Form
      errorsBasePath={'signIn.errors.form'}
      initialValues={initialValues}
      jsonSchema={signInSchema}
      onSubmit={handleSubmit}
    >
      {!hideEmail && (
        <InputField
          label={translate('signIn.form.fields.email')}
          name={'email'}
          type={'email'}
        />
      )}

      <PasswordField
        label={translate('signIn.form.fields.password')}
        name={'password'}
      />

      <StyledForgotPassword />

      <SubmitButtonWrapper>
        <StyledSubmitButton fullWidth>
          {translate('common.actions.next')}
        </StyledSubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
};

/**
 * Export `SignInForm` component.
 */

export default SignInForm;
