// @flow

/**
 * Module dependencies.
 */

import {
  Button,
  Icon,
  Modal,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled from 'styled-components';
import successIcon from 'client/assets/svg/success-64px.svg';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(5)} ${units(4)};
  text-align: center;

  ${media.min('xs')`
    padding: ${units(10)} ${units(2.5)};
  `}

  ${media.min('lg')`
    padding: ${units(10)} 69px;
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  margin: 0 auto;
  width: 100%;

  ${media.min('md')`
    width: ${units(30)};
  `}
`;

/**
 * `UploadDocumentsModalSuccess` component.
 */

const UploadDocumentsModalSuccess = (props: Props): Node => {
  const { isVisible, onRequestClose } = props;
  const { translate } = useTranslate();

  return (
    <Modal isVisible={isVisible}>
      <Wrapper>
        <Icon
          color={color('bender2')}
          icon={successIcon}
          size={'89px'}
        />

        <Type.H5
          as={'h2'}
          color={color('bender2')}
          fontWeight={400}
          marginBottom={'30px'}
          paddingTop={units(4)}
        >
          {translate('kyc.documents.success.title')}
        </Type.H5>

        <RawHtml
          as={'p'}
          color={color('textColorLight')}
          element={Type.Label}
          fontWeight={400}
          marginBottom={'45px'}
        >
          {translate('kyc.documents.success.description')}
        </RawHtml>

        <ButtonWrapper>
          <Button
            fullWidth
            icon={arrowIcon}
            onClick={onRequestClose}
          >
            {translate('common.actions.next')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `UploadDocumentsModalSuccess` component.
 */

export default UploadDocumentsModalSuccess;
