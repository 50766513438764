// @flow

/**
 * Module dependencies.
 */

import { Icon, Link, color, units } from 'pmint-design-system';
import React, { type Node } from 'react';
import arrowBackIcon from 'client/assets/svg/arrow-back-24px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  to: string
|};

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  background-color: ${color('white')};
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(105, 122, 137, 0.05), 0 3px 8px 0 rgba(105, 122, 137, 0.1), 0 0 1px 0 rgba(105, 122, 137, 0.5);
  height: ${units(2.5)};
  margin-right: ${units(1.75)};
  top: 5px;
  width: ${units(2.5)};
`;

/**
 * `BackLink` component.
 */

const BackLink = ({ to, ...rest }: Props): Node => {
  const { translate } = useTranslate();

  return (
    <StyledLink
      {...rest}
      colorTheme={'secondary'}
      size={'small'}
      to={to}
    >
      <StyledIcon icon={arrowBackIcon} />

      {translate('common.actions.back')}
    </StyledLink>
  );
};

/**
 * Export `BackLink` component.
 */

export default BackLink;
