// @flow

/**
 * Module dependencies.
 */

import type {
  KycHomeAddressInformation,
  KycIdentityInformation,
  KycTaxInformation
} from 'client/core/types/kyc';

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { normalizeKycInformation } from 'client/core/utils/normalizer';
import { pick } from 'lodash';
import { updateKycType } from 'client/core/redux/action-types/update-kyc';

/**
 * `Values` type.
 */

type Values = KycIdentityInformation | {
  address?: KycHomeAddressInformation,
  phone?: {
    number: string,
    sms: boolean
  },
  tax?: KycTaxInformation
};

/**
 * Export `updateKyc`.
 */

export const updateKyc = (values: Values) => {
  const pickedValues = pick(values, [
    'address',
    'birthday',
    'gender',
    'name',
    'phone',
    'tax'
  ]);

  return createRequestAction({
    apiName: 'app',
    data: pickedValues,
    endpoint: 'identity',
    handleResponse: ({ data }) => {
      return normalizeKycInformation(data);
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: updateKycType
  });
};

/**
 * Export `resetUpdateKyc`.
 */

export const resetUpdateKyc = createResetRequestAction({
  method: 'PATCH',
  type: updateKycType
});
