// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import { ifProp } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  className?: string,
  label: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin-bottom: ${units(2)};
  overflow: hidden;
  position: relative;
  width: 100%;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Type.Label).attrs({ as: 'dt' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  left: ${units(2)};
  letter-spacing: normal;
  position: absolute;
  top: 20px;
  transform-origin: left center;

  ${ifProp('hasValue', css`
    transform: translateY(-50%) scale(0.61);
  `)}
`;

/**
 * `ValueWrapper` styled component.
 */

const ValueWrapper = styled.dd`
  border: 1px solid ${color('gray300')};
  border-radius: ${units(1)};
  display: flex;
  margin: 0;
  min-height: ${units(8)};
  padding: 27px 15px ${units(1)};
`;

/**
 * `Value` styled component.
 */

const Value = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('gray500')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * `FieldValue` component.
 */

const FieldValue = (props: Props): Node => {
  const { children, label, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <Label hasValue={Boolean(children)}>
        {label}
      </Label>

      <ValueWrapper>
        <Value>
          {children}
        </Value>
      </ValueWrapper>
    </Wrapper>
  );
};

/**
 * Export `FieldValue` component.
 */

export default FieldValue;
