// @flow

/**
 * Module dependencies.
 */

import { Checkbox as PmintCheckbox } from 'pmint-design-system';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  label: string | Node,
  name: string
|};

/**
 * `Checkbox` component.
 */

const Checkbox = (props: Props): Node => {
  const { className, disabled, label, name } = props;
  const { error, meta, onChange, value, ...fieldProps } = useField(name);
  const { isSubmitting } = useFormState();
  const { touched } = meta || {};
  const checkboxLabel = useLabel(label, name);
  const hasError = !!error && touched;
  const handleChange = useCallback(({ target }) => {
    onChange(target.checked);
  }, [onChange]);

  const isDisabled = disabled || isSubmitting;

  return (
    <PmintCheckbox
      checked={value}
      className={className}
      disabled={isDisabled}
      hasError={hasError}
      label={checkboxLabel}
      name={name}
      onChange={handleChange}
      {...fieldProps}
    />
  );
};

/**
 * Export `Checkbox` component.
 */

export default Checkbox;
