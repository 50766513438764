// @flow

/**
 * Module dependencies.
 */
import { keywords, schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  identity: {
    birthday,
    gender,
    name
  }
} = schemaValidations;

/**
 * Export `identityInformationSchema`.
 */

export const identityInformationSchema = {
  additionalProperties: false,
  properties: {
    birthday,
    gender,
    name
  },
  required: ['birthday', 'name', 'gender']
};

/**
 * Keywords.
 */

const { isPastDate, isValidAge } = keywords;

/**
 * Export `validationOptions`.
 */

export const validationOptions = {
  keywords: { isPastDate, isValidAge }
};
