// @flow

/**
 * Module dependencies.
 */

import { Box, Fill, Type, media, units } from 'pmint-design-system';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { getEditedFormData } from 'client/core/utils/form';
import { getKycInformation } from 'client/core/redux/selectors/kyc';
import {
  identityInformationSchema,
  validationOptions
} from 'client/components/kyc/identity-information-schema';

import { ifProp, theme } from 'styled-tools';
import { toLower } from 'lodash';
import { updateKyc } from 'client/core/redux/actions/update-kyc';
import { useDispatch, useSelector } from 'react-redux';
import DateField from 'client/components/core/forms/fields/date-input';
import FieldValue from 'client/components/core/field-value';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import KycButtons from './kyc-buttons';
import React, { type Node, useCallback } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import styled, { css } from 'styled-components';
import useScroller from 'client/hooks/use-scroller';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  identityConfirmed: boolean,
  isEditingFields: boolean,
  isEditingOtherFields: boolean,
  setIsEditingFields: (value: boolean) => void
|};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
`;

/**
 * Export `EditDataWrapper` styled component.
 */

export const EditDataWrapper = styled(Fill)`
  opacity: 0;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: opacity, visibility;
  visibility: hidden;

  ${ifProp('isEditing', css`
    opacity: 1;
    visibility: visible;
  `)}
`;

/**
 * Export `ViewDataWrapper` styled component.
 */

export const ViewDataWrapper = styled.div`
  opacity: 1;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: opacity, visibility;
  visibility: visible;

  ${ifProp('isEditing', css`
    opacity: 0;
    visibility: hidden;
  `)}
`;

/**
 * Export `FieldsWrapper` styled component.
 */

export const FieldsWrapper = styled.div`
  display: grid;

  ${media.min('sm')`
    grid-column-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * `IdentityInformation` component.
 */

const IdentityInformation = (props: Props): Node => {
  const {
    identityConfirmed,
    isEditingFields,
    isEditingOtherFields,
    setIsEditingFields
  } = props;

  const { identityInformation } = useSelector(getKycInformation);
  const { birthday, gender, name } = identityInformation;
  const { translate } = useTranslate();
  const [ref, scroll] = useScroller();
  const dispatch = useDispatch();
  const genderOptions = [{
    label: translate('gender.female'),
    value: 'Female'
  }, {
    label: translate('gender.male'),
    value: 'Male'
  }, {
    label: translate('gender.undefined'),
    value: 'Undefined'
  }];

  const handleEditFields = useCallback(() => {
    return setIsEditingFields(true);
  }, [setIsEditingFields]);

  const handleCloseEditMode = useCallback(() => {
    return setIsEditingFields(false);
  }, [setIsEditingFields]);

  const handleSubmit = useCallback((values, { reset }) => {
    const editedData = getEditedFormData(identityInformation, values);

    return dispatch(updateKyc(editedData)).then(({ value }) => {
      if (editedData.name) {
        dispatch(fetchAccount());
      }

      handleCloseEditMode();
      reset(value.identityInformation);
    });
  }, [dispatch, identityInformation, handleCloseEditMode]);

  return (
    <Form
      errorsBasePath={'kyc.form.errors.fields'}
      initialValues={identityInformation}
      jsonSchema={identityInformationSchema}
      onSubmit={handleSubmit}
      validationOptions={validationOptions}
    >
      <HeaderWrapper
        // $FlowFixMe
        ref={ref}
      >
        <Type.Label
          as={'h2'}
          fontWeight={500}
        >
          {translate('kyc.identityInformation')}
        </Type.Label>

        {!identityConfirmed && (
          <KycButtons
            formValues={identityInformation}
            isEditing={isEditingFields}
            isEditingOtherFields={isEditingOtherFields}
            onCloseEditFields={handleCloseEditMode}
            onEditFields={handleEditFields}
            scroll={scroll}
          />
        )}
      </HeaderWrapper>

      <Box position={'relative'}>
        <EditDataWrapper isEditing={isEditingFields && !identityConfirmed}>
          <InputField
            label={translate('kyc.form.fields.name')}
            name={'name'}
          />

          <FieldsWrapper>
            <DateField
              label={translate('kyc.form.fields.birthday')}
              name={'birthday'}
            />

            <SelectField
              items={genderOptions}
              label={translate('kyc.form.fields.gender')}
              name={'gender'}
            />
          </FieldsWrapper>
        </EditDataWrapper>

        <ViewDataWrapper isEditing={isEditingFields && !identityConfirmed}>
          <FieldValue label={translate('kyc.form.fields.name')}>
            {name}
          </FieldValue>

          <FieldsWrapper>
            <FieldValue label={translate('kyc.form.fields.birthday')}>
              {birthday}
            </FieldValue>

            <FieldValue label={translate('kyc.form.fields.gender')}>
              {translate(`gender.${toLower(gender)}`)}
            </FieldValue>
          </FieldsWrapper>
        </ViewDataWrapper>
      </Box>
    </Form>
  );
};

/**
 * Export `IdentityInformation` component.
 */

export default IdentityInformation;
