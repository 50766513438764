// @flow

/**
 * Module dependencies.
 */

import type { DocumentType } from 'client/core/types/kyc-documents';
import { Icon } from 'pmint-design-system';
import React, { type Node } from 'react';
import driverLicenseIcon from 'client/assets/svg/document-types/driver-license-32px.svg';
import identityCardIcon from 'client/assets/svg/document-types/identity-card-32px.svg';
import otherIcon from 'client/assets/svg/document-types/other-32px.svg';
import passportIcon from 'client/assets/svg/document-types/passport-32px.svg';
import residencePermitIcon from 'client/assets/svg/document-types/residence-permit-32px.svg';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  color?: string | Function,
  size?: string | Function,
  type: DocumentType
|};

/**
 * Icons.
 */

const icons: { [key: DocumentType]: string } = {
  driverLicense: driverLicenseIcon,
  identityCard: identityCardIcon,
  other: otherIcon,
  passport: passportIcon,
  residencePermit: residencePermitIcon
};

/**
 * `DocumentIcon` component.
 */

const DocumentIcon = ({ type, ...rest }: Props): Node => (
  <Icon
    aria-hidden
    icon={icons[type] ?? icons.other}
    {...rest}
  />
);

/**
 * Export `DocumentIcon` component.
 */

export default DocumentIcon;
