// @flow

/**
 * Module dependencies.
 */

import {
  Box,
  Button,
  Fill,
  Icon,
  Type,
  color,
  states,
  units
} from 'pmint-design-system';

import { ifProp, theme } from 'styled-tools';
import { isEmpty, omit } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect } from 'react';
import accept from 'attr-accept';
import dragAndDropIcon from 'client/assets/svg/drag-n-drop-32px.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  id: string,
  label: string,
  maxFileSize: string,
  name: string,
  onFile: File => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  border: 1px dashed ${color('gray400')};
  border-radius: 4px;
  padding: ${units(3)} ${units(2)} ${units(4)};
  text-align: center;
  transition: background-color ${theme('transitions.defaultTransition')};

  ${ifProp('isDragActive', css`
    background-color: ${color('gray200')};
  `)}

  ${ifProp('hasError', css`
    border-color: ${color('errorDark')};
  `)}
`;

/**
 * `Input` styled component.
 */

const Input = styled(Fill).attrs({ as: 'input' })`
  cursor: pointer;
  opacity: 0;
  width: 100%;

  ${states.interaction`
    & + ${Content} {
      background-color: ${color('gray200')};
    }
  `}
`;

/**
 * `BrowseButton` styled component.
 */

const BrowseButton = styled(Button)`
  position: relative;
`;

/**
 * `FilePicker` component.
 */

function FilePicker(props: Props): Node {
  const { id, label, maxFileSize, name, onFile } = props;
  const { showErrorMessage } = useSnackbar();
  const { translate } = useTranslate();
  const descriptionId = `${id}Description`;
  const handleDrop = useCallback(files => {
    if (isEmpty(files)) {
      return;
    }

    onFile(files[0]);
  }, [onFile]);

  const {
    getInputProps,
    getRootProps,
    isDragActive,
    rejectedFiles
  } = useDropzone({
    accept: ['image/*', 'application/pdf'],
    maxSize: maxFileSize,
    multiple: false,
    onDrop: handleDrop
  });

  const hasError = !isEmpty(rejectedFiles);

  useEffect(() => {
    if (!hasError) {
      return;
    }

    const [rejectedFile] = rejectedFiles;

    if (rejectedFile.size > maxFileSize) {
      showErrorMessage(translate('kyc.documents.fileUpload.dropzoneErrors.maxFileSize'));

      return;
    }

    if (!accept(rejectedFiles, ['image/*', 'application/pdf'])) {
      showErrorMessage(translate('kyc.documents.fileUpload.dropzoneErrors.fileType'));

      return;
    }

    showErrorMessage(translate('kyc.documents.fileUpload.dropzoneErrors.defaultError'));
  }, [hasError, maxFileSize, rejectedFiles, showErrorMessage, translate]);

  return (
    <Wrapper {...omit(getRootProps(), ['tabIndex'])}>
      <Input
        {...omit(getInputProps(), ['style', 'tabIndex'])}
        aria-describedby={descriptionId}
        id={id}
        name={name}
      />

      <Content
        hasError={hasError}
        isDragActive={isDragActive}
      >
        <Type.Paragraph
          as={'label'}
          display={'block'}
          fontWeight={400}
          htmlFor={id}
          marginBottom={units(2)}
        >
          {label}
        </Type.Paragraph>

        <Icon
          color={color('bender2')}
          icon={dragAndDropIcon}
          size={units(4)}
        />

        <Type.Small
          as={'p'}
          id={descriptionId}
          paddingTop={units(1.5)}
        >
          <RawHtml
            as={'span'}
            display={'block'}
            element={Box}
            marginBottom={units(1)}
          >
            {translate('kyc.documents.fileUpload.description')}
          </RawHtml>

          <BrowseButton
            colorTheme={'secondary'}
            tabIndex={-1}
            type={'button'}
            variant={'outlined'}
          >
            {translate('kyc.documents.fileUpload.buttonLabel')}
          </BrowseButton>
        </Type.Small>
      </Content>
    </Wrapper>
  );
}

/**
 * Export `FilePicker` component.
 */

export default FilePicker;
