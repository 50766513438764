// @flow

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { ifProp, prop } from 'styled-tools';
import { isNil, round, truncate } from 'lodash';
import IconButton from 'client/components/core/icon-button';
import React, { type Node } from 'react';
import removeIcon from 'pmint-design-system/icons/close-24px.svg';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  errors: Object,
  file: File,
  isSubmitting: boolean,
  onCancel: () => void,
  progress: number
|};

/**
 * Progress style.
 */

const progressStyle = css`
  bottom: 0;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  background-color: ${color('gray200')};
  border-radius: 4px;
  color: ${color('textColor')};
  display: grid;
  grid-gap: ${units(1)};
  grid-template-areas:
    "name action"
    "info info";
  grid-template-columns: 1fr min-content;
  max-width: 100%;
  overflow: hidden;
  padding: ${units(2)};
  position: relative;

  &::after,
  &::before {
    ${progressStyle}

    content: '';
  }

  &::before {
    background-color: ${color('gray300')};
  }

  &::after {
    background-color: ${color('bender2')};
    transform: scaleX(${prop('progress', 0)});
    transform-origin: left center;
  }

  ${ifProp('hasError', css`
    &::after {
      background-color: ${color('errorDark')};
      transform: scaleX(1);
    }
  `)}

  ${media.min('xs')`
    grid-template-areas: "name info action";
    grid-template-columns: 2fr 1fr min-content;
  `}
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  grid-area: action;
  justify-self: end;
`;

/**
 * `AccessibleProgress` styled component.
 */

const AccessibleProgress = styled.progress`
  ${progressStyle}

  appearance: none;
  opacity: 0;
`;

/**
 * Truncate file name.
 */

function truncateFileName(fileName) {
  const maxSize = 18;

  if (fileName.length < maxSize) {
    return fileName;
  }

  const match = fileName.match(/\.[^.]{1,4}$/);

  if (!match) {
    return truncate(fileName, {
      length: maxSize,
      omission: '...'
    });
  }

  const [extension] = match;

  return truncate(fileName, {
    length: maxSize,
    omission: `[...]${extension}`
  });
}

/**
 * `DocumentUploader` component.
 */

function DocumentUploader(props: Props): Node {
  const { errors, file, isSubmitting, onCancel, progress } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper
      hasError={!isNil(errors)}
      progress={round(progress, 8)}
    >
      <Type.Paragraph
        fontWeight={400}
        gridArea={'name'}
        whiteSpace={'nowrap'}
      >
        {truncateFileName(file.name)}
      </Type.Paragraph>

      <Type.Small
        as={'p'}
        fontWeight={400}
        gridArea={'info'}
        whiteSpace={'nowrap'}
      >
        {translate('kyc.documents.fileUpload.fileSize', { size: file.size })}
      </Type.Small>

      <StyledIconButton
        aria-label={translate('common.actions.remove')}
        disabled={isSubmitting}
        icon={removeIcon}
        onClick={onCancel}
      />

      <AccessibleProgress value={progress} />
    </Wrapper>
  );
}

/**
 * Export `DocumentUploader` component.
 */

export default DocumentUploader;
