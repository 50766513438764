// @flow

/**
 * Module dependencies.
 */

import { Link, Type, color, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import React, { type Node } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  className?: string
|};

/**
 * `ForgotPasswordLabel` styled component.
 */

const ForgotPasswordLabel = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  margin-right: ${units(0.5)};
  text-align: left;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 500;
  text-align: left;
  text-decoration: underline;
`;

/**
 * `ForgotPassword` component.
 */

const ForgotPassword = (props: Props): Node => {
  return (
    <ForgotPasswordLabel {...props}>
      <Trans
        components={[
          <StyledLink
            colorTheme={'secondary'}
            key={'recover'}
            size={'small'}
            to={routes.recoverPassword}
          />
        ]}
        i18nKey={'forgotPassword.label'}
      />
    </ForgotPasswordLabel>
  );
};

/**
 * Export `ForgotPassword` component.
 */

export default ForgotPassword;
