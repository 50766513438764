// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { recoverPasswordType } from 'client/core/redux/action-types/recover-password';

/**
 * Export `recoverPassword`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: recoverPasswordType
}));
