// @flow

/**
 * Module dependencies.
 */

import { schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  user: {
    password
  }
} = schemaValidations;

/**
 * Export `setPasswordSchema`.
 */

export default {
  additionalProperties: false,
  properties: {
    confirmPassword: {
      const: {
        $data: '1/password'
      },
      type: 'string'
    },
    password
  },
  required: ['confirmPassword', 'password'],
  type: 'object'
};
