// @flow

/**
 * Module dependencies.
 */

import { media } from 'pmint-design-system';
import FeesContent from 'client/components/fees/fees-content';
import Header from 'client/components/core/header';
import PageLayout from 'client/components/page-layout';
import React, { type Node } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import feesIcon from 'client/assets/svg/fees.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content auto;
  min-height: 100%;

  ${media.min('md')`
    grid-template-columns: repeat(8, 1fr);
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2 / -2;
  position: relative;
`;

/**
 * `Fees` container.
 */

const Fees = (): Node => {
  const { translate } = useTranslate();

  return (
    <PageLayout
      sidebarContent={(
        <SidebarContent
          subtitle={translate('fees.sidebar.content')}
          title={translate('fees.sidebar.title')}
        />
      )}
    >
      <ContentWrapper>
        <Header
          icon={feesIcon}
          title={translate('fees.header.title')}
        />

        <Content>
          <FeesContent />
        </Content>
      </ContentWrapper>
    </PageLayout>
  );
};

/**
 * Export `Fees` container.
 */

export default Fees;
