// @flow

/**
 * Module dependencies.
 */

import { Button } from 'pmint-design-system';
import { Col, Container, Row } from 'react-components/layout';
import type { ErrorProps } from './error-boundary';
import { Type } from 'react-components/typography';
import { isProduction } from 'core/utils/environment';
import { units } from 'react-components/styles';
import React, { type Node } from 'react';
import sentry from 'core/clients/sentry-browser-client';
import styled from 'styled-components';

/**
 * `Details` styled component.
 */

const Details = styled.details`
  white-space: pre-wrap;

  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `DefaultErrorMessage` component.
 */

const DefaultErrorMessage = ({ error, errorInfo, sentryEventId }: ErrorProps): Node => (
  <Container>
    <Row>
      <Col>
        <Type.H1 paddingTop={units(4)}>
          {'Something bad happened'}
        </Type.H1>

        {!isProduction() && (
          <>
            <Type.Paragraph>
              {error && error.toString()}
            </Type.Paragraph>

            <Details>
              <summary>
                {'Stack trace'}
              </summary>

              {error && error.stack}
            </Details>

            <Details>
              <summary>
                {'Component stack'}
              </summary>

              {errorInfo && errorInfo.componentStack}
            </Details>
          </>
        )}

        {sentry.isReportDialogToShow() && (
          <Button onClick={() => sentry.showReportDialog({ sentryEventId })}>
            {'Report feedback'}
          </Button>
        )}
      </Col>
    </Row>
  </Container>
);

/**
 * Export `DefaultErrorMessage` component.
 */

export default DefaultErrorMessage;
