// @flow

/**
 * Module dependencies.
 */

import { Password as PmintPassword } from 'pmint-design-system';
import { getFieldValue } from 'client/core/utils/form';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import dontSeeIcon from 'pmint-design-system/icons/dont-see-28px.svg';
import seeIcon from 'pmint-design-system/icons/see-28px.svg';
import useLabel from 'client/hooks/use-label';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  autoFocus?: boolean,
  disabled?: boolean,
  label: string,
  name: string
|};

/**
 * `Password` component.
 */

const Password = (props: Props): Node => {
  const { disabled, label, name, ...rest } = props;
  const {
    error,
    meta,
    onBlur,
    onChange,
    onFocus,
    value
  } = useField(name);

  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const { translate } = useTranslate();
  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const handleBlur = useCallback(event => {
    if (onBlur) {
      onBlur(event);
      onChange(getFieldValue(value));
    }
  }, [onBlur, onChange, value]);

  const handleChange = useCallback(({ target }) => {
    onChange(target.value);
  }, [onChange]);

  const passwordLabel = useLabel(label, name);

  return (
    <PmintPassword
      {...rest}
      disabled={isDisabled}
      dontSeeIcon={dontSeeIcon}
      hasError={hasError}
      hideAriaLabel={translate('common.password.hideLabel')}
      label={passwordLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      seeIcon={seeIcon}
      showAriaLabel={translate('common.password.showLabel')}
      value={value}
    />
  );
};

/**
 * Export `Password` component.
 */

export default Password;
