// @flow

/**
 * Module dependencies.
 */

import type { KycDocument } from 'client/core/types/kyc-documents';
import { Type, color, units } from 'pmint-design-system';
import Document from './document';
import React, { type Node } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  documents: Array<KycDocument>,
  onDeleteDocument: (id: string) => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('gray100')};
  border-radius: ${units(1)};
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  background-color: ${color('gray200')};
  border-radius: ${units(1)};
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  min-height: ${units(8)};
  padding: ${units(2)} ${units(3)};
  text-align: left;
  width: 100%;
`;

/**
 * `FileList` styled component.
 */

const FileList = styled.ul`
  padding: ${units(3)};
`;

/**
 * `FileListItem` styled component.
 */

const FileListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }
`;

/**
 * `DocumentsList` component.
 */

function DocumentsList({ documents, onDeleteDocument }: Props): Node {
  const { translate } = useTranslate();
  const documentsType = documents[0].type;

  return (
    <Wrapper>
      <Header>
        <Type.Paragraph
          as={'h3'}
          fontWeight={500}
        >
          {translate(`kyc.documents.types.${documentsType}`)}
        </Type.Paragraph>
      </Header>

      <FileList>
        {documents.map(document => (
          <FileListItem key={document.id}>
            <Document
              document={document}
              onDeleteDocument={onDeleteDocument}
            />
          </FileListItem>
        ))}
      </FileList>
    </Wrapper>
  );
}

/**
 * Export `DocumentsList` component.
 */

export default DocumentsList;
