// @flow

/**
 * Module dependencies.
 */

import { Box, Type, units } from 'pmint-design-system';
import {
  EditDataWrapper,
  FieldsWrapper,
  ViewDataWrapper
} from './identity-information';

import {
  contactInformationSchema,
  validationOptions
} from 'client/components/kyc/contact-information-schema';

import { getKycInformation } from 'client/core/redux/selectors/kyc';
import { updateKyc } from 'client/core/redux/actions/update-kyc';
import { useDispatch, useSelector } from 'react-redux';
import FieldValue from 'client/components/core/field-value';
import Form from 'client/components/core/forms/form';
import KycButtons from './kyc-buttons';
import KycPhone from './kyc-phone';
import PhoneNumber from 'client/components/core/forms/fields/phone-number';
import React, { type Node, useCallback } from 'react';
import styled from 'styled-components';
import useScroller from 'client/hooks/use-scroller';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isEditingFields: boolean,
  isEditingOtherFields: boolean,
  setIsEditingFields: (value: boolean) => void
|};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 29px;
  margin-top: ${units(3)};
`;

/**
 * `ContactInformation` component.
 */

const ContactInformation = (props: Props): Node => {
  const { isEditingFields, isEditingOtherFields, setIsEditingFields } = props;
  const { contactInformation } = useSelector(getKycInformation);
  const { email, phone } = contactInformation;
  const { translate } = useTranslate();
  const [ref, scroll] = useScroller();
  const dispatch = useDispatch();
  const handleEditFields = useCallback(() => {
    return setIsEditingFields(true);
  }, [setIsEditingFields]);

  const handleCloseEditMode = useCallback(() => {
    return setIsEditingFields(false);
  }, [setIsEditingFields]);

  const handleSubmit = useCallback((values, { reset }) => {
    const phone = {
      number: values.phone,
      sms: false
    };

    return dispatch(updateKyc({ phone })).then(({ value }) => {
      handleCloseEditMode();

      return reset(value.contactInformation);
    });
  }, [dispatch, handleCloseEditMode]);

  return (
    <Form
      errorsBasePath={'kyc.form.errors.fields'}
      initialValues={contactInformation}
      jsonSchema={contactInformationSchema}
      onSubmit={handleSubmit}
      validationOptions={validationOptions}
    >
      <HeaderWrapper
        // $FlowFixMe
        ref={ref}
      >
        <Type.Label
          as={'h2'}
          fontWeight={500}
        >
          {translate('kyc.contactInformation')}
        </Type.Label>

        <KycButtons
          formValues={contactInformation}
          isEditing={isEditingFields}
          isEditingOtherFields={isEditingOtherFields}
          onCloseEditFields={handleCloseEditMode}
          onEditFields={handleEditFields}
          scroll={scroll}
        />
      </HeaderWrapper>

      <FieldsWrapper>
        <Box position={'relative'}>
          <EditDataWrapper isEditing={isEditingFields}>
            <PhoneNumber
              label={translate('kyc.form.fields.phone')}
              name={'phone'}
            />
          </EditDataWrapper>

          <ViewDataWrapper isEditing={isEditingFields}>
            <FieldValue label={translate('kyc.form.fields.phone')}>
              <KycPhone phoneNumber={phone} />
            </FieldValue>
          </ViewDataWrapper>
        </Box>

        <FieldValue label={translate('kyc.form.fields.email')}>
          {email}
        </FieldValue>
      </FieldsWrapper>
    </Form>
  );
};

/**
 * Export `ContactInformation` component.
 */

export default ContactInformation;
