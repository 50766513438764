// @flow

/**
 * Module dependencies.
 */

import { get, isEmpty, isEqual, reduce, trim } from 'lodash';

/**
 * Export `getEditedFormData`.
 */

export function getEditedFormData(initialValues: Object, updatedValues: Object): Object {
  if (isEmpty(updatedValues)) {
    return {};
  }

  return reduce(updatedValues, (acc: Object, updatedValue: any, key: string) => {
    const initialValue = get(initialValues, key);

    if (!isEqual(updatedValue, initialValue)) {
      return {
        ...acc,
        [key]: updatedValue
      };
    }

    return acc;
  }, {});
}

/**
 * Export `getFieldValue`.
 */

export const getFieldValue = (fieldValue: string) => {
  const trimmedValue = trim(fieldValue);

  if (trimmedValue.length === 0) {
    return undefined;
  }

  return trimmedValue;
};
