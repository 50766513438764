// @flow

/**
 * Module dependencies.
 */

import { Icon, Link, Type, color, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import { theme } from 'styled-tools';
import AppleAppStoreBadge from 'client/assets/svg/app-store.svg';
import BackLink from 'client/components/core/back-link';
import GooglePlayStoreBadge from 'client/assets/svg/google-play.svg';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import config from 'config';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * App and Google Play stores configs.
 */

const { appStore, googlePlayStore } = config.get('mobileApplications') ?? {};

/**
 * Mobile kyc faq config.
 */

const { mobileKycFaq } = config.get('externalLinks');

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content min-content auto;
  height: 100%;
`;

/**
 * `HeaderLine` styled component.
 */

const HeaderLine = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin: ${units(3)};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-self: center;
  grid-column: 2;
  justify-self: center;
  margin-bottom: ${units(6)};
  max-width: ${units(60)};
  position: relative;
`;

/**
 * `Heading` styled component.
 */

const Heading = styled(Type.H4).attrs({ as: 'h1' })`
  color: ${color('bender2')};
  margin-bottom: ${units(4)};
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('gray200')};
  height: 2px;
  margin-bottom: ${units(3.5)};
  margin-top: ${units(6)};
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  color: ${color('textColorLight')};
  display: block;
  font-weight: 300;
  margin-bottom: ${units(4)};
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

/**
 * `StyledTrans` styled component.
 */

const StyledTrans = styled(Trans)`
  color: ${color('textColorLight')};
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${units(2)};
`;

/**
 * `StoreButton` styled component.
 */

const StoreButton = styled(Icon)`
  height: ${units(9)};
  max-width: unset;
  overflow: hidden;
  width: 100%;
`;

/**
 * `StoreLink` styled component.
 */

const StoreLink = styled(Link).attrs({
  rel: 'noopener',
  target: '_blank'
})`
  cursor: pointer;
  flex: 1;
`;

/**
 * `StyledParagraph` styled component.
 */

const StyledParagraph = styled(Type.Paragraph)`
  color: ${color('textColorLight')};
`;

/**
 * Motivation components.
 */

const motivationComponents = [
  <StyledParagraph
    as={'b'}
    display={'inline-block'}
    fontWeight={500}
    key={'p'}
  />,
  <StyledLink
    colorTheme={'default'}
    href={mobileKycFaq}
    key={'link'}
    rel={'noopener'}
    target={'_blank'}
  />
];

/**
 * `MobileKycRedirect` container.
 */

const MobileKycRedirect = (): Node => {
  const { translate } = useTranslate();

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper>
        <HeaderLine />

        <BackLinkWrapper>
          <BackLink to={routes.home} />
        </BackLinkWrapper>

        <ContentWrapper>
          <Heading>
            {translate('mobileKycRedirect.title')}
          </Heading>

          <StyledRawHtml>
            {translate('mobileKycRedirect.intro')}
          </StyledRawHtml>

          <StyledTrans
            components={motivationComponents}
            i18nKey={'mobileKycRedirect.motivation'}
          />

          <Line />

          <StyledParagraph
            fontWeight={500}
            marginBottom={units(1)}
          >
            {translate('mobileKycRedirect.missingMobileApp')}
          </StyledParagraph>

          <StyledParagraph
            fontWeight={300}
            marginBottom={units(3.5)}
          >
            {translate('mobileKycRedirect.installNow')}
          </StyledParagraph>

          <ButtonsWrapper>
            <StoreLink href={appStore}>
              <StoreButton icon={AppleAppStoreBadge} />
            </StoreLink>

            <StoreLink href={googlePlayStore}>
              <StoreButton icon={GooglePlayStoreBadge} />
            </StoreLink>
          </ButtonsWrapper>
        </ContentWrapper>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `MobileKycRedirect` container.
 */

export default MobileKycRedirect;
