// @flow

/**
 * Module dependencies.
 */

import { useTranslation } from 'react-i18next';

/**
 * `TranslateProps` type.
 */

type TranslateProps = {
  i18n: Object,
  ready: boolean,
  translate: (key: Array<string> | string, interpolation?: Object) => string
};

/**
 * Use translate.
 */

function useTranslate(namespaces?: Array<string> | string, options?: Object): TranslateProps {
  const {
    t, // eslint-disable-line id-length
    ...rest
  } = useTranslation(namespaces, {
    useSuspense: false,
    ...options
  });

  return {
    ...rest,
    translate: t
  };
}

/**
 * Export `useTranslate`.
 */

export default useTranslate;
