// @flow

/**
 * Module dependencies.
 */

import { Icon, Link, Type, color, units } from 'pmint-design-system';
import React, { type Node } from 'react';
import UserAccount from 'client/components/core/user/user-account';
import logoutIcon from 'client/assets/svg/logout-24px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  icon: string,
  onLogout?: () => void,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  grid-column: 1 / -1;
  padding-bottom: ${units(1)};
  position: sticky;
  top: 0;
  z-index: 1;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  border-bottom: 1px solid ${color('gray300')};
  display: flex;
  justify-content: space-between;
  padding: ${units(2.5)} ${units(3)};
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 400;
  margin-left: ${units(1)};
`;

/**
 * `Header` component.
 */

const Header = (props: Props): Node => {
  const { icon, onLogout, title } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <ContentWrapper>
        <Content>
          <Icon
            color={color('textColorLight')}
            icon={icon}
            size={units(4)}
          />

          <Type.Paragraph
            as={'h1'}
            color={color('textColorLight')}
            fontWeight={400}
            lineHeight={units(3)}
            marginLeft={'10px'}
          >
            {title}
          </Type.Paragraph>
        </Content>

        {onLogout ? (
          <Content>
            <Icon
              color={color('textColorLight')}
              icon={logoutIcon}
              size={units(3)}
            />

            <StyledLink
              colorTheme={'secondary'}
              onClick={onLogout}
            >
              {translate('common.actions.logout')}
            </StyledLink>
          </Content>
        ) : <UserAccount />}
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `Header` component.
 */

export default Header;
