// @flow

/**
 * Module dependencies.
 */

import { isUSA, useTranslatedOptions } from 'core/utils/locales';
import { useFieldState } from '@seegno/react-forms';
import InputField from 'client/components/core/forms/fields/input';
import React, { type Node } from 'react';
import SelectField from 'client/components/core/forms/fields/select';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  countryFieldName: string,
  disabled: boolean,
  stateFieldName: string
|};

/**
 * `StateField` component.
 */

const StateField = (props: Props): Node => {
  const { countryFieldName, disabled, stateFieldName } = props;
  const { value } = useFieldState(countryFieldName);
  const { translate } = useTranslate();
  const usaStatesOptions = useTranslatedOptions('usaStates');

  return (
    <>
      {isUSA(value) ? (
        <SelectField
          disabled={disabled}
          items={usaStatesOptions}
          label={translate('kyc.form.fields.state')}
          name={stateFieldName}
        />
      ) : (
        <InputField
          disabled={disabled}
          label={translate('kyc.form.fields.state')}
          name={stateFieldName}
        />
      )}
    </>
  );
};

/**
 * Export `StateField` component.
 */

export default StateField;
