// @flow

/**
 * Export `session`.
 */

export const session = {
  clear: () => sessionStorage.clear(),
  get: (storageKey: string) => sessionStorage.getItem(storageKey),
  remove: (storageKey: string) => sessionStorage.removeItem(storageKey),
  set: (storageKey: string, storageValue: string | Object) => sessionStorage.setItem(storageKey, storageValue)
};
