// @flow

/**
 * Module dependencies.
 */

import { Select as PmintSelect } from 'pmint-design-system';
import { getFieldValue } from 'client/core/utils/form';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  emptyOptionLabel?: string,
  items: Array<{
    label: string,
    value: string
  }>,
  label: string,
  name: string,
  onChangeField?: string => void
|};

/**
 * `Select` component.
 */

const Select = (props: Props): Node => {
  const {
    disabled,
    emptyOptionLabel,
    items,
    label,
    name,
    onChangeField,
    ...rest
  } = props;

  const {
    error,
    meta,
    onBlur,
    onChange,
    onFocus,
    value
  } = useField(name);

  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleBlur = useCallback(event => {
    if (onBlur) {
      onBlur(event);
      onChange(getFieldValue(value));
    }
  }, [onBlur, onChange, value]);

  const handleChange = useCallback(({ target }) => {
    onChange(target.value);
    if (onChangeField) {
      onChangeField(target.value);
    }
  }, [onChange, onChangeField]);

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const selectLabel = useLabel(label, name);

  return (
    <PmintSelect
      {...rest}
      disabled={isDisabled}
      emptyOptionLabel={emptyOptionLabel ? emptyOptionLabel : null}
      hasError={hasError}
      items={items}
      label={selectLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      value={value}
    />
  );
};

/**
 * Export `Select` component.
 */

export default Select;
