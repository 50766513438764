// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import type { Kyc } from 'client/core/types/kyc';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { submitIdentity } from 'client/core/redux/actions/submit-identity';
import { updateSessionKycStatus } from 'client/core/utils/kyc';

/**
 * Export `performSubmitIdentity`.
 */

export const performSubmitIdentity = (values: Kyc) => (dispatch: Dispatch) => {
  return dispatch(submitIdentity(values)).then(() => {
    updateSessionKycStatus('pending');

    return dispatch(fetchAccount());
  });
};
