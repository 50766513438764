// @flow

/**
 * Module dependencies.
 */

import { Redirect } from 'react-router-dom';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { useSelector } from 'react-redux';
import React, { type Node } from 'react';
import routes from 'core/routes';

/**
 * `Home` container.
 */

const Home = (): Node => {
  const isAuthenticated = useSelector(isUserAuthenticated);

  if (isAuthenticated) {
    return <Redirect to={routes.profile.base} />;
  }

  return <Redirect to={routes.signIn.base} />;
};

/**
 * Export `Home` container.
 */

export default Home;
