// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getDeleteAccountAndIdentityState`.
 */

export const getDeleteAccountAndIdentityState = createRequestStateSelector('deleteAccountAndIdentity');
