// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchUserInformationType`.
 */

export const fetchUserInformationType = 'FETCH_USER_INFORMATION';

/**
 * Export `fetchUserInformationTypes`.
 */

export const fetchUserInformationTypes = createRequestActionTypes({
  type: fetchUserInformationType
});
