// @flow

/**
 * Module dependencies.
 */

import { type AuthProvider } from 'client/core/types/auth-provider';
import type { Kyc, KycInformation } from 'client/core/types/kyc';
import { type KycDocument } from 'client/core/types/kyc-documents';
import type { User } from 'client/core/types/user';
import { camelCase, find, get, isEmpty, map, omit, pick, upperFirst } from 'lodash';
import { documentSides } from './kyc-documents';
import { documentTypes } from 'core/types/document-types';

/**
 * `UserInformation` type.
 */

type UserInformation = {
  email: string,
  fullName: string,
  phone: {
   number: string,
   sms: boolean
  }
 };

/**
 * `formatToDate` util.
 */

const formatToDate = (date: string) => {
  const parsedDate = new Date(date);

  return parsedDate.toISOString().slice(0, 10);
};

/**
 * Export `normalizeUserInformation`.
 */

export const normalizeUserInformation = (userInformation?: UserInformation) => {
  if (!userInformation) {
    return {};
  }

  return {
    email: get(userInformation, 'email'),
    fullName: get(userInformation, 'name'),
    phone: get(userInformation, 'phone.number')
  };
};

/**
 * Export `normalizeKycInformation`.
 */

export const normalizeKycInformation = (data: Object): KycInformation => ({
  contactInformation: {
    email: get(data, 'email'),
    phone: get(data, 'phone.number')
  },
  homeAddressInformation: {
    city: get(data, 'address.city'),
    country: get(data, 'address.country'),
    postalCode: get(data, 'address.postalCode'),
    state: get(data, 'address.state'),
    street: get(data, 'address.street')
  },
  identityInformation: {
    birthday: formatToDate(get(data, 'birthday')),
    gender: get(data, 'gender'),
    name: get(data, 'name')
  },
  taxInformation: {
    country: get(data, 'tax.country'),
    idNumber: get(data, 'tax.idNumber'),
    state: get(data, 'tax.state')
  }
});

/**
 * Export `normalizeUser`.
 */

export const normalizeUser = (data: Object): User => ({
  ...omit(data, ['profilePicturePath']),
  pictureUrl: get(data, 'profilePicturePath')
});

/**
 * Export `normalizeKycResult`.
 */

export function normalizeKycResult(data: Object) {
  return {
    token: get(data, 'jwt')
  };
}

/**
 * Export `normalizeDeletedKycDocument`.
 */

export function normalizeDeletedKycDocument(data: Object) {
  return {
    id: get(data, 'id')
  };
}

/**
 * Normalize document type and side.
 */

function normalizeDocumentTypeAndSide(documentType: string, documentSide: string) {
  const type = camelCase(documentType);
  const side = camelCase(documentSide);

  if (type === documentTypes.utilityBill) {
    return {
      side: documentSides.backSide,
      type: documentTypes.passport
    };
  }

  return { side, type };
}

/**
 * Export `denormalizeDocumentTypeAndSide`.
 */

export function denormalizeDocumentTypeAndSide(type: string, side: string) {
  if (type === documentTypes.passport && side === documentSides.backSide) {
    return {
      side: upperFirst(documentSides.frontSide),
      type: upperFirst(documentTypes.utilityBill)
    };
  }

  return {
    side: upperFirst(side),
    type: upperFirst(type)
  };
}

/**
 * Export `denormalizeTaxInformation`.
 */

export function denormalizeTaxInformation({ taxData }: $Diff<Kyc, {
  kycDocuments: Array<KycDocument>,
  userData: Object
}>) {
  const street = taxData?.['address.street'];
  const street2 = taxData?.['address.street2'];
  const joinedStreet = isEmpty(street2) ? street : `${street} ${street2}`;
  const addressInformation = {
    address: {
      city: taxData?.['address.city'],
      country: taxData?.['address.country'],
      postalCode: taxData?.['address.postalCode'],
      state: taxData?.['address.state'],
      street: joinedStreet
    }
  };

  const taxInformation = {
    tax: {
      country: taxData?.['tax.country'],
      idNumber: taxData?.['tax.idNumber'],
      state: taxData?.['tax.state']
    }
  };

  return {
    addressInformation,
    taxInformation
  };
}

/**
 * Export `normalizeKycDocuments`.
 */

export function normalizeKycDocuments(data: Array<Object>): Array<KycDocument> {
  return map(data, document => ({
    ...pick(document, ['id', 'label', 'status']),
    ...normalizeDocumentTypeAndSide(
      get(document, 'type'),
      get(document, 'side')
    )
  }));
}

/**
 * Export `normalizeAuthProviders`.
 */

export const normalizeAuthProviders = (data: Object): Array<AuthProvider> => {
  const orderedKeys = ['google', 'apple', 'microsoft', 'dropbox'];

  return orderedKeys.reduce((providers, key) => {
    const providerConfig = find(data, { key });

    if (!providerConfig) {
      return providers;
    }

    return [
      ...providers,
      providerConfig
    ];
  }, []);
};
