// @flow

/**
 * Module dependencies.
 */

import { defaults, sum } from 'lodash';

/**
 * `PollingOperation` type.
 */

type PollingOperation = (retry: () => void) => void | Promise<any>;

/**
 * `Options` type.
 */

type Options = {
  maxWaitTime: number
};

/**
 * Default options.
 */

const defaultOptions = {
  // Wait at most one minute.
  maxWaitTime: 1000 * 60
};

/**
 * Export `Polling`.
 *
 * Implements retries with fibonacci backoff.
 */

export class Polling {

  /**
   * Operation.
   */

  operation: PollingOperation;

  /**
   * Options.
   */

  options: Options;

  /**
   * Timeout ID.
   */

  timeoutId: ?TimeoutID;

  /**
   * Last wait times.
   */

  lastWaitTimes: Array<number> = [0, 0];

  /**
   * Constructor.
   */

  constructor(operation: PollingOperation, options: Options = {}) {
    this.operation = operation;
    this.options = defaults({}, options, defaultOptions);
  }

  /**
   * Get wait time.
   */

  getWaitTime() {
    const { maxWaitTime } = this.options;

    if (this.lastWaitTimes[1] > maxWaitTime) {
      return maxWaitTime;
    }

    const waitTime = Math.max(sum(this.lastWaitTimes), 1000);

    this.lastWaitTimes = this.lastWaitTimes.concat(waitTime).slice(-2);

    return waitTime;
  }

  /**
   * Start.
   */

  start() {
    this.timeoutId = setTimeout(() => {
      this.operation(() => {
        this.start();
      });
    }, this.getWaitTime());
  }

  /**
   * Cancel.
   */

  cancel() {
    clearTimeout(this.timeoutId);
  }

}
