// @flow

/**
 * Module dependencies.
 */

import { Link, Type, color } from 'pmint-design-system';
import { ifProp } from 'styled-tools';
import { useFormState } from '@seegno/react-forms';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  onRequestClose: () => void
|};

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 29px;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: 400;
  text-decoration: underline;

  ${ifProp('disabled', css`
    cursor: not-allowed;
  `)};
`;

/**
 * `ChangeImageModalHeader` component.
 */

const ChangeImageModalHeader = ({ onRequestClose }: Props): Node => {
  const { translate } = useTranslate();
  const { isSubmitting } = useFormState();

  return (
    <HeaderWrapper>
      <Type.Label
        as={'p'}
        color={color('textColor')}
        fontWeight={500}
      >
        {translate('profile.changeImage.title')}
      </Type.Label>

      <StyledLink
        colorTheme={'secondary'}
        disabled={isSubmitting}
        onClick={isSubmitting ? null : onRequestClose}
        size={'small'}
      >
        {translate('common.actions.cancel')}
      </StyledLink>
    </HeaderWrapper>
  );
};

/**
 * Export `ChangeImageModalHeader` component.
 */

export default ChangeImageModalHeader;
