// @flow

/**
 * Module dependencies.
 */

import { Redirect, Route } from 'react-router-dom';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { useSelector } from 'react-redux';
import React, { type ComponentType, type Node } from 'react';
import routes from 'core/routes';

/**
 * `Props` type.
 */

type Props = {|
  children?: Node,
  component?: ComponentType<*>,
  exact?: boolean,
  path: string
|};

/**
 * `ConditionalRoute` component.
 */

const ConditionalRoute = (props: Props): Node => {
  const isAuthenticated = useSelector(isUserAuthenticated);

  if (isAuthenticated) {
    return <Route {...props} />;
  }

  return <Redirect to={routes.signIn.base} />;
};

/**
 * Export `ConditionalRoute` component.
 */

export default ConditionalRoute;
