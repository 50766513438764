// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `logoutType`.
 */

export const logoutType = 'LOGOUT';

/**
 * Export `logoutTypes`.
 */

export const logoutTypes = createRequestActionTypes({
  method: 'POST',
  type: logoutType
});
