// @flow

/**
 * Module dependencies.
 */

import { Icon, color, states, units } from 'pmint-design-system';
import { ifProp, theme } from 'styled-tools';
import React, { type Node, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  'aria-label': string,
  className?: string,
  disabled?: boolean,
  icon: string,
  onClick?: SyntheticMouseEvent<any> => void,
  type: string
|};

/**
 * `Button` styled component.
 */

const Button = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  border-radius: 50%;
  color: ${color('gray500')};
  cursor: pointer;
  line-height: 0;
  margin: 0;
  outline: none;
  padding: ${units(1.5)};
  position: relative;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, color;

  ${states.interaction`
    background-color: ${color('gray300')};
  `}

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}
`;

/**
 * `Ripple` styled component.
 */

const Ripple = styled.div`
  animation: ${theme('keyframes.ripple')} 0.75s cubic-bezier(0.14, 0.65, 0.25, 1) both;
  background: ${color.transparentize('secondary', 0.3)};
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transform-origin: 50% 50%;
  width: 100%;
`;

/**
 * `IconButton` component.
 */

function IconButton(props: Props): Node {
  const { icon, onClick, ...rest } = props;
  const [count, setCount] = useState(0);
  const handleClick = useCallback(event => {
    setCount(count => count + 1);

    if (onClick) {
      onClick(event);
    }
  }, [onClick]);

  return (
    <Button
      {...rest}
      onClick={handleClick}
    >
      {count > 0 && <Ripple key={count} />}

      <Icon
        icon={icon}
        size={units(3)}
      />
    </Button>
  );
}

/**
 * Default props.
 */

IconButton.defaultProps = {
  type: 'button'
};

/**
 * Export `IconButton` component.
 */

export default IconButton;
