// @flow

/**
 * Module dependencies.
 */

import { checkIfEmailExistsType } from 'client/core/redux/action-types/check-if-email-exists';
import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `checkIfEmailExists`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: checkIfEmailExistsType
}));
