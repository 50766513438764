// @flow

/**
 * Module dependencies.
 */

import { Link, Separator, Type, color, media, units } from 'pmint-design-system';
import { Trans } from 'react-i18next';
import {
  fetchAuthProviders,
  resetFetchAuthProviders
} from 'client/core/redux/actions/fetch-auth-providers';

import { getFetchAuthProvidersState } from 'client/core/redux/selectors/fetch-auth-providers';
import { getUser } from 'client/core/redux/selectors/user';
import { isEmpty, isNil } from 'lodash';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { theme } from 'styled-tools';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Loading from 'client/components/core/loading';
import PageLayout from 'client/components/page-layout';
import React, { type Node, useEffect } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import SignInForm from 'client/components/sign-in/sign-in-form';
import SignInOptions from 'client/components/sign-in/sign-in-options';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMesssage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;
  height: 100%;

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-self: center;
  grid-column: 2;
  justify-self: center;
  max-width: ${units(60)};
  position: relative;
  width: 100%;
`;

/**
 * `SignInOptionsWrapper` styled component.
 */

const SignInOptionsWrapper = styled.div`
  display: grid;
  grid-row-gap: 38px;
  padding-bottom: ${units(3)};
`;

/**
 * `DontHaveAnAccountLabel` styled component.
 */

const DontHaveAnAccountLabel = styled(Type.Paragraph)`
  color: ${color('textColorLight')};
  font-weight: 400;
  line-height: ${units(3)};
  margin-right: ${units(0.5)};
  padding-bottom: 27px;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  font-weight: 500;
  line-height: 24px;
  text-decoration: underline;
`;

/**
 * `SignIn` container.
 */

const SignIn = (): Node => {
  const {
    data,
    errors,
    isLoading,
    isSuccessful
  } = useSelector(getFetchAuthProvidersState);

  const { translate } = useTranslate();
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(getUser);

  useEffect(() => {
    if (isAuthenticated && !isEmpty(user)) {
      const { kycStatus } = user;

      if (isNil(kycStatus)) {
        return history.push(routes.register.identityInformation, user);
      }

      return history.push(routes.profile.base);
    }
  }, [history, isAuthenticated, user]);

  useEffect(() => {
    dispatch(fetchAuthProviders());

    return () => dispatch(resetFetchAuthProviders());
  }, [dispatch]);

  useNetworkErrorMesssage('signIn.authProviders.errors', errors);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper>
        <Line />

        <ContentWrapper>
          {isLoading ? <Loading isVisible /> : (
            <>
              <Type.H4
                color={color('bender2')}
                marginBottom={units(4.5)}
              >
                {translate('signIn.hiThere')}
              </Type.H4>

              {isSuccessful && (
                <>
                  <Type.Paragraph
                    color={color('textColorLight')}
                    fontWeight={400}
                    marginBottom={units(1.5)}
                  >
                    {translate('signIn.authProvidersTitle')}
                  </Type.Paragraph>

                  <SignInOptionsWrapper>
                    <SignInOptions options={data} />

                    <Separator>
                      {translate('signIn.or')}
                    </Separator>
                  </SignInOptionsWrapper>
                </>
              )}

              <DontHaveAnAccountLabel>
                <Trans
                  components={[
                    <StyledLink
                      colorTheme={'secondary'}
                      key={'register'}
                      to={routes.register.base}
                    />
                  ]}
                  i18nKey={'signIn.dontHaveAccount'}
                />
              </DontHaveAnAccountLabel>

              <SignInForm />
            </>
          )}
        </ContentWrapper>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `SignIn` container.
 */

export default SignIn;
