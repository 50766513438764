// @flow

/**
 * Module dependencies.
 */

import type { Kyc } from 'client/core/types/kyc';
import { cookiesStorage } from 'client/core/utils/cookies';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { denormalizeDocumentTypeAndSide } from 'client/core/utils/kyc-documents';
import {
  denormalizeTaxInformation,
  normalizeKycResult
} from 'client/core/utils/normalizer';
import { pick } from 'lodash';
import { storageKeys } from 'core/utils/storage-keys';
import { submitKycType } from 'client/core/redux/action-types/submit-kyc';

/**
 * Export `submitKyc`.
 */

export const submitKyc = ({ kycDocuments, taxData, userData }: Kyc) => {
  const {
    addressInformation,
    taxInformation
  } = denormalizeTaxInformation({ taxData });

  const documents = kycDocuments.map(document => ({
    ...document,
    ...denormalizeDocumentTypeAndSide(document.type, document.side)
  }));

  return createRequestAction({
    apiName: 'app',
    data: {
      ...addressInformation,
      ...taxInformation,
      ...pick(userData, ['birthday', 'email', 'gender', 'name']),
      documents,
      phone: {
        number: userData.phone,
        sms: false
      }
    },
    endpoint: 'submitKyc',
    handleResponse: ({ data }) => {
      if (data) {
        const { token } = normalizeKycResult(data);

        const existedToken = cookiesStorage.get(storageKeys.token);

        if (existedToken) {
          cookiesStorage.remove(storageKeys.token);
        }

        cookiesStorage.set(storageKeys.token, token);

        return data;
      }
    },
    method: 'POST',
    type: submitKycType
  });
};

/**
 * Export `resetSubmitKyc`.
 */

export const resetSubmitKyc = createResetRequestAction({
  method: 'POST',
  type: submitKycType
});
