// @flow

/**
 * Module dependencies.
 */

import { cookiesStorage } from 'client/core/utils/cookies';
import { createRequestAction } from 'core-redux/request';
import { get } from 'lodash';
import { refreshTokenType } from 'client/core/redux/action-types/refresh-token';
import { storageKeys } from 'core/utils/storage-keys';

/**
 * Export `refreshToken`.
 */

export const refreshToken = () => {
  return createRequestAction({
    apiName: 'account',
    endpoint: 'refreshToken',
    handleResponse: ({ data }) => {
      const token = get(data, 'token');

      if (token) {
        cookiesStorage.remove(storageKeys.token);
      }

      cookiesStorage.set(storageKeys.token, token);

      return data;
    },
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'POST',
    type: refreshTokenType
  });
};
