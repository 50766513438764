// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import PageContent from 'client/components/page-layout/page-content';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: Node,
  description: string,
  errorMessage: string,
  isFailed: boolean,
  title: string
};

/**
 * `FormWrapper` styled component.
 */

const FormWrapper = styled.div`
  max-width: ${units(60)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('textColor')};
  font-weight: 400;
  letter-spacing: 0;
  margin-bottom: ${units(3)};
`;

/**
 * `SetPasswordLayout` component.
 */

function SetPasswordLayout(props: Props): Node {
  const {
    children,
    description,
    errorMessage,
    isFailed,
    title
  } = props;

  return (
    <PageContent>
      <Type.H4
        as={'h2'}
        color={color('bender2')}
        marginBottom={units(2)}
        textAlign={isFailed ? 'center' : 'initial'}
      >
        {title}
      </Type.H4>

      {isFailed ? (
        <RawHtml
          element={Description}
          textAlign={'center'}
        >
          {errorMessage}
        </RawHtml>
      ) : (
        <>
          <RawHtml element={Description}>
            {description}
          </RawHtml>

          <FormWrapper>
            {children}
          </FormWrapper>
        </>
      )}
    </PageContent>
  );
}

/**
 * Export `SetPasswordLayout` component.
 */

export default SetPasswordLayout;
