// @flow

/**
 * Module dependencies.
 */

import type { Dispatch, State } from 'redux';
import {
  addDocumentFileType,
  addInitialFieldsType,
  addUploadDocumentErrorsType,
  addUploadProgressType,
  addUploadedDocumentType,
  cancelUploadDocumentType,
  presignUrlType
} from 'client/core/redux/action-types/upload-documents';

import { clamp, get } from 'lodash';
import { createRequestAction } from 'core-redux/request';
import axios from 'axios';

/**
 * `AddUploadedDocument` type.
 */

type AddUploadedDocument = {
  fields: Object,
  fileName: string,
  id: string
};

/**
 * Export `addUploadedDocument`.
 */

export function addUploadedDocument(options: AddUploadedDocument) {
  const { fields, fileName, id } = options;

  return {
    payload: { fields, fileName, id },
    type: addUploadedDocumentType
  };
}

/**
 * Export `addDocumentType`.
 */

export function addDocumentType(documentType: string) {
  return {
    payload: { documentType },
    type: addDocumentFileType
  };
}

/**
 * Export `addInitialFields`.
 */

export function addInitialFields(file: File, id: string) {
  return {
    payload: { file, id },
    type: addInitialFieldsType
  };
}

/**
 * Export `addUploadDocumentErrors`.
 */

export function addUploadDocumentErrors(errors: Object, id: string) {
  return {
    payload: { errors, id },
    type: addUploadDocumentErrorsType
  };
}

/**
 * Export `addUploadProgress`.
 */

export function addUploadProgress(file: File, id: string, progress: number) {
  return {
    payload: { file, id, progress },
    type: addUploadProgressType
  };
}

/**
 * Export `cancelUploadDocument`.
 */

export function cancelUploadDocument(id: string) {
  return {
    payload: { id },
    type: cancelUploadDocumentType
  };
}

/**
 * Presign Url.
 */

const presignUrl = (filename: string) => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'presignUrl',
    getQuery: () => ({ filename }),
    handleResponse: ({ data }) => data?.data,
    type: presignUrlType
  });
};

/**
 * `UploadFile` type.
 */

type UploadFile = {
  fields: Object,
  file: File,
  id: string,
  url: string
};

/**
 * Upload file.
 */

const uploadFile = (options: UploadFile) => (dispatch: Dispatch, getState: State) => {
  const { fields, file, id, url } = options;
  const formData = new FormData();
  const cancelToken = axios.CancelToken.source();

  Object.entries(fields).map(([key, value]) => {
    // $FlowFixMe
    formData.append(key, value);
  });

  formData.append('file', file);

  axios
    .post(url, formData, {
      cancelToken: cancelToken.token,
      onUploadProgress: ({ loaded, total }) => {
        const allDocuments = get(getState(), 'documents');
        const document = allDocuments.find(document => document.id === id);

        if (document.wasCancelled) {
          return cancelToken.cancel();
        }

        return dispatch(addUploadProgress(file, id, clamp(loaded / total, 0, 1)));
      }
    })
    .then(
      () => dispatch(addUploadedDocument({ fields, fileName: file.name, id })),
      error => {
        if (axios.isCancel(error)) {
          return;
        }

        return dispatch(addUploadDocumentErrors(error?.response?.data ?? error, id));
      }
    );

  return () => {
    cancelToken.cancel();
  };
};

/**
 * `UploadDocument` type.
 */

type UploadDocument = {
  file: File,
  id: string
};

/**
 * Export `uploadDocument`.
 */

export const uploadDocument = ({ file, id }: UploadDocument) => {
  return async (dispatch: Dispatch) => {
    dispatch(addInitialFields(file, id));

    const { name } = file;
    const { value } = await dispatch(presignUrl(name));
    const { fields, url } = value;

    return dispatch(uploadFile({ fields, file, id, url }));
  };
};
