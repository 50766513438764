// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `updateKycType`.
 */

export const updateKycType = 'UPDATE_KYC';

/**
 * Export `updateKycTypes`.
 */

export const updateKycTypes = createRequestActionTypes({
  method: 'PATCH',
  type: updateKycType
});
