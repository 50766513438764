// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { deleteAccountAndIdentityType } from 'client/core/redux/action-types/delete-account-and-identity';

/**
 * Export `deleteAccountAndIdentity`.
 */

export default combineReducers(createRequestReducers({
  method: 'DELETE',
  type: deleteAccountAndIdentityType
}));
