// @flow

/**
 * Routes.
 */

const routes = {
  about: '/about',
  changeImage: '/change-image',
  changePassword: '/change-password',
  documents: '/documents',
  fees: '/fees',
  followUp: {
    base: '/follow-up',
    cancelDeposit: '/follow-up/cancel-deposit',
    depositExecuted: '/follow-up/deposit-executed',
    depositExecutedSoon: '/follow-up/deposit-executed-soon'
  },
  home: '/',
  kyc: '/kyc',
  privacyPolicy: '/privacy-policy',
  profile: {
    base: '/profile',
    changePassword: '/profile/change-password'
  },
  recoverPassword: '/recover-password',
  register: {
    base: '/register',
    identityInformation: '/register/identity-information',
    identityVerification: {
      base: '/register/identity-verification',
      success: '/register/identity-verification/success'
    },
    taxInformation: '/register/tax-information'
  },
  removeAccount: '/remove-account',
  resetPassword: '/reset-password',
  resetPasswordSuccess: '/reset-password-success',
  setPassword: '/set-password',
  setPasswordSuccess: '/set-password-success',
  signIn: {
    base: '/sign-in',
    welcomeBack: '/welcome-back'
  }
};

/**
 * Export `routes`.
 */

export default routes;
