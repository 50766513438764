// @flow

/**
 * Module dependencies.
 */

import { SnackbarContext } from './snackbar-context';
import { Transition, TransitionGroup } from 'react-transition-group';
import { media, themeProp, units } from 'react-components/styles';
import { reject } from 'lodash';
import { switchProp } from 'styled-tools';
import React, { type Node, useCallback, useMemo, useReducer } from 'react';
import SnackbarContent from './snackbar-content';
import styled, { css } from 'styled-components';

/**
 * `State` type.
 */

type State = {
  messages: Array<{
    content: string,
    id: number,
    options?: Object
  }>,
  total: number
};

/**
 * `Action` type.
 */

type Action = {
  payload: Object,
  type: string
};

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * Action types.
 */

const actionTypes = {
  addMessage: 'ADD_MESSAGE',
  removeMessage: 'REMOVE_MESSAGE'
};

/**
 * Reducer.
 */

const reducer = (state: State, action: Action) => {
  const { payload, type } = action;

  switch (type) {
    case actionTypes.addMessage: {
      const total = state.total + 1;

      return {
        messages: [
          ...state.messages,
          {
            ...payload?.message,
            id: total
          }
        ],
        total
      };
    }

    case actionTypes.removeMessage:
      return {
        messages: reject(state.messages, payload),
        total: state.total - 1
      };

    default:
      return state;
  }
};

/**
 * State time.
 */

const stateTime = 500;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  bottom: ${units(2)};
  left: ${units(2)};
  max-width: ${units(56)};
  position: fixed;
  right: ${units(2)};
  z-index: ${themeProp('zIndex.snackbar')};

  ${media.min('md')`
    max-width: calc(33.5% - 32px);
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  transition: opacity ${themeProp('animations.defaultTransition')};

  &:not(:last-child) {
    margin-bottom: ${units(2)};
  }

  ${switchProp('state', {
    entered: css`
      opacity: 1;
    `,
    entering: css`
      opacity: 1;
    `,
    exited: css`
      opacity: 0;
    `,
    exiting: css`
      opacity: 0;
    `
  })}
`;

/**
 * `SnackbarProvider` container.
 */

const SnackbarProvider = (props: Props): Node => {
  const { children } = props;
  const [{ messages }, dispatch] = useReducer(reducer, { messages: [], total: 0 });
  const showMessage = useCallback((content, options) => {
    dispatch({
      payload: {
        message: { content, options }
      },
      type: actionTypes.addMessage
    });
  }, []);

  const removeMessage = useCallback((id: number) => {
    dispatch({
      payload: { id },
      type: actionTypes.removeMessage
    });
  }, []);

  const value = useMemo(() => ({
    showErrorMessage: (content, options) => {
      showMessage(content, { ...options, type: 'error' });
    },
    showInfoMessage: (content, options) => {
      showMessage(content, { ...options, type: 'info' });
    },
    showSuccessMessage: (content, options) => {
      showMessage(content, { ...options, type: 'success' });
    },
    showWarningMessage: (content, options) => {
      showMessage(content, { ...options, type: 'warning' });
    }
  }), [showMessage]);

  return (
    <SnackbarContext.Provider value={value}>
      {children}

      <Wrapper>
        <TransitionGroup>
          {messages.map(({ content, id, options }) => (
            <Transition
              key={id}
              timeout={stateTime}
            >
              {state => (
                <ContentWrapper state={state}>
                  <SnackbarContent
                    id={id}
                    key={id}
                    onDismiss={removeMessage}
                    options={options}
                  >
                    {content}
                  </SnackbarContent>
                </ContentWrapper>
              )}
            </Transition>
          ))}
        </TransitionGroup>
      </Wrapper>
    </SnackbarContext.Provider>
  );
};

/**
 * Export `SnackbarProvider` container.
 */

export default SnackbarProvider;
