// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { logoutTypes } from 'client/core/redux/action-types/logout';
import { pick } from 'lodash';
import authentication from './authentication';
import changeImage from './change-image';
import changePassword from './change-password';
import checkIfEmailExists from './check-if-email-exists';
import confirmEmail from './confirm-email';
import createScheduleJob from './create-schedule-job';
import deleteAccountAndIdentity from './delete-account-and-identity';
import deleteDocument from './delete-document';
import documents from './documents';
import fetchAccount from './fetch-account';
import fetchAuthProviders from './fetch-auth-providers';
import fetchDeposit from './fetch-deposit';
import fetchDocuments from './fetch-documents';
import fetchResetPasswordToken from './fetch-reset-password-token';
import fetchUserInformation from './fetch-user-information';
import initialize from './initialize';
import kyc from './kyc';
import logout from './logout';
import recoverPassword from './recover-password';
import removeAccount from './remove-account';
import requestAccountDeletion from './request-account-deletion';
import resetPassword from './reset-password';
import setPassword from './set-password';
import signIn from './sign-in';
import signInWithOptions from './sign-in-with-options';
import submitDocuments from './submit-documents';
import submitIdentity from './submit-identity';
import submitKyc from './submit-kyc';
import updateDeposit from './update-deposit';
import updateKyc from './update-kyc';
import uploadDocuments from './upload-documents';
import uploadImage from './upload-image';
import user from './user';

/**
 * Reducer.
 */

const reducer = combineReducers({
  authentication,
  changeImage,
  changePassword,
  checkIfEmailExists,
  confirmEmail,
  createScheduleJob,
  deleteAccountAndIdentity,
  deleteDocument,
  documents,
  fetchAccount,
  fetchAuthProviders,
  fetchDeposit,
  fetchDocuments,
  fetchResetPasswordToken,
  fetchUserInformation,
  initialize,
  kyc,
  logout,
  recoverPassword,
  removeAccount,
  requestAccountDeletion,
  resetPassword,
  setPassword,
  signIn,
  signInWithOptions,
  submitDocuments,
  submitIdentity,
  submitKyc,
  updateDeposit,
  updateKyc,
  uploadDocuments,
  uploadImage,
  user
});

/**
 * Export `reducer`.
 */

export default (state: Object, action: Object = {}) => {
  const { type } = action;

  if (type === logoutTypes.successType) {
    return reducer(pick(state, [
      'authentication',
      'initialize'
    ]), action);
  }

  return reducer(state, action);
};
