// @flow

/**
 * Module dependencies.
 */

import { Button, Icon, Loader, color, units } from 'pmint-design-system';
import { useFormActions, useFormState } from '@seegno/react-forms';
import React, { type Node } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  colorTheme?: 'gray' | 'primary' | 'secondary',
  fullWidth?: boolean,
  icon: string,
  iconColor?: string,
  isSubmitting?: boolean,
  variant?: 'fill' | 'outlined'
|};

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  min-height: ${units(8)};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Label` styled component.
 */

const Label = styled.div`
  flex: 1;
  text-align: left;
`;

/**
 * `SubmitButton` component.
 */

const SubmitButton = (props: Props): Node => {
  const { children, icon, iconColor, isSubmitting, ...rest } = props;
  const { submit } = useFormActions();
  const formState = useFormState();
  const { hasErrors } = formState.meta;

  return (
    <StyledButton
      {...rest}
      disabled={hasErrors}
      onClick={submit}
      type={'submit'}
    >
      <ContentWrapper>
        <Label>
          {children}
        </Label>

        {isSubmitting || formState.isSubmitting ? (
          <Loader />
        ) : (
          <Icon
            color={iconColor || color('white')}
            icon={icon}
            size={units(3)}
          />
        )}
      </ContentWrapper>
    </StyledButton>
  );
};

/**
 * Default props.
 */

SubmitButton.defaultProps = {
  icon: arrowIcon
};

/**
 * Export `SubmitButton` component.
 */

export default SubmitButton;
