// @flow

/**
 * Module dependencies.
 */

import { Input as PmintInput } from 'pmint-design-system';
import { getFieldValue } from 'client/core/utils/form';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback } from 'react';
import useLabel from 'client/hooks/use-label';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  id?: string,
  label: string,
  name: string,
  onChange?: (string | number) => (string | number),
  type?: string
|};

/**
 * `Input` component.
 */

const Input = (props: Props): Node => {
  const { disabled, id, label, name, type, ...rest } = props;
  const { error, meta, onBlur, onChange, onFocus, value } = useField(name);
  const { touched } = meta || {};
  const { isSubmitting } = useFormState();
  const handleBlur = useCallback(event => {
    if (onBlur) {
      onBlur(event);
      onChange(getFieldValue(value));
    }
  }, [onBlur, onChange, value]);

  const handleChange = useCallback(({ target }) => {
    if (props.onChange) {
      onChange(props.onChange(target.value));
    } else {
      onChange(target.value);
    }
  }, [onChange, props]);

  const handleKeyDown = useCallback(event => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }, []);

  const hasError = !!error && touched;
  const isDisabled = disabled || isSubmitting;
  const inputLabel = useLabel(label, name);

  return (
    <PmintInput
      {...rest}
      disabled={isDisabled}
      hasError={hasError}
      id={id}
      label={inputLabel}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={onFocus}
      onKeyDown={type === 'email' ? handleKeyDown : null}
      type={type}
      value={value}
    />
  );
};

/**
 * Export `Input` component.
 */

export default Input;
