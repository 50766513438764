// @flow

/**
 * Module dependencies.
 */

import { isUSA, useTranslatedOptions } from 'core/utils/locales';
import { useFieldActions, useFieldState } from '@seegno/react-forms';
import InputField from 'client/components/core/forms/fields/input';
import React, { type Node, useEffect, useState } from 'react';
import SelectField from 'client/components/core/forms/fields/select';

/**
 * `Props` type.
 */

type Props = {|
  countryFieldName: string,
  disabled?: boolean,
  label: string,
  stateFieldName: string
|};

/**
 * `StateField` component.
 */

const StateField = (props: Props): Node => {
  const { countryFieldName, disabled, label, stateFieldName } = props;
  const { value } = useFieldState(countryFieldName);
  const [previousCountry, setPreviousCountry] = useState(value);
  const { setFieldValue } = useFieldActions();
  const usaStatesOptions = useTranslatedOptions('usaStates');

  useEffect(() => {
    if (isUSA(previousCountry) || isUSA(value)) {
      setFieldValue(stateFieldName, null);
    }
    setPreviousCountry(value);
  }, [previousCountry, setFieldValue, stateFieldName, value]);

  return (
    <>
      {isUSA(value) ? (
        <SelectField
          disabled={disabled}
          items={usaStatesOptions}
          label={label}
          name={stateFieldName}
        />
      ) : (
        <InputField
          disabled={disabled}
          label={label}
          name={stateFieldName}
        />
      )}
    </>
  );
};

/**
 * Export `StateField` component.
 */

export default StateField;
