// @flow

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import { theme } from 'styled-tools';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import RecoverPasswordForm from 'client/components/recover-password/recover-password-form';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-row-gap: ${units(5.75)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('bender2')};
  grid-column: 1 / -1;
  height: ${theme('layout.header.height.line')};
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  grid-column: 2;

  ${media.min('xl')`
    justify-self: center;
    max-width: 482px;
    width: 100%;
  `}
`;

/**
 * `RecoverPassword` container.
 */

const RecoverPassword = (): Node => {
  const { translate } = useTranslate();

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper>
        <Line />

        <ContentWrapper>
          <Type.H4
            as={'h1'}
            color={color('bender2')}
            marginBottom={units(4)}
          >
            {translate('recoverPassword.title')}
          </Type.H4>

          <RawHtml
            as={'p'}
            color={color('textColor')}
            element={Type.Label}
            fontWeight={400}
            marginBottom={'26px'}
          >
            {translate('recoverPassword.subtitle')}
          </RawHtml>

          <RecoverPasswordForm />
        </ContentWrapper>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `RecoverPassword` container.
 */

export default RecoverPassword;
