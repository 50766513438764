// @flow

/**
 * Module dependencies.
 */

import { Icon, Loader, color, units } from 'pmint-design-system';
import { find } from 'lodash';
import { ifProp } from 'styled-tools';
import React, { type Node, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import userIcon from 'client/assets/svg/user-96px.svg';

/**
 * `Props` type.
 */

type Props = {|
  pictureUrl: ?string
|};

/**
 * `Image` styled component.
 */

const Image = styled.img`
  border-radius: 50%;
  display: none;
  height: 100%;
  max-height: ${units(13)};
  max-width: ${units(13)};
  object-fit: cover;
  width: 100%;
  z-index: 5;

  ${ifProp('imageLoaded', css`
    display: block;
  `)}
`;

/**
 * `UserImage` component.
 */

const UserImage = ({ pictureUrl }: Props): Node => {
  const [userImage, setUserImage] = useState(pictureUrl);
  const [imageLoaded, setImageLoaded] = useState(() => {
    const image = find(document.images, { src: userImage });

    return image ? image.complete : false;
  });

  useEffect(() => {
    if (pictureUrl !== userImage) {
      setUserImage(pictureUrl);
      setImageLoaded(false);
    }
  }, [pictureUrl, userImage]);

  if (userImage) {
    return (
      <>
        <Image
          imageLoaded={imageLoaded}
          onLoad={() => setImageLoaded(true)}
          src={userImage}
        />

        {!imageLoaded && (
          <Loader
            colorTheme={color('bender1')}
            size={units(7)}
            stroke={units(0.7)}
          />
        )}
      </>
    );
  }

  return (
    <Icon
      color={color('bender1')}
      icon={userIcon}
      size={units(9)}
    />
  );
};

/**
 * Export `UserImage` component.
 */

export default UserImage;
