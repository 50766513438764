// @flow

/**
 * Module dependencies.
 */

import { changeImageType } from 'client/core/redux/action-types/change-image';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

/**
 * `Options` type.
 */

type Options = {|
  pathKey: string
|};

/**
 * Export `changeImage`.
 */

export const changeImage = ({ pathKey }: Options) => {
  return createRequestAction({
    apiName: 'app',
    data: { pathKey },
    endpoint: 'changeImage',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'PATCH',
    type: changeImageType
  });
};

/**
 * Export `resetChangeImage`.
 */

export const resetChangeImage = createResetRequestAction({
  method: 'PATCH',
  type: changeImageType
});
