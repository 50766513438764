// @flow

/**
 * Module dependencies.
 */

import {
  changePassword,
  resetChangePassword
} from 'client/core/redux/actions/change-password';

import { getChangePasswordState } from 'client/core/redux/selectors/change-password';
import { media, units } from 'pmint-design-system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import ForgotPassword from 'client/components/core/forgot-password';
import Form from 'client/components/core/forms/form';
import PasswordField from 'client/components/core/forms/fields/password';
import PasswordValidation from 'client/components/profile/change-password/password-validation';
import React, { type Node, useCallback, useEffect } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import changePasswordSchema from './change-password-schema';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * Initial values.
 */

const initialValues = {
  newPassword: undefined,
  oldPassword: undefined,
  repeatPassword: undefined
};

/**
 * `StyledForgotPassword` styled component.
 */

const StyledForgotPassword = styled(ForgotPassword)`
  margin: 18px 0 37px;
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${units(5)};

  > button {
    flex: 1 1 50%;

    ${media.min('xs')`
      flex-grow: 0;
    `}
  }
`;

/**
 * `ChangePasswordForm` component.
 */

const ChangePasswordForm = (): Node => {
  const { showSuccessMessage } = useSnackbar();
  const { translate } = useTranslate();
  const { errors } = useSelector(getChangePasswordState);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = useCallback(values => {
    return dispatch(changePassword(values)).then(() => {
      showSuccessMessage(translate('profile.changePassword.successDescription'));

      return history.push(routes.profile.base);
    });
  }, [dispatch, history, showSuccessMessage, translate]);

  useEffect(() => {
    return () => dispatch(resetChangePassword());
  }, [dispatch]);

  useNetworkErrorMessage('profile.changePassword.errors', errors);

  return (
    <Form
      errorsBasePath={'profile.changePassword.errors.form'}
      initialValues={initialValues}
      jsonSchema={changePasswordSchema}
      onSubmit={handleSubmit}
    >
      <PasswordField
        autoFocus
        label={translate('profile.changePassword.form.oldPassword')}
        name={'oldPassword'}
      />

      <StyledForgotPassword />

      <PasswordField
        label={translate('profile.changePassword.form.newPassword')}
        name={'newPassword'}
      />

      <PasswordField
        label={translate('profile.changePassword.form.repeatPassword')}
        name={'repeatPassword'}
      />

      <PasswordValidation fieldname={'newPassword'} />

      <SubmitButtonWrapper>
        <SubmitButton fullWidth>
          {translate('common.actions.submit')}
        </SubmitButton>
      </SubmitButtonWrapper>
    </Form>
  );
};

/**
 * Export `ChangePasswordForm` component.
 */

export default ChangePasswordForm;
