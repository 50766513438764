// @flow

/**
 * Module dependencies.
 */

import { confirmEmailType } from 'client/core/redux/action-types/confirm-email';
import { cookiesStorage } from 'client/core/utils/cookies';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { storageKeys } from 'core/utils/storage-keys';

/**
 * `Options` type.
 */

type Options = {|
  email: string,
  token: string
|};

/**
 * Export `confirmEmail`.
 */

export const confirmEmail = ({ email, token }: Options) => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'confirmEmail',
    getQuery: () => ({ email, token }),
    handleResponse: ({ data }) => {
      cookiesStorage.set(storageKeys.token, data?.jwt, {
        expires: 0
      });

      return data;
    },
    type: confirmEmailType
  });
};

/**
 * Export `resetConfirmEmail`.
 */

export const resetConfirmEmail = createResetRequestAction({
  type: confirmEmailType
});
