// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'pmint-design-system';
import Loading from 'client/components/core/loading';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `AboutPageContent` styled component.
 */

const AboutPageContent = styled.article`
  color: ${color('textColor')};
  font-weight: 400;
  line-height: 24px;
  margin-top: 67px;
  text-align: left;

  ${media.min('md')`
    margin-bottom: 67px;
  `}

  header > p {
    font-size: 18px;
    line-height: 28px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: ${units(5)};
    margin-top: 63px;

    ~ p {
      ${media.min('sm')`
        margin-left: 31px;
      `}
    }
  }
`;

/**
 * `AboutContent` component.
 */

const AboutContent = (): Node => {
  const { ready, translate } = useTranslate('about');

  if (!ready) {
    return <Loading isVisible />;
  }

  return (
    <RawHtml element={AboutPageContent}>
      {translate('contentPage')}
    </RawHtml>
  );
};

/**
 * Export `AboutContent` component.
 */

export default AboutContent;
