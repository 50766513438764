// @flow

/**
 * Export `removeAccountSchema`.
 */

export default {
  properties: {
    oneTimeCode: {
      maxLength: 6,
      minLength: 6,
      pattern: '^\\d{6}$',
      type: 'string'
    },
    reason: {
      maxLength: 256,
      type: 'string'
    }
  },
  required: ['oneTimeCode', 'reason']
};
