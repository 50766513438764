// @flow

/**
 * Module dependencies.
 */

import { documentTypes } from 'core/types/document-types';
import { upperFirst } from 'lodash';

/**
 * Export `documentSides`.
 */

export const documentSides = {
  backSide: 'backSide',
  frontSide: 'frontSide'
};

/**
 * Export `denormalizeDocumentTypeAndSide`.
 */

export function denormalizeDocumentTypeAndSide(type: string, side: string) {
  if (type === documentTypes.passport && side === documentSides.backSide) {
    return {
      side: upperFirst(documentSides.frontSide),
      type: upperFirst(documentTypes.utilityBill)
    };
  }

  return {
    side: upperFirst(side),
    type: upperFirst(type)
  };
}
