// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `fetchDocumentsType`.
 */

export const fetchDocumentsType = 'FETCH_DOCUMENTS';

/**
 * Export `fetchDocumentsTypes`.
 */

export const fetchDocumentsTypes = createRequestActionTypes({
  type: fetchDocumentsType
});
