// @flow

/**
 * Module dependencies.
 */

import { Type, color, units } from 'pmint-design-system';
import {
  fetchDocuments,
  resetFetchDocuments
} from 'client/core/redux/actions/fetch-documents';

import {
  getDocuments,
  getUploadedDocuments
} from 'client/core/redux/selectors/documents';

import { isEmpty } from 'lodash';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { performSubmitIdentity } from 'client/core/redux/actions/perform-submit-identity';
import { performSubmitKyc } from 'client/core/redux/actions/perform-submit-kyc';
import { resetSubmitDocuments } from 'client/core/redux/actions/submit-documents';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import RegisterLayout from 'client/components/register/register-layout';
import UploadDocuments from 'client/components/documents/upload-documents';
import routes from 'core/routes';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `IdentityVerification` container.
 */

const IdentityVerification = (): Node => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const documents = useSelector(getDocuments);
  const [documentsErrors, setDocumentsErrors] = useState(null);
  const history = useHistory();
  const uploadedDocuments = useSelector(getUploadedDocuments);
  // This prevents the user from seeing the uploaded documents state.
  const [awaitingDocuments, setAwaitingDocuments] = useState(null);
  const location = useLocation();
  const { state } = location;
  const isAuthenticated = useSelector(isUserAuthenticated);
  const handleSubmitDocuments = useCallback(async () => {
    const { taxData, userData } = state ?? {};

    if (!taxData || !userData) {
      return history.push(routes.register.base);
    }

    const kycDocuments = uploadedDocuments.map(({ side, type, uploadFields }) => ({
      key: uploadFields.key,
      side,
      type
    }));

    setAwaitingDocuments(documents);
    const submitSelector = isAuthenticated ? performSubmitIdentity : performSubmitKyc;

    await dispatch(submitSelector({ kycDocuments, taxData, userData })).then(() => {
      history.push(routes.register.identityVerification.success);
    }).catch(error => {
      setDocumentsErrors(error);
    }).finally(() => {
      setAwaitingDocuments(null);
    });
  }, [dispatch, documents, history, isAuthenticated, state, uploadedDocuments]);

  const progressBarItems = [{
    label: translate('progressBar.register.identityInformation'),
    state: 'complete'
  }, {
    label: translate('progressBar.register.taxInformation'),
    state: 'complete'
  }, {
    label: translate('progressBar.register.identityVerification'),
    state: 'progress'
  }];

  useEffect(() => {
    if (isEmpty(documents)) {
      dispatch(fetchDocuments()).then(({ value }) => {
        if (!isEmpty(value)) {
          return history.push(routes.profile.base);
        }
      });
    }
  }, [dispatch, documents, history]);

  useEffect(() => {
    if (documentsErrors) {
      setDocumentsErrors(null);
    }
  }, [documentsErrors]);

  useEffect(() => {
    return () => {
      dispatch(resetSubmitDocuments());
      dispatch(resetFetchDocuments());
    };
  }, [dispatch]);

  useNetworkErrorMessage('kyc.documents.errors.submitDocuments', documentsErrors);

  return (
    <RegisterLayout progressBarItems={progressBarItems}>
      <Type.Label
        as={'h1'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={units(3)}
      >
        {translate('register.identityVerificationTitle')}
      </Type.Label>

      <UploadDocuments
        documents={awaitingDocuments || documents}
        isSubmitting={!!awaitingDocuments}
        onSubmitDocuments={handleSubmitDocuments}
      />
    </RegisterLayout>
  );
};

/**
 * Export `IdentityVerification` container.
 */

export default IdentityVerification;
