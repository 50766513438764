// @flow

/**
 * Module dependencies.
 */

import { Button, Icon, Loader, Modal, media, units } from 'pmint-design-system';
import { getRemoveAccountState } from 'client/core/redux/selectors/remove-account';
import { getRequestAccountDeletionState } from 'client/core/redux/selectors/request-account-deletion';
import { getUser } from 'client/core/redux/selectors/user';
import {
  performRemoveAccount,
  resetRemoveAccount
} from 'client/core/redux/actions/remove-account';

import {
  requestAccountDeletion,
  resetRequestAccountDeletion
} from 'client/core/redux/actions/request-account-deletion';

import { useDispatch, useSelector } from 'react-redux';
import CancelButton from './cancel-button';
import Form from 'client/components/core/forms/form';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import RemoveAccountConfirm from './remove-account-confirm';
import RemoveAccountFormContent from './remove-account-form-content';
import RemoveAccountSuccess from './remove-account-success';
import SubmitButton from 'client/components/core/forms/submit-button';
import arrowIcon from 'client/assets/svg/arrow.svg';
import removeAccountSchema from 'client/components/profile/remove-account/remove-account-schema';
import styled from 'styled-components';
import useNetworkErrorMessage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * Initial values.
 */

const initialValues = {
  oneTimeCode: undefined,
  reason: undefined
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(5)} ${units(4)};
  text-align: center;

  ${media.min('xs')`
    padding: ${units(10)} ${units(2.5)};
  `}

  ${media.min('lg')`
    padding: ${units(10)} 69px;
  `}
`;

/**
 * `ButtonContent` styled component.
 */

const ButtonContent = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

/**
 * `ButtonsWrapper` styled component.
 */

const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: ${units(2)} 0;

  ${media.min('xs')`
    grid-gap: 0 ${units(2)};
    grid-template-columns: repeat(2, minmax(0, ${units(29)}));
    justify-content: center;
  `}
`;

/**
 * `RemoveAccountModal` component.
 */

const RemoveAccountModal = ({ isVisible, onRequestClose }: Props): Node => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { translate } = useTranslate();
  const { errors } = useSelector(getRemoveAccountState);
  const [isFormContentVisible, setFormContentVisible] = useState(false);
  const [isSuccessVisible, setSuccessVisible] = useState(false);
  const {
    data: requestAccountDeletionData,
    errors: requestAccountDeletionErrors,
    isLoading: requestAccountDeletionIsLoading
  } = useSelector(getRequestAccountDeletionState);

  const handleSubmit = useCallback(values => {
    if (values) {
      return dispatch(performRemoveAccount(values)).then(() => {
        setSuccessVisible(true);
      });
    }
  }, [dispatch]);

  const handleShowRemoveAccountForm = useCallback(() => {
    return dispatch(requestAccountDeletion()).then(() => {
      setFormContentVisible(true);
    });
  }, [dispatch]);

  const handleRequestAccountDeletion = useCallback(() => {
    dispatch(resetRemoveAccount());
    dispatch(resetRequestAccountDeletion());

    return dispatch(requestAccountDeletion());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetRemoveAccount());
      dispatch(resetRequestAccountDeletion());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isVisible) {
      dispatch(resetRemoveAccount());
      dispatch(resetRequestAccountDeletion());

      const timeout = setTimeout(() => {
        setFormContentVisible(false);
      }, 350);

      return () => clearTimeout(timeout);
    }
  }, [dispatch, isVisible]);

  useNetworkErrorMessage('profile.removeAccount.errors', isVisible && (errors || requestAccountDeletionErrors));

  return (
    <Modal isVisible={isVisible}>
      <Wrapper>
        {isSuccessVisible ? <RemoveAccountSuccess /> : (
          <Form
            errorsBasePath={'profile.removeAccount.errors.form'}
            initialValues={initialValues}
            jsonSchema={removeAccountSchema}
            onSubmit={handleSubmit}
          >
            {!isFormContentVisible ? <RemoveAccountConfirm /> : (
              <RemoveAccountFormContent
                countdownDate={requestAccountDeletionData?.deleteAccountCodeExpirationDate}
                email={user.email}
                onRequestDeleteCode={handleRequestAccountDeletion}
              />
            )}

            <ButtonsWrapper>
              <CancelButton
                isVisible={isVisible}
                onRequestClose={onRequestClose}
              />

              {isFormContentVisible ? (
                <SubmitButton
                  colorTheme={'secondary'}
                  iconColor={'inherit'}
                  variant={'outlined'}
                >
                  {translate('common.actions.proceed')}
                </SubmitButton>
              ) : (
                <Button
                  colorTheme={'secondary'}
                  onClick={handleShowRemoveAccountForm}
                  variant={'outlined'}
                >
                  <ButtonContent>
                    {translate('common.actions.proceed')}

                    {requestAccountDeletionIsLoading ? <Loader /> : (
                      <Icon
                        color={'inherit'}
                        icon={arrowIcon}
                        size={units(3)}
                      />
                    )}
                  </ButtonContent>
                </Button>
              )}
            </ButtonsWrapper>
          </Form>
        )}
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `RemoveAccountModal` component.
 */

export default RemoveAccountModal;
