// @flow

/**
 * Module dependencies.
 */

import { Alert, Loader, Type, color, media, units } from 'pmint-design-system';
import {
  fetchUserInformation,
  resetFetchUserInformation
} from 'client/core/redux/actions/fetch-user-information';

import { getFetchUserInformationState } from 'client/core/redux/selectors/fetch-user-information';
import { getUser } from 'client/core/redux/selectors/user';
import { ifProp } from 'styled-tools';
import { isKycMissingError } from 'client/core/utils/kyc';
import { logout, resetLogout } from 'client/core/redux/actions/logout';
import { resetUpdateKyc } from 'client/core/redux/actions/update-kyc';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountMenu from 'client/components/core/account-menu/account-menu';
import BackLink from 'client/components/core/back-link';
import ContactInformation from 'client/components/kyc/contact-information';
import ErrorMessage from 'client/components/core/error-message';
import Header from 'client/components/core/header';
import HomeAddressInformation from 'client/components/kyc/home-address-information';
import IdentityInformation from 'client/components/kyc/identity-information';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import TaxInformation from 'client/components/kyc/tax-information';
import config from 'config';
import kycIcon from 'client/assets/svg/fingerprint-32px.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content min-content auto;

  ${media.min('md')`
    grid-template-columns: 1fr 6fr 1fr;
    min-height: 100vh;
  `}
`;

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-left: ${units(3)};
  margin-top: 3px;
`;

/**
 * `AlertWrapper` styled component.
 */

const AlertWrapper = styled.div`
  grid-column: 1 / -1;
  margin: ${units(5.75)} ${units(3)} 0;

  ${media.min('xxl')`
    grid-column: 2;
    justify-self: center;
    margin-left: 0;
    margin-right: 0;
    max-width: ${units(90)};
    width: 100%;
  `}
`;

/**
 * `StyledRawHtml` styled component.
 */

const StyledRawHtml = styled(RawHtml)`
  font-weight: 400;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2;
  margin-top: 70px;
  overflow: hidden;

  ${media.min('xs')`
    margin-bottom: ${units(3)};
  `}

  ${media.min('xxl')`
    justify-self: center;
    max-width: ${units(90)};
    width: 100%;
  `}

  ${ifProp('hasErrors', css`
    ${media.min('md')`
      margin-top: 0;
    `}
  `)}
`;

/**
 * `LoaderWrapper` styled component.
 */

const LoaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.Label).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-weight: 400;
  text-align: center;

  > a {
    color: ${color('bender2')};
  }
`;

/**
 * `Kyc` container.
 */

const Kyc = (): Node => {
  const {
    errors: fetchUserInformationErrors,
    isLoading,
    isSuccessful
  } = useSelector(getFetchUserInformationState);

  const [editingStep, setEditingStep] = useState(null);
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(fetchUserInformation())
      .catch(error => {
        if (isKycMissingError(error?.code)) {
          history.push(routes.register.identityInformation);
        }
      });
  }, [dispatch, history]);

  useEffect(() => {
    return () => {
      dispatch(resetFetchUserInformation());
      dispatch(resetLogout());
      dispatch(resetUpdateKyc());
    };
  }, [dispatch]);

  return (
    <PageLayout
      hasCirclesSidebar={false}
      sidebarContent={<AccountMenu user={user} />}
    >
      <Wrapper>
        <Header
          icon={kycIcon}
          onLogout={handleLogout}
          title={translate('kyc.title')}
        />

        <BackLinkWrapper>
          <BackLink to={routes.profile.base} />
        </BackLinkWrapper>

        {isSuccessful && (
          <AlertWrapper>
            <Alert
              size={'large'}
              type={'info'}
            >
              <StyledRawHtml>
                {translate('kyc.infoMessage')}
              </StyledRawHtml>
            </Alert>
          </AlertWrapper>
        )}

        <Content hasErrors={fetchUserInformationErrors}>
          {isLoading && (
            <LoaderWrapper>
              <Loader
                colorTheme={color('bender2')}
                size={units(6)}
                stroke={units(0.5)}
              />
            </LoaderWrapper>
          )}

          {fetchUserInformationErrors && (
            <ErrorMessage title={translate('kyc.error.title')}>
              <RawHtml element={Description}>
                {translate('kyc.error.description', {
                  email: config.get('supportEmail')
                })}
              </RawHtml>
            </ErrorMessage>
          )}

          {isSuccessful && (
            <>
              <IdentityInformation
                identityConfirmed={user?.identityConfirmed}
                isEditingFields={editingStep === 'identity'}
                isEditingOtherFields={Boolean(editingStep) && editingStep !== 'identity'}
                setIsEditingFields={(open: boolean) => setEditingStep(open ? 'identity' : null)}
              />

              <ContactInformation
                isEditingFields={editingStep === 'contact'}
                isEditingOtherFields={Boolean(editingStep) && editingStep !== 'contact'}
                setIsEditingFields={(open: boolean) => setEditingStep(open ? 'contact' : null)}
              />

              <TaxInformation
                identityConfirmed={user?.identityConfirmed}
                isEditingFields={editingStep === 'tax'}
                isEditingOtherFields={Boolean(editingStep) && editingStep !== 'tax'}
                setIsEditingFields={(open: boolean) => setEditingStep(open ? 'tax' : null)}
              />

              <HomeAddressInformation
                isEditingFields={editingStep === 'address'}
                isEditingOtherFields={Boolean(editingStep) && editingStep !== 'address'}
                setIsEditingFields={(open: boolean) => setEditingStep(open ? 'address' : null)}
              />
            </>
          )}
        </Content>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `Kyc` container.
 */

export default Kyc;
