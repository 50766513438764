// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `deleteAccountAndIdentityType`.
 */

export const deleteAccountAndIdentityType = 'DELETE_ACCOUNT_AND_IDENTITY';

/**
 * Export `deleteAccountAndIdentityTypes`.
 */

export const deleteAccountAndIdentityTypes = createRequestActionTypes({
  type: deleteAccountAndIdentityType
});
