// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { logout } from 'client/core/redux/actions/logout';
import { removeAccountType } from 'client/core/redux/action-types/remove-account';

/**
 * `RemoveAccountData` type.
 */

type RemoveAccountData = {|
  oneTimeCode: string,
  reason: string
|};

/**
 * `removeAccount` action.
 */

const removeAccount = ({ oneTimeCode, reason }: RemoveAccountData) => {
  return createRequestAction({
    apiName: 'app',
    data: {
      deleteAccountCode: oneTimeCode,
      reasonOfDeletion: reason
    },
    endpoint: 'removeAccount',
    handleResponse: ({ data }) => data,
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'DELETE',
    type: removeAccountType
  });
};

/**
 * Export `performRemoveAccount` action.
 */

export const performRemoveAccount = (values: RemoveAccountData) => (dispatch: Dispatch) => {
  return dispatch(removeAccount(values)).then(() => dispatch(logout({ accountDelete: true })));
};

/**
 * Export `resetRemoveAccount`.
 */

export const resetRemoveAccount = createResetRequestAction({
  method: 'DELETE',
  type: removeAccountType
});
