// @flow

/**
 * Module dependencies.
 */

import { Icon, units } from 'pmint-design-system';
import { isString, times } from 'lodash';
import { prop } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { keyframes } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  colors: [string, string, string, string] | string,
  icon: string
|};

/**
 * `Bounce` animation.
 */

const bounce = keyframes`
  50% {
    transform: translateY(25px);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${units(3)};
  justify-content: center;
  min-height: ${units(66)};
`;

/**
 * `BallsHolder` styled component.
 */

const BallsHolder = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

/**
 * `Ball` styled component.
 */

const Ball = styled.div`
  animation: 2s ${bounce} ease infinite;
  animation-delay: ${({ delayIndex }) => Number(delayIndex) * 0.25}s;
  background-color: ${prop('colorValue')};
  border-radius: 50%;
  height: 22px;
  margin: 0 10px;
  width: 22px;
`;

/**
 * `ProviderLoadingIcon` component.
 */

function ProviderLoadingIcon({ colors, icon }: Props): Node {
  const colorsArray = isString(colors) ? times(4, () => colors) : colors;

  return (
    <Wrapper>
      <Icon
        icon={icon}
        size={'245px'}
      />

      <BallsHolder>
        {colorsArray.map((colorValue, index) => (
          <Ball
            colorValue={colorValue}
            delayIndex={index}
            key={index}
          />
        ))}
      </BallsHolder>
    </Wrapper>
  );
}

/**
 * Export `ProviderLoadingIcon` component.
 */

export default ProviderLoadingIcon;
