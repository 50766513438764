// @flow

/**
 * Module dependencies.
 */

import { getRecoverPasswordState } from 'client/core/redux/selectors/recover-password';
import { media, units } from 'pmint-design-system';
import {
  recoverPassword,
  resetRecoverPassword
} from 'client/core/redux/actions/recover-password';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Form from 'client/components/core/forms/form';
import InputField from 'client/components/core/forms/fields/input';
import ModalPortal from 'client/components/core/modal/modal-portal';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import RecoverPasswordSuccessModal from './recover-password-success-modal';
import SubmitButton from 'client/components/core/forms/submit-button';
import recoverPasswordSchema from 'client/components/recover-password/recover-password-schema';
import routes from 'core/routes';
import styled from 'styled-components';
import useNetworkErrorMesssage from 'client/hooks/use-network-error-message';
import useTranslate from 'client/hooks/use-translate';

/**
 * Initial values.
 */

const initialValues = {
  email: undefined
};

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${units(7)};

  > button {
    flex-basis: 53.05%;
    flex-grow: 1;
    flex-shrink: 1;

    ${media.min('xs')`
      flex-grow: 0;
    `}
  }
`;

/**
 * `RecoverPasswordForm` component.
 */

const RecoverPasswordForm = (): Node => {
  const { translate } = useTranslate();
  const { errors } = useSelector(getRecoverPasswordState);
  const [isModalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const handleBack = useCallback(() => {
    setModalVisible(false);

    return history.push(routes.signIn.base);
  }, [history]);

  const handleSubmit = useCallback(({ email }) => {
    return dispatch(recoverPassword({ email })).then(() => {
      return setModalVisible(true);
    });
  }, [dispatch]);

  useEffect(() => {
    return () => dispatch(resetRecoverPassword());
  }, [dispatch]);

  useNetworkErrorMesssage('recoverPassword.errors.network', errors);

  return (
    <Form
      errorsBasePath={'recoverPassword.errors.form'}
      initialValues={initialValues}
      jsonSchema={recoverPasswordSchema}
      onSubmit={handleSubmit}
    >
      <InputField
        label={translate('recoverPassword.form.fields.email')}
        name={'email'}
        type={'email'}
      />

      <SubmitButtonWrapper>
        <SubmitButton fullWidth>
          {translate('recoverPassword.form.recoverPasswordButton')}
        </SubmitButton>
      </SubmitButtonWrapper>

      <ModalPortal isOpen={isModalVisible}>
        <RecoverPasswordSuccessModal
          isVisible={isModalVisible}
          onRequestClose={handleBack}
        />
      </ModalPortal>
    </Form>
  );
};

/**
 * Export `RecoverPasswordForm` component.
 */

export default RecoverPasswordForm;
