// @flow

/**
 * Module dependencies.
 */

import { type User } from 'client/core/types/user';
import { get } from 'lodash';

/**
 * Export `getUser`.
 */

export const getUser = (state: Object): User => {
  return get(state, 'user');
};
