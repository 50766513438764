// @flow

/**
 * Module dependencies.
 */

import { Type, color, states, units } from 'pmint-design-system';
import { getUser } from 'client/core/redux/selectors/user';
import { ifProp, theme } from 'styled-tools';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { type Node, useCallback } from 'react';
import UserCard from './user-card';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  onLogout: () => void,
  visible: boolean
|};

/**
 * `StyledUserCard` styled component.
 */

const StyledUserCard = styled(UserCard)`
  max-width: ${units(30.5)};
`;

/**
 * `UserInformationWrapper` styled component.
 */

const UserInformationWrapper = styled.div`
  align-items: start;
  display: flex;
  justify-content: space-between;
  padding: ${units(2)} ${units(1.5)} ${units(1.5)} ${units(3)};
`;

/**
 * `UserImage` styled component.
 */

const UserImage = styled.div`
  background-color: ${color('gray300')};
  border-radius: 50%;
  flex-shrink: 0;
  height: ${units(8)};
  margin-right: ${units(2)};
  width: ${units(8)};

  ${ifProp('pictureUrl', css`
    background-color: transparent;
  `)}
`;

/**
 * `Image` styled component.
 */

const Image = styled.img`
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  width: 100%;
`;

/**
 * `InformationWrapper` styled component.
 */

const InformationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${units(1.75)};
`;

/**
 * User information.
 */

const userInformation = css`
  max-width: 127px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * `UserName` styled component.
 */

const UserName = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 400;
  margin-bottom: ${units(0.5)};

  ${userInformation}
`;

/**
 * `UserEmail` styled component.
 */

const UserEmail = styled(Type.Small).attrs({ as: 'p' })`
  color: ${color('textColorLight')};
  font-size: 11px;
  font-weight: 400;

  ${userInformation}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled.button`
  ${theme('typography.styles.small')}

  background-color: transparent;
  border: none;
  color: ${color('textColor')};
  cursor: pointer;
  flex: 1;
  font-weight: 400;
  outline: 0;
  padding: ${units(1.25)};
  transition: background-color ${theme('transitions.defaultTransition')};

  ${states.interaction`
    background-color: ${color('gray200')};
  `}
`;

/**
 * `AccountButton` styled component.
 */

const AccountButton = styled(StyledButton)`
  border-radius: 0 ${units(0.5)} 0 ${units(1)};
  position: relative;
`;

/**
 * `LogoutButton` styled component.
 */

const LogoutButton = styled(StyledButton)`
  border-radius: ${units(0.5)} 0 ${units(1)} 0;
`;

/**
 * `Circle` styled component.
 */

const Circle = styled.span`
  background-color: ${color('warning')};
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(113, 128, 150, 0.2), 0 2px 4px 0 rgba(255, 190, 72, 0.5);
  height: ${units(1.25)};
  position: absolute;
  right: 24px;
  top: 7px;
  width: ${units(1.25)};
`;

/**
 * `Line` styled component.
 */

const Line = styled.div`
  background-color: ${color('gray200')};
  height: ${units(3)};
  margin: 0 -2px;
  width: 2px;
`;

/**
 * `UserLoggedIn` component.
 */

const UserLoggedIn = ({ onLogout, visible }: Props): Node => {
  const { translate } = useTranslate();
  const { email, identityConfirmed, name, pictureUrl } = useSelector(getUser);
  const history = useHistory();
  const handleAccountPage = useCallback(() => {
    return history.push(routes.profile.base);
  }, [history]);

  return (
    <StyledUserCard visible={visible}>
      <UserInformationWrapper>
        <UserImage>
          {pictureUrl && (
            <Image src={pictureUrl} />
          )}
        </UserImage>

        <InformationWrapper>
          <UserName>
            {name}
          </UserName>

          <UserEmail>
            {email}
          </UserEmail>
        </InformationWrapper>
      </UserInformationWrapper>

      <ButtonWrapper>
        <AccountButton onClick={handleAccountPage}>
          {translate('accountMenu.label')}

          {!identityConfirmed && <Circle />}
        </AccountButton>

        <Line />

        <LogoutButton onClick={onLogout}>
          {translate('common.actions.logout')}
        </LogoutButton>
      </ButtonWrapper>
    </StyledUserCard>
  );
};

/**
 * Export `UserLoggedIn` component.
 */

export default UserLoggedIn;
