// @flow

/**
 * Module dependencies.
 */

import {
  Box,
  IconButton,
  Type,
  color,
  media,
  units
} from 'pmint-design-system';

import { getUser } from 'client/core/redux/selectors/user';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { logout, resetLogout } from 'client/core/redux/actions/logout';
import { prop } from 'styled-tools';
import { setBodyScroll } from 'client/core/utils/body-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import UserLoggedIn from './user-logged-in';
import UserLoggedOut from './user-logged-out';
import routes from 'core/routes';
import styled from 'styled-components';
import useClickOutside from 'click-outside-hook';
import userIcon from 'client/assets/svg/user-24px.svg';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

/**
 * `Username` styled component.
 */

const Username = styled(Type.Paragraph)`
  color: ${color('textColor')};
  font-weight: 400;
  margin-right: ${units(2)};
  text-align: right;

  ${media.max('xs')`
    margin-left: ${units(1)};
    margin-right: ${units(1)};
  `}
`;

/**
 * `UserImage` styled component.
 */

const UserImage = styled.button`
  background-image: url(${prop('pictureUrl')});
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  border-radius: 50%;
  box-shadow: 0 4px 4px 0 rgba(105, 122, 137, 0.05), 0 6px 18px 0 rgba(105, 122, 137, 0.1), 0 0 1px 0 rgba(105, 122, 137, 0.5);
  cursor: pointer;
  display: block;
  height: ${units(5)};
  outline: 0;
  padding: 0;
  width: ${units(5)};
`;

/**
 * `UserAccount` component.
 */

const UserAccount = (): Node => {
  const [isCardVisible, setCardVisible] = useState(false);
  const isAuthenticated = useSelector(isUserAuthenticated);
  const dispatch = useDispatch();
  const history = useHistory();
  const { name, pictureUrl } = useSelector(getUser);
  const ref = useClickOutside(() => {
    if (isCardVisible) {
      setCardVisible(false);
    }
  });

  const handleLogout = useCallback(() => {
    setCardVisible(false);

    return dispatch(logout());
  }, [dispatch]);

  const handleSignIn = useCallback(() => {
    setCardVisible(false);
    setBodyScroll(false);

    return history.push(routes.signIn);
  }, [history]);

  useEffect(() => {
    return () => dispatch(resetLogout());
  }, [dispatch]);

  return (
    <Wrapper>
      {isAuthenticated && (
        <Username>
          {name}
        </Username>
      )}

      <Box
        position={'relative'}
        ref={ref}
      >
        {pictureUrl ? (
          <UserImage
            onClick={() => setCardVisible(!isCardVisible)}
            pictureUrl={pictureUrl}
          />
        ) : (
          <IconButton
            colorTheme={'default'}
            icon={userIcon}
            onClick={() => setCardVisible(!isCardVisible)}
          />
        )}

        {isAuthenticated ? (
          <UserLoggedIn
            onLogout={handleLogout}
            visible={isCardVisible}
          />
        ) : (
          <UserLoggedOut
            onSignIn={handleSignIn}
            visible={isCardVisible}
          />
        )}
      </Box>
    </Wrapper>
  );
};

/**
 * Export `UserAccount` component.
 */

export default UserAccount;
