// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { signInWithOptionsType } from 'client/core/redux/action-types/sign-in-with-options';

/**
 * Export `signInWithOptions`.
 */

export default combineReducers(createRequestReducers({
  method: 'POST',
  type: signInWithOptionsType
}));
