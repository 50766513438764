// @flow

/**
 * Module dependencies.
 */

import { sortBy } from 'lodash';
import { useMemo } from 'react';
import useTranslate from 'client/hooks/use-translate';

/**
 * Export `isUSA`.
 */

export const isUSA = (key: string) => key === 'US';

/**
 * Export `useTranslatedOptions`.
 */

export function useTranslatedOptions(key: 'usaStates' | 'countries') {
  const { translate } = useTranslate();

  return useMemo(() => {
    const optionsEntries = translate(key, { returnObjects: true });
    const options = Object.entries(optionsEntries).reduce((acc, [value, label]) => [
      ...acc, { label, value }
    ], []);

    // $FlowFixMe
    return sortBy(options, ['label', 'value']);
  }, [key, translate]);
}
