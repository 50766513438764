// @flow

/**
 * Module dependencies.
 */

import { ifProp } from 'styled-tools';
import { media, units } from 'pmint-design-system';
import BackLink from 'client/components/core/back-link';
import PageLayout from 'client/components/page-layout';
import ProgressBar from 'client/components/core/progress-bar';
import React, { type Node } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  progressBarItems: Array<{
    label: string,
    state: string
  }>,
  to?: string | Object
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(18.5)};
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content auto;
  height: 100%;
  padding-bottom: 0;

  ${ifProp('hasBackLink', css`
    grid-row-gap: ${units(8)};
    grid-template-rows: min-content min-content auto;
  `)};

  ${media.min('md')`
    grid-template-columns: 1fr 6fr 1fr;
    min-height: 100vh;
    padding-bottom: ${units(5.75)};
  `}
`;

/**
 * `StyledProgressBar` styled component.
 */

const StyledProgressBar = styled(ProgressBar)`
  grid-column: 1 / -1;
`;

/**
 * `StyledBackLink` styled component.
 */

const StyledBackLink = styled(BackLink)`
  grid-column: 1 / -1;
  padding-left: 23px;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 2;
  padding-top: ${units(2)};

  ${media.min('xxl')`
    justify-self: center;
    max-width: 721px;
    width: 100%;
  `}
`;

/**
 * `RegisterLayout` component.
 */

const RegisterLayout = (props: Props): Node => {
  const { children, progressBarItems, to } = props;

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <Wrapper hasBackLink={to}>
        <StyledProgressBar items={progressBarItems} />

        {to && <StyledBackLink to={to} />}

        <Content>
          {children}
        </Content>
      </Wrapper>
    </PageLayout>
  );
};

/**
 * Export `RegisterLayout` component.
 */

export default RegisterLayout;
