// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { deleteDocumentType } from 'client/core/redux/action-types/delete-document';
import { normalizeDeletedKycDocument } from 'client/core/utils/normalizer';

/**
 * `Options` type.
 */

type Options = {|
  documentId: string
|};

/**
 * Export `deleteDocument`.
 */

export const deleteDocument = ({ documentId }: Options) => {
  return createRequestAction({
    apiName: 'app',
    endpoint: {
      name: 'deleteDocument',
      params: { id: documentId }
    },
    handleResponse: ({ data }) => normalizeDeletedKycDocument(data),
    meta: {
      auth: { isAuthenticated: true }
    },
    method: 'DELETE',
    type: deleteDocumentType
  });
};

/**
 * Export `resetDeleteDocument`.
 */

export const resetDeleteDocument = createResetRequestAction({
  method: 'DELETE',
  type: deleteDocumentType
});
