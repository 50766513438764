// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getChangeImageState`.
 */

export const getChangeImageState = createRequestStateSelector('changeImage');
