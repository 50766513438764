// @flow

/**
 * Module dependencies.
 */

import { useCallback, useRef } from 'react';

/**
 * `useScroller` hook.
 */

function useScroller(): [Object, () => void] {
  const ref = useRef();
  const scroll = useCallback(() => {
    if (ref.current && ref.current.scrollIntoView) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, []);

  return [ref, scroll];
}

/**
 * Export `useScroller` hook.
 */

export default useScroller;
