// @flow

/**
 * Module dependencies.
 */

import { fetchResetPasswordToken } from 'client/core/redux/actions/fetch-reset-password-token';
import { getFetchResetPasswordTokenState } from 'client/core/redux/selectors/fetch-reset-password-token';
import { resetPassword } from 'client/core/redux/actions/reset-password';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PageLayout from 'client/components/page-layout';
import React, { type Node, useCallback, useEffect } from 'react';
import SetPasswordForm from 'client/components/set-password/set-password-form';
import SetPasswordLayout from 'client/components/set-password/set-password-layout';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import routes from 'core/routes';
import useQueryString from 'client/hooks/use-query-string';
import useTranslate from 'client/hooks/use-translate';

/**
 * `ResetPassword` container.
 */

function ResetPassword(): Node {
  const { translate } = useTranslate();
  const { email, token } = useQueryString();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    data,
    isFailed
  } = useSelector(getFetchResetPasswordTokenState);

  const handleSubmit = useCallback(values => {
    return dispatch(resetPassword({
      ...values,
      ...data
    })).then(() => {
      return history.push(routes.resetPasswordSuccess);
    });
  }, [data, dispatch, history]);

  useEffect(() => {
    if (email && token) {
      dispatch(fetchResetPasswordToken({ email, token }));
    }
  }, [dispatch, email, token]);

  useEffect(() => {
    if (!email || !token) {
      // TODO: Add some error message instead.
      return history.push(routes.home);
    }
  }, [email, history, token]);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <SetPasswordLayout
        description={translate('resetPassword.description')}
        errorMessage={translate('resetPassword.tokenUsed')}
        isFailed={isFailed}
        title={translate('resetPassword.title')}
      >
        <SetPasswordForm onSubmit={handleSubmit} />
      </SetPasswordLayout>
    </PageLayout>
  );
}

/**
 * Export `ResetPassword` container.
 */

export default ResetPassword;
