// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { createScheduleJobType } from 'client/core/redux/action-types/create-schedule-job';

/**
 * `Props` type.
 */

type Props = {|
  callbackUrl: string,
  payload?: string,
  waitingTimeInSeconds: string
|};

/**
 * Export `createScheduleJob`.
 */

export const createScheduleJob = (props: Props) => {
  const {
    callbackUrl,
    payload,
    waitingTimeInSeconds
  } = props;

  return createRequestAction({
    apiName: 'scheduler',
    data: {
      callbackUrl,
      payload,
      waitingTimeInSeconds
    },
    endpoint: 'createJob',
    handleResponse: ({ data }) => data,
    method: 'POST',
    type: createScheduleJobType
  });
};

/**
 * Export `resetCreateScheduleJob`.
 */

export const resetCreateScheduleJob = createResetRequestAction({
  method: 'POST',
  type: createScheduleJobType
});
