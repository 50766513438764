// @flow

/**
 * Module dependencies.
 */

import { Box, Icon, Type, color, media, states, units } from 'pmint-design-system';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { ifProp, theme } from 'styled-tools';
import { matchPath } from 'react-router';
import React, { type Node } from 'react';
import documentIcon from 'client/assets/svg/document-32px.svg';
import fingerprintIcon from 'client/assets/svg/fingerprint-32px.svg';
import profileIcon from 'client/assets/svg/profile-32px.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import successIcon from 'client/assets/svg/success-24px.svg';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  emailConfirmed: boolean,
  identityConfirmed: boolean
|};

/**
 * `MenuItem` styled component.
 */

const MenuItem = styled(RouterLink)`
  border-bottom: 1px solid ${color('gray300')};
  cursor: pointer;
  display: block;
  padding: 0 26px;
  text-decoration: none;
  transition: ${theme('transitions.defaultTransition')};
  transition-property: background-color, border-color;

  ${ifProp('disabled', css`
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${ifProp('active', css`
    border-color: ${color('textColor')};
  `)}

  ${states.interaction`
    background-color: ${color('gray300')};
  `}
`;

/**
 * `MenuItemContent` styled component.
 */

const MenuItemContent = styled.div`
  align-items: center;
  display: flex;
  min-height: 73px;
  padding: 0;

  ${media.min('xs')`
    padding: 0 42px;
  `}

  ${media.min('md')`
    padding: 0;
  `}

  ${media.min('lg')`
    padding: 0 42px;
  `}
`;

/**
 * `KycCircle` styled component.
 */

const KycCircle = styled.span`
  background-color: ${color('warning')};
  border-radius: 50%;
  box-shadow: 0 1px 1px 0 rgba(113, 128, 150, 0.2), 0 2px 4px 0 rgba(255, 190, 72, 0.5);
  height: ${units(1.25)};
  left: ${units(2.5)};
  position: absolute;
  width: ${units(1.25)};
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  flex-shrink: 0;
`;

/**
 * `AccountMenuContent` component.
 */

const AccountMenuContent = (props: Props): Node => {
  const { emailConfirmed, identityConfirmed } = props;
  const { translate } = useTranslate();
  const location = useLocation();
  const menuItems = [{
    icon: profileIcon,
    id: 'profile',
    label: translate('accountMenu.menu.profile'),
    to: routes.profile.base
  }, {
    icon: fingerprintIcon,
    id: 'kyc',
    label: translate('accountMenu.menu.kyc'),
    to: routes.kyc
  }, {
    icon: documentIcon,
    id: 'documents',
    label: translate('accountMenu.menu.documents'),
    to: routes.documents
  }];

  return menuItems.map(({ icon, id, label, to }) => {
    const isActive = matchPath(location.pathname, {
      path: to
    });

    const isDisabled = id === 'profile' && !emailConfirmed;

    return (
      <MenuItem
        active={isActive}
        aria-current={isActive ? 'page' : null}
        aria-disabled={isDisabled}
        disabled={isDisabled}
        key={id}
        to={isDisabled ? null : to}
      >
        <MenuItemContent>
          {id === 'kyc' && !identityConfirmed ? (
            <Box position={'relative'}>
              <Icon
                color={isActive ? color('textColor') : color('textColorLight')}
                icon={icon}
                size={units(4)}
              />

              <KycCircle aria-label={translate('accountMenu.identityConfirmationPending')} />
            </Box>
          ) : (
            <StyledIcon
              color={isActive ? color('textColor') : color('textColorLight')}
              icon={icon}
              size={units(4)}
            />
          )}

          <Type.Label
            as={'h3'}
            color={isActive ? color('textColor') : color('textColorLight')}
            flex={1}
            fontWeight={400}
            letterSpacing={0}
            marginLeft={'10px'}
            marginRight={'10px'}
          >
            {label}
          </Type.Label>

          {id === 'kyc' && identityConfirmed && (
            <Icon
              aria-label={translate('accountMenu.identityConfirmed')}
              block
              color={color('bender1')}
              icon={successIcon}
              size={units(3)}
            />
          )}
        </MenuItemContent>
      </MenuItem>
    );
  });
};

/**
 * Export `AccountMenuContent` component.
 */

export default AccountMenuContent;
