// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `setPasswordType`.
 */

export const setPasswordType = 'SET_PASSWORD';

/**
 * Export `setPasswordTypes`.
 */

export const setPasswordTypes = createRequestActionTypes({
  method: 'PATCH',
  type: setPasswordType
});
