// @flow

/**
 * Module dependencies.
 */

import { Icon, Link, color, media, units } from 'pmint-design-system';
import { Route, Switch } from 'react-router-dom';
import Footer from 'client/components/footer';
import React, { type Node } from 'react';
import logoIcon from 'client/assets/svg/logo.svg';
import routes from 'core/routes';
import styled, { css } from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.header`
  background-color: ${color('gray200')};
  display: grid;
  grid-area: sidebar;
  grid-row-gap: ${units(8)};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: min-content auto;
  padding-bottom: ${units(6)};

  ${media.min('md')`
    grid-row-gap: 0;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: min-content auto min-content;
    overflow: hidden;
    padding-bottom: 0;
  `}
`;

/**
 * Logo style.
 */

const logoStyle = css`
  grid-column: 1 / -1;
  padding-left: ${units(3)};
  padding-top: 18px;
  z-index: 100;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  cursor: pointer;

  ${logoStyle}
`;

/**
 * `IconWrapper` styled component.
 */

const IconWrapper = styled.div`
  ${logoStyle}
`;

/**
 * `TextWrapperMobile` styled component.
 */

const TextWrapperMobile = styled.div`
  align-self: center;
  display: block;
  grid-column: 2 / -2;

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `CirclesWrapper` styled component.
 */

const CirclesWrapper = styled.div`
  align-self: center;
  bottom: 37px;
  display: none;
  grid-column: 1 / -1;
  justify-self: end;
  position: relative;
  right: -33px;

  ${media.min('md')`
    display: block;
  `}
`;

/**
 * Circles style.
 */

const circlesStyle = css`
  background: linear-gradient(139.27deg, #EDF2F7 0%, #DDE5EC 100%);
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.15);
`;

/**
 * `FourthCircle` styled component.
 */

const FourthCircle = styled.div`
  border-radius: 50%;
  bottom: -539px;
  height: 1751px;
  opacity: 0.8;
  position: absolute;
  right: -348px;
  width: 1751px;

  ${circlesStyle}
`;

/**
 * `ThirdCircle` styled component.
 */

const ThirdCircle = styled.div`
  border-radius: 50%;
  bottom: -330px;
  height: 1166px;
  opacity: 0.8;
  position: absolute;
  right: -${units(26.5)};
  width: 1167px;

  ${circlesStyle}
`;

/**
 * `SecondCircle` styled component.
 */

const SecondCircle = styled.div`
  border-radius: 50%;
  bottom: -145px;
  height: 850px;
  opacity: 0.8;
  position: absolute;
  right: -87px;
  width: ${units(106)};

  ${circlesStyle}
`;

/**
 * `FirstCircle` styled component.
 */

const FirstCircle = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 575px;
  justify-content: flex-end;
  opacity: 0.8;
  position: relative;
  width: 575px;

  ${circlesStyle}
`;

/**
 * `TextWrapperDesktop` styled component.
 */

const TextWrapperDesktop = styled.div`
  max-width: 290px;
  position: relative;
  right: 54px;

  ${media.min('lg')`
    right: 94px;
  `}

  ${media.min('xl')`
    max-width: ${units(42.5)};
  `}
`;

/**
 * `StyledFooter` styled component.
 */

const StyledFooter = styled(Footer)`
  grid-column: 1 / -1;
  position: relative;

  ${media.max('md')`
    display: none;
  `}
`;

/**
 * `SidebarWithCircles` component.
 */

const SidebarWithCircles = ({ children }: Props): Node => {
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Switch>
        <Route
          path={[
            routes.register.base,
            routes.setPassword,
            routes.signIn.base
          ]}
        >
          <IconWrapper>
            <Icon
              aria-label={translate('sidebar.logoLabel')}
              color={color('bender6')}
              icon={logoIcon}
              size={units(15.75)}
            />
          </IconWrapper>
        </Route>

        <Route path={'*'}>
          <StyledLink to={routes.home}>
            <Icon
              aria-label={translate('sidebar.logoLabel')}
              color={color('bender6')}
              icon={logoIcon}
              size={units(15.75)}
            />
          </StyledLink>
        </Route>
      </Switch>

      <TextWrapperMobile>
        {children}
      </TextWrapperMobile>

      <CirclesWrapper>
        <FourthCircle />

        <ThirdCircle />

        <SecondCircle />

        <FirstCircle>
          <TextWrapperDesktop>
            {children}
          </TextWrapperDesktop>
        </FirstCircle>
      </CirclesWrapper>

      <StyledFooter />
    </Wrapper>
  );
};

/**
 * Export `SidebarWithCircles` component.
 */

export default SidebarWithCircles;
