// @flow

/**
 * Module dependencies.
 */

import { FormErrorsBasePathContext } from './form-errors-base-path-context';
import { FormProvider } from '@seegno/react-forms';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  className?: string,
  errorsBasePath: string,
  initialValues?: Object,
  jsonSchema: Object,
  onSubmit: (values: Object, actions: Object) => ?Promise<any>,
  validationOptions?: Object
|};

/**
 * `Form` component.
 */

const Form = (props: Props): Node => {
  const {
    children,
    errorsBasePath,
    initialValues,
    jsonSchema,
    onSubmit,
    validationOptions,
    ...rest
  } = props;

  return (
    <FormErrorsBasePathContext.Provider value={errorsBasePath}>
      <FormProvider
        initialValues={initialValues}
        jsonSchema={jsonSchema}
        onSubmit={onSubmit}
        validationOptions={validationOptions}
      >
        {({ submit }) => (
          <form
            {...rest}
            onSubmit={submit}
          >
            {children}
          </form>
        )}
      </FormProvider>
    </FormErrorsBasePathContext.Provider>
  );
};

/**
 * Export `Form` component.
 */

export default Form;
