// @flow

/**
 * Module dependencies.
 */

import { camelCase, isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import config from 'config';
import firebase from 'client/core/utils/analytics';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Analytics` container.
 */

const Analytics = () => {
  const { i18n, translate } = useTranslate();
  const location = useLocation();
  const path = location.pathname;

  useEffect(() => {
    if (config.analytics.apiKey) {
      const title = path
        .split('/')
        .map(path => {
          if (isEmpty(path)) {
            return;
          }

          const translationKey = `analytics.${camelCase(path)}`;

          if (i18n.exists(translationKey)) {
            return translate(translationKey);
          }
        })
        .filter(path => !isEmpty(path))
        .join(' - ');

      firebase.analytics().logEvent('page_view', {
        page_path: path,
        page_title: title
      });
    }
  }, [i18n, path, translate]);

  return null;
};

/**
 * Export `Analytics` container.
 */

export default Analytics;
