// @flow

/**
 * Module dependencies.
 */

import { createReactPortal } from 'client/core/utils/portals';
import { ifProp, theme } from 'styled-tools';
import { setBodyScroll } from 'client/core/utils/body-scroll';
import React, { type Node, useCallback, useEffect } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  closeIcon?: string,
  isOpen: boolean
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  transition: visibility ${theme('animations.styles.defaultTransition')};
  visibility: ${ifProp('isVisible', 'visible', 'hidden')};
`;

/**
 * `ModalPortal` component.
 */

const ModalPortal = (props: Props): Node => {
  const { children, closeIcon, isOpen } = props;
  const handleGhostClick = useCallback(event => {
    if (event) {
      event.stopPropagation();
    }
  }, []);

  useEffect(() => {
    setBodyScroll(!isOpen);
  }, [isOpen]);

  useEffect(() => () => {
    if (isOpen) {
      return setBodyScroll(true);
    }
  }, [isOpen]);

  return createReactPortal((
    <Wrapper
      closeIcon={closeIcon}
      isVisible={isOpen}
      onClick={handleGhostClick}
    >
      {children}
    </Wrapper>
  ), 'modal-root');
};

/**
 * Export `ModalPortal` component.
 */

export default ModalPortal;
