// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { fetchAuthProvidersType } from 'client/core/redux/action-types/fetch-auth-providers';
import { normalizeAuthProviders } from 'client/core/utils/normalizer';

/**
 * Export `fetchAuthProviders`.
 */

export const fetchAuthProviders = () => {
  return createRequestAction({
    apiName: 'app',
    endpoint: 'authProviders',
    handleResponse: ({ data }) => normalizeAuthProviders(data),
    type: fetchAuthProvidersType
  });
};

/**
 * Export `resetFetchAuthProviders`.
 */

export const resetFetchAuthProviders = createResetRequestAction({
  type: fetchAuthProvidersType
});
