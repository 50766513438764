// @flow

/**
 * Module dependencies.
 */

import { Card, Type, color, media, units } from 'pmint-design-system';
import { Polling } from 'client/core/utils/polling';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { getFetchAccountState } from 'client/core/redux/selectors/fetch-account';
import { getIdentityState } from 'client/core/utils/user-state';
import { getUser } from 'client/core/redux/selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import AccountPollingItem from 'client/components/core/account-polling-item';
import PageContent from 'client/components/page-layout/page-content';
import PageLayout from 'client/components/page-layout';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useEffect, useState } from 'react';
import SidebarContent from 'client/components/page-layout/sidebar-content';
import emailIcon from 'client/assets/svg/email-96px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';
import userIcon from 'client/assets/svg/user-96px.svg';

/**
 * `StyledCard` styled component.
 */

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: ${units(5)} ${units(4)};

  ${media.min('xs')`
    flex-direction: row;
  `}

  ${media.min('md')`
    margin-bottom: ${units(5)};
  `}
`;

/**
 * `PollingSeparator` styled component.
 */

const PollingSeparator = styled.div`
  border-top: 1px dashed ${color('gray400')};
  flex: 0 0 0%;
  margin: ${units(5)} 0;

  ${media.min('xs')`
    border-left: 1px dashed ${color('gray400')};
    border-top: 0;
    margin: 0 ${units(4)};
  `}
`;

/**
 * `SetPasswordSuccess` container.
 */

const SetPasswordSuccess = (): Node => {
  const dispatch = useDispatch();
  const fetchAccountState = useSelector(getFetchAccountState);
  const user = useSelector(getUser);
  const state = getIdentityState(user, fetchAccountState);
  const [identityState, setIdentityState] = useState(state);
  const { translate } = useTranslate();

  useEffect(() => {
    const polling = new Polling(retry => {
      dispatch(fetchAccount())
        .then(({ value }) => {
          if (!value.identityConfirmed) {
            return retry();
          }
        });
    });

    polling.start();

    return () => {
      polling.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    return setIdentityState(state);
  }, [state]);

  useEffect(() => {
    if (identityState === 'pending') {
      const timeout = setTimeout(() => {
        return setIdentityState('warning');
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [identityState]);

  return (
    <PageLayout sidebarContent={<SidebarContent />}>
      <PageContent>
        <Type.H4
          as={'h2'}
          color={color('bender2')}
          marginBottom={units(2)}
        >
          {translate('setPasswordSuccess.title')}
        </Type.H4>

        <RawHtml
          as={'p'}
          color={color('textColor')}
          element={Type.Label}
          fontWeight={400}
          letterSpacing={0}
          marginBottom={units(6)}
          marginBottomMd={units(8)}
        >
          {translate(`setPasswordSuccess.description.${identityState === 'error' ? 'pending' : identityState}`)}
        </RawHtml>

        <StyledCard>
          <AccountPollingItem
            icon={emailIcon}
            state={'verified'}
          >
            {translate('setPasswordSuccess.email.verified')}
          </AccountPollingItem>

          <PollingSeparator />

          <AccountPollingItem
            icon={userIcon}
            state={identityState}
          >
            {translate(`setPasswordSuccess.identity.${identityState}`)}
          </AccountPollingItem>
        </StyledCard>
      </PageContent>
    </PageLayout>
  );
};

/**
 * Export `SetPasswordSuccess` container.
 */

export default SetPasswordSuccess;
