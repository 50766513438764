// @flow

/**
 * Export `addImageFileType`.
 */

export const addImageFileType = 'ADD_IMAGE_FILE';

/**
 * Export `addUploadFileProgressType`.
 */

export const addUploadFileProgressType = 'ADD_UPLOAD_FILE_PROGRESS';

/**
 * Export `addUploadedFileType`.
 */

export const addUploadedFileType = 'ADD_UPLOADED_FILE';

/**
 * Export `addUploadErrorsType`.
 */

export const addUploadErrorsType = 'ADD_UPLOAD_ERRORS';

/**
 * Export `resetUploadImageType`.
 */

export const resetUploadImageType = 'RESET_UPLOAD_IMAGE';
