// @flow

/**
 * Export `authProviders`.
 */

export const authProviders = {
  apple: 'apple',
  dropbox: 'dropbox',
  google: 'google',
  microsoft: 'microsoft'
};
