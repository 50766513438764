// @flow

/**
 * Module dependencies.
 */

import 'core/clients/sentry-browser-client';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider, withSSR as withI18nSSR } from 'react-i18next';
import { Provider } from 'react-redux';
import { cookiesStorage } from 'client/core/utils/cookies';
import { getAuthInitialState } from 'core/utils/store';
import { i18n } from 'client/core/localization/i18n';
import { initializeAnalytics } from 'client/core/utils/analytics';
import { render } from 'react-dom';
import { storageKeys } from 'core/utils/storage-keys';
import { unescape } from 'lodash';
import React from 'react';
import Root from 'client/containers/root';
import config from 'config';
import configureStore from 'client/core/redux/store';

/**
 * Base path.
 */

const basePath = config.get('basePath');

/**
 * Initial localization data.
 */

const {
  i18nStore,
  language
} = JSON.parse(unescape(window._initialLocalizationData).replace(/\s/g, ' ')) || {}; // eslint-disable-line no-underscore-dangle

/**
 * Initial state.
 */

const initialState = JSON.parse(unescape(window._initialState).replace(/\s/g, ' ')) || {}; // eslint-disable-line no-underscore-dangle

/**
 * Remove global references.
 */

delete window._initialLocalizationData; // eslint-disable-line no-underscore-dangle
delete window._initialState; // eslint-disable-line no-underscore-dangle

/**
 * Token.
 */

const token = cookiesStorage.get(storageKeys.token);

/**
 * Store.
 *
 * TODO: Set `authentication.status` as `'unknown'` and validate the token.
 * Only set to `'authenticated'` if the token is valid.
 */

const store = configureStore({
  ...initialState,
  ...getAuthInitialState(token)
});

/**
 * Initialize analytics.
 */

initializeAnalytics();

/**
 * Root with i18n SSR.
 */

const RootWithI18nSSR = withI18nSSR()(Root);

/**
 * Render.
 */

render((
  <I18nextProvider i18n={i18n}>
    <BrowserRouter basename={basePath}>
      <Provider store={store}>
        <RootWithI18nSSR
          initialI18nStore={i18nStore}
          initialLanguage={language}
        />
      </Provider>
    </BrowserRouter>
  </I18nextProvider>
  // $FlowFixMe
), document.getElementById('root'));
