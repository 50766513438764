// @flow

/**
 * Module dependencies.
 */

import { Type, color, media, units } from 'pmint-design-system';
import BackLink from 'client/components/core/back-link';
import ChangePasswordForm from 'client/components/profile/change-password/change-password-form';
import Header from 'client/components/core/header';
import React, { type Node } from 'react';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  icon: string,
  onLogout: () => void,
  title: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  grid-template-rows: min-content min-content auto;

  ${media.min('md')`
    grid-template-columns: 2fr 4fr 2fr;
    min-height: 100vh;
  `}
`;

/**
 * `BackLinkWrapper` styled component.
 */

const BackLinkWrapper = styled.div`
  grid-column: 1 / -1;
  justify-self: start;
  margin-left: ${units(3)};
  margin-top: 3px;
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  grid-column: 2;
  margin-top: 30px;

  ${media.min('xxl')`
    justify-self: center;
    max-width: 481px;
    width: 100%;
  `}
`;

/**
 * `ChangePassword` container.
 */

const ChangePassword = (props: Props): Node => {
  const { icon, onLogout, title } = props;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <Header
        icon={icon}
        onLogout={onLogout}
        title={title}
      />

      <BackLinkWrapper>
        <BackLink to={routes.profile.base} />
      </BackLinkWrapper>

      <ContentWrapper>
        <Type.Label
          as={'h2'}
          color={color('textColor')}
          fontWeight={500}
          marginBottom={'29px'}
        >
          {translate('profile.changePassword.title')}
        </Type.Label>

        <ChangePasswordForm />
      </ContentWrapper>
    </Wrapper>
  );
};

/**
 * Export `ChangePassword` container.
 */

export default ChangePassword;
