// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getRemoveAccountState`.
 */

export const getRemoveAccountState = createRequestStateSelector('removeAccount');
