// @flow

/**
 * Module dependencies.
 */

import {
  type AuthProviderSignInValues,
  signInWithOptions
} from 'client/core/redux/actions/sign-in-with-options';

import { type Dispatch } from 'react-redux';
import { addUser } from './user';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { isNil } from 'lodash';
import { saveUserSession } from 'client/core/utils/cookies';
import { setAuthenticated } from 'client/core/redux/actions/set-authenticated';
import { signIn } from 'client/core/redux/actions/sign-in';

/**
 * `SignInValues` type.
 */

type SignInValues = {|
  email: string,
  password: string
|};

/**
 * Export `performSignIn`.
 */

export const performSignIn = (values: SignInValues) => async (dispatch: Dispatch) => {
  const { value: { token } } = await dispatch(signIn(values));
  const { value: user } = await dispatch(fetchAccount());

  saveUserSession({
    identityProvider: 'pmint',
    token,
    user
  });

  return await dispatch(setAuthenticated());
};

/**
 * Export `performProviderSignIn`.
 */

export const performProviderSignIn = (values: AuthProviderSignInValues) => {
  return async (dispatch: Dispatch) => {
    try {
      const { value: { token } } = await dispatch(signInWithOptions(values));
      const { value: user } = await dispatch(fetchAccount());

      if (isNil(user.kycStatus)) {
        await dispatch(addUser(user));
      }

      saveUserSession({
        identityProvider: values.identityProvider,
        token,
        user
      });

      return await dispatch(setAuthenticated());
    } catch (error) {
      return Promise.reject({ error });
    }
  };
};
