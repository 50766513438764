// @flow

/**
 * Module dependencies.
 */

import { Icon, color, states, units } from 'pmint-design-system';
import { ifProp, theme } from 'styled-tools';
import React, { type Node } from 'react';
import shapeIcon from 'client/assets/svg/shape.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  children: Node
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('gray300')};
  border-radius: ${units(1)};
  box-shadow: 0 2px 2px 0 rgba(105, 122, 137, 0.05), 0 5px 15px 0 rgba(105, 122, 137, 0.1);
  outline: 0;
  position: absolute;
  right: 0;
  top: calc(100% + ${units(1.5)});
  transition:
    background-color ${theme('transitions.defaultTransition')},
    opacity ${theme('transitions.fastTransition')},
    visibility ${theme('transitions.fastTransition')};

  z-index: ${theme('zIndex.userCard')};

  svg {
    fill: ${color('white')};
    transition: fill ${theme('transitions.defaultTransition')};
  }

  ${ifProp('isClickable', css`
    ${states.interaction`
      background-color: ${color('gray200')};

      svg {
        fill: ${color('gray200')};
      }
    `}
  `)}

  ${ifProp('visible', css`
    opacity: 1;
    visibility: visible;
  `, css`
    opacity: 0;
    visibility: hidden;
  `)}
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon)`
  position: absolute;
  right: ${units(2)};
  top: -10px;
`;

/**
 * `UserCard` component.
 */

const UserCard = (props: Props): Node => {
  const { children, ...rest } = props;

  return (
    <Wrapper {...rest}>
      <StyledIcon
        color={color('gray300')}
        icon={shapeIcon}
        size={'19px'}
      />

      {children}
    </Wrapper>
  );
};

/**
 * Export `UserCard` component.
 */

export default UserCard;
