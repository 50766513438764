// @flow

/**
 * Module dependencies.
 */

import { Modal, media, units } from 'pmint-design-system';
import { performChangeImage } from 'client/core/redux/actions/perform-change-image';
import { useDispatch } from 'react-redux';
import ChangeImageModalContent from './change-image-modal-content';
import ChangeImageModalHeader from './change-image-modal-header';
import Form from 'client/components/core/forms/form';
import React, { type Node, useCallback } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import changeImageSchema from 'client/components/profile/change-image/change-image-schema';
import config from 'config';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * Initial values.
 */

const initialValues = {
  file: null
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  padding: ${units(5)} ${units(2.5)};
  position: relative;

  ${media.min('xs')`
    padding: ${units(10)} ${units(7.5)};
  `}

  ${media.min('lg')`
    padding: ${units(10)} ${units(15)};
  `}
`;

/**
 * `SubmitButtonWrapper` styled component.
 */

const SubmitButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${units(5)};
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  flex: 1 1 50%;

  ${media.min('sm')`
    flex-grow: 0;
  `}
`;

/**
 * `ChangeImageModal` component.
 */

const ChangeImageModal = (props: Props): Node => {
  const { isVisible, onRequestClose } = props;
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(file => {
    return dispatch(performChangeImage(file)).then(() => {
      onRequestClose();
    });
  }, [dispatch, onRequestClose]);

  return (
    <Modal isVisible={isVisible}>
      <Wrapper>
        <Form
          errorsBasePath={'profile.changeImage.errors.form'}
          initialValues={initialValues}
          jsonSchema={changeImageSchema}
          onSubmit={handleSubmit}
        >
          <ChangeImageModalHeader onRequestClose={onRequestClose} />

          <ChangeImageModalContent
            isModalVisible={isVisible}
            maxFileSize={config.get('fileSizes.userImage')}
            name={'file'}
          />

          <SubmitButtonWrapper>
            <StyledSubmitButton fullWidth>
              {translate('common.actions.submit')}
            </StyledSubmitButton>
          </SubmitButtonWrapper>
        </Form>
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `ChangeImageModal` component.
 */

export default ChangeImageModal;
