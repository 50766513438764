// @flow

/**
 * Export `storageKeys`.
 */

export const storageKeys = {
  previousSession: 'previous-user-data',
  refreshToken: 'session-refresh-token',
  revokeToken: 'session-revoke-token',
  token: 'session-token',
  user: 'user'
};
