// @flow

/**
 * Module dependencies.
 */

import { Button, Type, color, media, units } from 'pmint-design-system';
import { Polling } from 'client/core/utils/polling';
import { fetchAccount } from 'client/core/redux/actions/fetch-account';
import { getEmailState, getIdentityState } from 'client/core/utils/user-state';
import { getFetchAccountState } from 'client/core/redux/selectors/fetch-account';
import { getUser } from 'client/core/redux/selectors/user';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AccountPollingItem from 'client/components/core/account-polling-item';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import arrowIcon from 'client/assets/svg/arrow.svg';
import emailIcon from 'client/assets/svg/email-96px.svg';
import routes from 'core/routes';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';
import userIcon from 'client/assets/svg/user-96px.svg';

/**
 * `PollingWrapper` styled component.
 */

const PollingWrapper = styled.div`
  background-color: ${color('gray100')};
  border-radius: ${units(1)};
  display: flex;
  flex-direction: column;
  margin-bottom: 57px;
  padding: ${units(7.5)} ${units(4)};

  ${media.min('xs')`
    flex-direction: row;
  `}
`;

/**
 * `PollingSeparator` styled component.
 */

const PollingSeparator = styled.div`
  border-top: 1px dashed ${color('gray400')};
  flex: 0 0 0%;
  margin: ${units(5)} 0;

  ${media.min('xs')`
    border-top: 0;
    border-left: 1px dashed ${color('gray400')};
    margin: 0 ${units(4)};
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  flex: 1 1 ${units(30)};

  ${media.min('xs')`
    flex-grow: 0;
  `}
`;

/**
 * `AccountPolling` component.
 */

function AccountPolling(): Node {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const fetchAccountState = useSelector(getFetchAccountState);
  const user = useSelector(getUser);
  const userEmailState = getEmailState(user, fetchAccountState);
  const userIdentityState = getIdentityState(user, fetchAccountState);
  const [userEmail] = useState(user?.email);
  const [emailState, setEmailState] = useState(userEmailState);
  const [identityState, setIdentityState] = useState(userIdentityState);
  const history = useHistory();
  const handleProfilePage = useCallback(() => {
    return history.push(routes.profile.base);
  }, [history]);

  useEffect(() => {
    if (emailState !== 'verified') {
      setEmailState(() => getEmailState(user, fetchAccountState));
    }
  }, [emailState, fetchAccountState, user]);

  useEffect(() => {
    if (identityState === 'pending') {
      setIdentityState(() => getIdentityState(user, fetchAccountState));
    }
  }, [fetchAccountState, identityState, user]);

  useEffect(() => {
    const polling = new Polling(retry => {
      dispatch(fetchAccount())
        .then(({ value }) => {
          if (!value.identityConfirmed) {
            retry();
          }
        });
    });

    polling.start();

    return () => {
      polling.cancel();
    };
  }, [dispatch]);

  useEffect(() => {
    if (emailState === 'verified' && identityState === 'pending') {
      const timeout = setTimeout(() => {
        return setIdentityState('warning');
      }, 15000);

      return () => clearTimeout(timeout);
    }
  }, [emailState, identityState]);

  return (
    <>
      <RawHtml
        element={Type.Paragraph}
        marginBottom={'53px'}
      >
        {translate('kyc.accountPolling.description', {
          email: userEmail || translate('kyc.accountPolling.yourEmail')
        })}
      </RawHtml>

      <PollingWrapper>
        <AccountPollingItem
          icon={emailIcon}
          state={emailState}
        >
          {translate(`kyc.accountPolling.email.${emailState}`)}
        </AccountPollingItem>

        <PollingSeparator />

        <AccountPollingItem
          icon={userIcon}
          state={identityState}
        >
          {translate(`kyc.accountPolling.identity.${identityState}`)}
        </AccountPollingItem>
      </PollingWrapper>

      <ButtonWrapper>
        <StyledButton
          disabled={emailState !== 'verified' || identityState === 'pending'}
          fullWidth
          icon={arrowIcon}
          onClick={handleProfilePage}
        >
          {translate('common.actions.next')}
        </StyledButton>
      </ButtonWrapper>
    </>
  );
}

/**
 * Export `AccountPolling` component.
 */

export default AccountPolling;
