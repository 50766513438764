// @flow

/**
 * Module dependencies.
 */

import { formats, keywords, schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  identity: {
    birthday,
    gender,
    name,
    phoneNumber: phone
  }
} = schemaValidations;

/**
 * Export `identityInformationSchema`.
 */

export const identityInformationSchema = {
  additionalProperties: false,
  properties: {
    agreeTermsPrivacy: {
      const: true,
      type: 'boolean'
    },
    birthday,
    email: {
      exists: true,
      format: 'email',
      type: 'string'
    },
    gender,
    name,
    phone
  },
  required: ['agreeTermsPrivacy', 'birthday', 'email', 'gender', 'name', 'phone'],
  type: 'object'
};

/**
 * Formats.
 */

const { phoneNumber } = formats;

/**
 * Keywords.
 */

const { isPastDate, isValidAge } = keywords;

/**
 * Export `validationOptions`.
 */

export const validationOptions = (emailExists: boolean) => {
  return {
    formats: { phoneNumber },
    keywords: {
      exists: {
        type: 'string',
        validate: () => !emailExists
      },
      isPastDate,
      isValidAge
    }
  };
};
