// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import { createScheduleJob } from 'client/core/redux/actions/create-schedule-job';
import { fetchDeposit } from 'client/core/redux/actions/fetch-deposit';
import { updateDeposit } from 'client/core/redux/actions/update-deposit';
import config from 'config';

/**
 * `Values` type.
 */

type Values = {|
  followUpToken: string
|};

/**
 * Export `performDepositExecutedFollowUp`.
 */

export const performDepositExecutedFollowUp = (values: Values) => (dispatch: Dispatch) => {
  const { followUpToken } = values;
  const {
    callbackUrl,
    deposits: {
      primeTrustFollowUp: {
        action,
        waitingTimeInSeconds
      }
    }
  } = config.get('scheduler');

  return dispatch(fetchDeposit({ followUpToken }))
    .then(({ value }) => {
      const { id, providerFollowUp } = value;

      if (providerFollowUp) {
        return;
      }

      const payload = {
        action,
        resource: { date: new Date(), id }
      };

      return dispatch(createScheduleJob({
        callbackUrl,
        payload: JSON.stringify(payload),
        waitingTimeInSeconds
      })).then(() => {
        return dispatch(updateDeposit({
          followUpToken,
          patchData: {
            isFollowUpHandled: true,
            providerFollowUp: true
          }
        }));
      });
    });
};
