// @flow

/**
 * Module dependencies.
 */

import { getNetworkErrorPaths } from 'client/core/utils/errors';
import { useSnackbar } from 'client/containers/core/snackbar/snackbar-context';
import useTranslate from './use-translate';

/**
 * `useNetworkErrorMesssage` hook.
 */

const useNetworkErrorMesssage = (basePath: string, errors: Object) => {
  const { translate } = useTranslate();
  const { showErrorMessage } = useSnackbar();

  if (errors) {
    const errorMessage = translate(getNetworkErrorPaths(basePath, errors.code));

    return showErrorMessage(errorMessage);
  }
};

/**
 * Export `useNetworkErrorMesssage` hook.
 */

export default useNetworkErrorMesssage;
