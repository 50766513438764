// @flow

/**
 * Module dependencies.
 */

import type { User } from 'client/core/types/user';
import { addUserNameAndImageType } from 'client/core/redux/action-types/user';
import { fetchAccountTypes } from 'client/core/redux/action-types/fetch-account';

/**
 * User.
 */

function user(state: User = {}, action: Object = {}): User {
  const { payload, type } = action;

  switch (type) {
    case fetchAccountTypes.successType:
      return {
        ...state,
        ...payload
      };

    case addUserNameAndImageType:
      return {
        ...state,
        name: payload.user.name,
        pictureUrl: payload.user.pictureUrl
      };

    default:
      return state;
  }
}

/**
 * Export `user`.
 */

export default user;
