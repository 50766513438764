// @flow

/**
 * Module dependencies.
 */

import 'firebase/analytics';
import { pick } from 'lodash';
import config from 'config';
import firebase from 'firebase/app';

/**
 * Export `initializeAnalytics`.
 */

export const initializeAnalytics = () => {
  if (!config.analytics.apiKey) {
    return;
  }

  firebase.initializeApp(pick(config.analytics, [
    'apiKey',
    'appId',
    'authDomain',
    'databaseURL',
    'measurementId',
    'messagingSenderId',
    'projectId',
    'storageBucket'
  ]));
};

/**
 * Export `firebase`.
 */

export default firebase;
