// @flow

/**
 * Export `recoverPasswordSchema`.
 */

export default {
  additionalProperties: false,
  properties: {
    email: {
      format: 'email',
      type: 'string'
    }
  },
  required: ['email'],
  type: 'object'
};
