// @flow

/**
 * Module dependencies.
 */

import type { Dispatch, State } from 'redux';
import type { UploadFile } from 'client/core/redux/types/upload-file';
import {
  addImageFileType,
  addUploadErrorsType,
  addUploadFileProgressType,
  addUploadedFileType,
  resetUploadImageType
} from 'client/core/redux/action-types/upload-image';

import { clamp } from 'lodash';
import { getAuthenticationToken } from 'client/core/redux/selectors/authentication';
import { isSessionExpiredError } from 'client/core/utils/errors';
import { refreshToken } from './refresh-token';
import { resolve } from 'url';
import axios from 'axios';
import config from 'config';

/**
 * Add image file.
 */

function addImageFile(file: File) {
  return {
    payload: { file },
    type: addImageFileType
  };
}

/**
 * Add upload file progress.
 */

function addUploadFileProgress(progress: number) {
  return {
    payload: { progress },
    type: addUploadFileProgressType
  };
}

/**
 * Add uploaded file.
 */

function addUploadedFile(fields: Object) {
  return {
    payload: { fields },
    type: addUploadedFileType
  };
}

/**
 * Add upload errors.
 */

function addUploadErrors(errors: Object) {
  return {
    payload: { errors },
    type: addUploadErrorsType
  };
}

/**
 * Export `resetUploadImage`.
 */

export function resetUploadImage() {
  return {
    type: resetUploadImageType
  };
}

/**
 * Presign url endpoint.
 */

const presignUrlEndpoint = '/me/profile-picture/presign-url';

/**
 * Presign url.
 */

const presignUrl = (fileName: string, token: string) => async (dispatch: Dispatch) => {
  try {
    const data = await axios.get(resolve(config.get('api.app.baseUrl'), presignUrlEndpoint), {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        filename: fileName
      }
    });

    return data.data;
  } catch (error) {
    if (isSessionExpiredError(error)) {
      return dispatch(refreshToken());
    }

    return dispatch(addUploadErrors(error?.response?.data ?? error));
  }
};

/**
 * Upload file.
 */

const uploadFile = (options: UploadFile) => async (dispatch: Dispatch) => {
  const { fields, file, url } = options;
  const formData = new FormData();
  const cancelToken = axios.CancelToken.source();

  Object.entries(fields).map(([key, value]) => {
    // $FlowFixMe
    formData.append(key, value);
  });

  formData.append('file', file);

  try {
    await axios
      .post(url, formData, {
        cancelToken: cancelToken.token,
        onUploadProgress: ({ loaded, total }) => {
          return dispatch(addUploadFileProgress(clamp(loaded / total, 0, 1)));
        }
      });

    dispatch(addUploadedFile(fields));

    return () => {
      cancelToken.cancel();
    };
  } catch (error) {
    return dispatch(addUploadErrors(error?.response?.data ?? error));
  }
};

/**
 * `UploadImage` type.
 */

type UploadImage = {|
  file: File
|};

/**
 * Export `uploadImage`.
 */

export const uploadImage = ({ file }: UploadImage) => async (dispatch: Dispatch, getState: State) => {
  const token = getAuthenticationToken(getState());

  if (!token) {
    return dispatch({
      payload: Promise.reject(new Error('Missing authentication token'))
    });
  }

  dispatch(addImageFile(file));

  const { data } = await dispatch(presignUrl(file.name, token));

  if (data) {
    const { fields, url } = data;

    await dispatch(uploadFile({ fields, file, url }));
  }
};
