// @flow

/**
 * Module dependencies.
 */

import type { AppleLoginResponse } from 'client/core/types/auth-provider';
import { Modal } from 'pmint-design-system';
import { camelCase } from 'lodash';
import AppleLogin from 'react-apple-login';
import ModalPortal from 'client/components/core/modal/modal-portal';
import ProviderLoadingIcon from './provider-loading-icon';
import React, { type Node, useCallback } from 'react';
import config from 'config';
import logoSvg from 'client/assets/svg/apple-logo.svg';

/**
 * `Props` type.
 */

type Props = {|
  isModalVisible: boolean,
  onSignInFailure: ({ code: string }) => void,
  onSignInSuccess: AppleLoginResponse => void
|};

/**
 * `AppleSignInModal` component.
 */

function AppleSignInModal(props: Props): Node {
  const { isModalVisible, onSignInFailure, onSignInSuccess } = props;
  const handleCallback = useCallback(({ authorization, error }) => {
    if (authorization) {
      return onSignInSuccess({
        code: authorization.code,
        idToken: authorization.id_token
      });
    }

    return onSignInFailure({ code: camelCase(error?.error) });
  }, [onSignInFailure, onSignInSuccess]);

  return (
    <ModalPortal isOpen={isModalVisible}>
      <Modal isVisible={isModalVisible}>
        {isModalVisible && (
          <AppleLogin
            autoLoad
            callback={handleCallback}
            clientId={config.get('authProvider.apple.clientId')}
            render={() => (
              <ProviderLoadingIcon
                colors={'#000'}
                icon={logoSvg}
              />
            )}
            responseMode={'code id_token'}
            responseType={'form_post'}
            scope={'email name'}
            usePopup
          />
        )}
      </Modal>
    </ModalPortal>
  );
}

/**
 * Export `AppleSignInModal` component.
 */

export default AppleSignInModal;
