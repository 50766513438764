// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import { setAuthenticated } from './set-authenticated';
import { setPassword } from './set-password';

/**
 * `Values` type.
 */

type Values = {|
  confirmPassword: string,
  password: string
|};

/**
 * Export `performSetPassword`.
 */

export const performSetPassword = (values: Values) => (dispatch: Dispatch) => {
  return dispatch(setPassword(values)).then(() => {
    return dispatch(setAuthenticated());
  });
};
