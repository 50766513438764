// @flow

/**
 * Module dependencies.
 */

import { formats, schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  identity: {
    phoneNumber: phone
  }
} = schemaValidations;

/**
 * Export `contactInformationSchema`.
 */

export const contactInformationSchema = {
  properties: {
    phone
  },
  required: ['phone'],
  type: 'object'
};

/**
 * Formats.
 */

const { phoneNumber } = formats;

/**
 * Export `validationOptions`.
 */

export const validationOptions = {
  formats: { phoneNumber }
};
