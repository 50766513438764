// @flow

/**
 * Module dependencies.
 */

import { createRequestActionTypes } from 'core-redux/request';

/**
 * Export `submitKycType`.
 */

export const submitKycType = 'SUBMIT_KYC';

/**
 * Export `submitKycTypes`.
 */

export const submitKycTypes = createRequestActionTypes({
  method: 'POST',
  type: submitKycType
});
