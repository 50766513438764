// @flow

/**
 * Module dependencies.
 */

import { Button } from 'pmint-design-system';
import { useFormActions, useFormState } from '@seegno/react-forms';
import React, { type Node, useEffect } from 'react';
import backIcon from 'client/assets/svg/back-32px.svg';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  isVisible: boolean,
  onRequestClose: () => void
|};

/**
 * `CancelButton` component.
 */

const CancelButton = ({ isVisible, onRequestClose }: Props): Node => {
  const { isSubmitting, meta } = useFormState();
  const { touched } = meta;
  const { translate } = useTranslate();
  const formActions = useFormActions();

  useEffect(() => {
    if (!isVisible && touched) {
      const timeout = setTimeout(() => {
        return formActions.reset();
      }, 200);

      return () => clearTimeout(timeout);
    }
  }, [formActions, isVisible, touched]);

  return (
    <Button
      disabled={isSubmitting}
      fullWidth
      icon={backIcon}
      onClick={onRequestClose}
    >
      {translate('common.actions.cancel')}
    </Button>
  );
};

/**
 * Export `CancelButton` component.
 */

export default CancelButton;
