// @flow

/**
 * Module dependencies.
 */

import { Type, color } from 'pmint-design-system';
import { isUserAuthenticated } from 'client/core/redux/selectors/authentication';
import { useSelector } from 'react-redux';
import IdentityInformationForm from 'client/components/register/identity-information-form';
import React, { type Node } from 'react';
import RegisterLayout from 'client/components/register/register-layout';
import routes from 'core/routes';
import useTranslate from 'client/hooks/use-translate';

/**
 * `IdentityInformation` container.
 */

const IdentityInformation = (): Node => {
  const { translate } = useTranslate();
  const isAuthenticated = useSelector(isUserAuthenticated);
  const progressBarItems = [{
    label: translate('progressBar.register.identityInformation'),
    state: 'progress'
  }, {
    label: translate('progressBar.register.taxInformation'),
    state: 'initial'
  }, {
    label: translate('progressBar.register.identityVerification'),
    state: 'initial'
  }];

  return (
    <RegisterLayout
      progressBarItems={progressBarItems}
      to={isAuthenticated ? routes.profile.base : routes.signIn.base}
    >
      <Type.Label
        as={'h1'}
        color={color('textColor')}
        fontWeight={500}
        marginBottom={'17px'}
      >
        {translate('register.identityInformationTitle')}
      </Type.Label>

      <Type.Paragraph
        color={color('textColorLight')}
        marginBottom={'29px'}
      >
        {translate('register.identityInformationSubTitle')}
      </Type.Paragraph>

      <IdentityInformationForm />
    </RegisterLayout>
  );
};

/**
 * Export `IdentityInformation` container.
 */

export default IdentityInformation;
