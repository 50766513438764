// @flow

/**
 * Module dependencies.
 */

import { schemaValidations } from 'pmint_core_validator';

/**
 * Schema validations.
 */

const {
  identity: {
    countryCode,
    postalCodeUS,
    stateCodeUS,
    taxIdNumberUS
  }
} = schemaValidations;

/**
* Export `taxInformationSchema`.
*/

export default {
  allOf: [{
    if: {
      properties: {
        'address.country': { const: 'US' }
      }
    },
    then: {
      properties: {
        'address.postalCode': postalCodeUS,
        'address.state': stateCodeUS
      },
      required: ['address.state']
    }
  }, {
    if: {
      properties: {
        'tax.country': { const: 'US' }
      }
    },
    then: {
      properties: {
        'tax.idNumber': taxIdNumberUS,
        'tax.state': stateCodeUS
      },
      required: ['tax.state']
    }
  }],
  properties: {
    'address.city': {
      type: 'string'
    },
    'address.country': countryCode,
    'address.postalCode': { type: 'string' },
    'address.state': { type: 'string' },
    'address.street': { type: 'string' },
    'address.street2': { type: 'string' },
    'tax.country': countryCode,
    'tax.idNumber': { type: 'string' },
    'tax.state': { type: 'string' }
  },
  required: [
    'address.city',
    'address.country',
    'address.postalCode',
    'address.street',
    'tax.country',
    'tax.idNumber'
  ]
};
