// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { setPasswordType } from 'client/core/redux/action-types/set-password';

/**
 * Export `setPassword`.
 */

export default combineReducers(createRequestReducers({
  method: 'PATCH',
  type: setPasswordType
}));
