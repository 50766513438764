// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { updateDepositType } from 'client/core/redux/action-types/update-deposit';

/**
 * Export `updateDeposit`.
 */

export default combineReducers(createRequestReducers({
  method: 'PATCH',
  type: updateDepositType
}));
