// @flow

/**
 * Module dependencies.
 */

import type { User } from 'client/core/types/user';

/**
 * `AccountState` type.
 */

type AccountState = {
  isFailed: boolean,
  isSuccessful: boolean
};

/**
 * Export `PollingState` type.
 */

export type PollingState = 'pending' | 'verified' | 'error' | 'warning';

/**
 * Export `getIdentityState`.
 */

export function getIdentityState(user: User, accountState: AccountState): PollingState {
  const { isFailed, isSuccessful } = accountState;

  if (isFailed) {
    return 'error';
  }

  if (isSuccessful && user.identityConfirmed) {
    return 'verified';
  }

  return 'pending';
}

/**
 * Export `getEmailState`.
 */

export function getEmailState(user: User, accountState: AccountState): PollingState {
  const { isFailed, isSuccessful } = accountState;

  if (isFailed) {
    return 'error';
  }

  if (isSuccessful && user.emailConfirmed) {
    return 'verified';
  }

  return 'pending';
}
