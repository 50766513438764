// @flow

/**
 * Module dependencies.
 */

import { useFieldActions, useFieldState } from '@seegno/react-forms';
import React, { type Node, useEffect } from 'react';
import StateField from './state-field';

/**
 * `Props` type.
 */

type Props = {|
  countryFieldName: string,
  disabled: boolean,
  stateFieldName: string
|};

/**
 * `AddressStateField` component.
 */

const AddressStateField = (props: Props): Node => {
  const { countryFieldName, disabled, stateFieldName } = props;
  const { value } = useFieldState('tax.state');
  const fieldActions = useFieldActions();

  useEffect(() => {
    if (value) {
      return fieldActions.setFieldValue('address.state', value);
    }
  }, [fieldActions, value]);

  return (
    <StateField
      countryFieldName={countryFieldName}
      disabled={disabled}
      stateFieldName={stateFieldName}
    />
  );
};

/**
 * Export `AddressStateField` component.
 */

export default AddressStateField;
