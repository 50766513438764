// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { confirmEmailType } from 'client/core/redux/action-types/confirm-email';
import { createRequestReducers } from 'core-redux/request';

/**
 * Export `confirmEmail`.
 */

export default combineReducers(createRequestReducers({
  type: confirmEmailType
}));
