// @flow

/**
 * Module dependencies.
 */

import { media, units } from 'pmint-design-system';
import Form from 'client/components/core/forms/form';
import PasswordField from 'client/components/core/forms/fields/password';
import PasswordValidation from './password-validation';
import React, { type Node } from 'react';
import SubmitButton from 'client/components/core/forms/submit-button';
import setPasswordSchema from 'client/components/set-password/set-password-schema';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {
  onSubmit: { confirmPassword: string, password: string } => Promise<any>
};

/**
 * Initial values.
 */

const initialValues = {
  confirmPassword: '',
  password: ''
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: ${units(4)};

  ${media.min('md')`
    margin-bottom: ${units(4)};
  `}
`;

/**
 * `StyledSubmitButton` styled component.
 */

const StyledSubmitButton = styled(SubmitButton)`
  grid-column: 1 / -1;

  ${media.min('sm')`
    grid-column: 3 / -1;
  `}
`;

/**
 * `SetPasswordForm` component.
 */

const SetPasswordForm = ({ onSubmit }: Props): Node => {
  const { translate } = useTranslate();

  return (
    <Form
      errorsBasePath={'setPassword.form.errors'}
      initialValues={initialValues}
      jsonSchema={setPasswordSchema}
      onSubmit={onSubmit}
    >
      <PasswordField
        label={translate('setPassword.form.fields.password')}
        name={'password'}
      />

      <PasswordField
        label={translate('setPassword.form.fields.confirmPassword')}
        name={'confirmPassword'}
      />

      <PasswordValidation name={'password'} />

      <Wrapper>
        <StyledSubmitButton>
          {translate('setPassword.form.buttonLabel')}
        </StyledSubmitButton>
      </Wrapper>
    </Form>
  );
};

/**
 * Export `SetPasswordForm` component.
 */

export default SetPasswordForm;
