// @flow

/**
 * Module dependencies.
 */

import {
  createRequestAction,
  createResetRequestAction
} from 'core-redux/request';

import { signInType } from 'client/core/redux/action-types/sign-in';

/**
 * `Options` type.
 */

type Options = {
  email: string,
  password: string
};

/**
 * Export `signIn`.
 */

export const signIn = ({ email, password }: Options) => {
  return createRequestAction({
    apiName: 'account',
    data: { email, password },
    endpoint: 'createToken',
    handleResponse: ({ data }) => data,
    method: 'POST',
    type: signInType
  });
};

/**
 * Export `resetSignIn`.
 */

export const resetSignIn = createResetRequestAction({
  method: 'POST',
  type: signInType
});
