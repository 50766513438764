// @flow

/**
 * Module dependencies.
 */

import { Icon, Loader, Type, color, units } from 'pmint-design-system';
import { type PollingState } from 'client/core/utils/user-state';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import errorIcon from 'client/assets/svg/error-24px.svg';
import styled from 'styled-components';
import successIcon from 'client/assets/svg/success-24px.svg';

/**
 * `Props` type.
 */

type Props = {
  children: Node,
  icon: string,
  state: PollingState
};

/**
 * `PollingItemWrapper` styled component.
 */

const PollingItemWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

/**
 * `PollingIconWrapper` styled component.
 */

const PollingIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${units(5)};
  position: relative;
`;

/**
 * `PollingLoaderWrapper` styled component.
 */

const PollingLoaderWrapper = styled.div`
  align-items: center;
  background-color: ${color('white')};
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 4px 4px 0 rgba(105, 122, 137, 0.05), 0 6px 18px 0 rgba(105, 122, 137, 0.1), 0 0 1px 0 rgba(105, 122, 137, 0.5);
  display: flex;
  height: ${units(5)};
  justify-content: center;
  left: 50%;
  position: absolute;
  transform: translate(-50%, 25%);
  width: ${units(5)};
`;

/**
 * Get icon color.
 */

function getIconColor(state) {
  switch (state) {
    case 'warning':
      return color('warning');

    case 'verified':
      return color('bender1');

    case 'error':
      return color('errorDark');

    default:
      return color('textColorLight');
  }
}

/**
 * `AccountPollingItem` component.
 */

const AccountPollingItem = ({ children, icon, state }: Props): Node => (
  <PollingItemWrapper>
    <PollingIconWrapper>
      <Icon
        block
        color={getIconColor(state)}
        icon={icon}
        size={units(12)}
      />

      <PollingLoaderWrapper>
        {state === 'pending' || state === 'warning' ? <Loader /> : (
          <Icon
            color={getIconColor(state)}
            icon={state === 'error' ? errorIcon : successIcon}
            size={units(3)}
          />
        )}
      </PollingLoaderWrapper>
    </PollingIconWrapper>

    <RawHtml
      as={'p'}
      color={color('textColorLight')}
      element={Type.Small}
      margin={'0 auto'}
      maxWidth={units(16)}
    >
      {children}
    </RawHtml>
  </PollingItemWrapper>
);

/**
 * Export `AccountPollingItem` component.
 */

export default AccountPollingItem;
