// @flow

/**
 * Module dependencies.
 */

import type { KycDocument } from 'client/core/types/kyc-documents';
import { Type, color, units } from 'pmint-design-system';
import { getSubmitDocumentsState } from 'client/core/redux/selectors/submit-documents';
import { useSelector } from 'react-redux';
import DocumentIcon from './document-icon';
import IconButton from 'client/components/core/icon-button';
import React, { type Node } from 'react';
import deleteIcon from 'client/assets/svg/delete-24px.svg';
import styled from 'styled-components';
import useTranslate from 'client/hooks/use-translate';

/**
 * `Props` type.
 */

type Props = {|
  document: KycDocument,
  onDeleteDocument: (id: string) => void
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  background-color: ${color('gray200')};
  border-radius: ${units(1)};
  display: flex;
  padding: ${units(1)} ${units(1)} ${units(1)} ${units(2)};
`;

/**
 * `StyledDocumentIcon` styled component.
 */

const StyledDocumentIcon = styled(DocumentIcon)`
  flex-shrink: 0;
  margin-right: ${units(1)};
`;

/**
 * `Document` component.
 */

function Document({ document, onDeleteDocument }: Props): Node {
  const { isLoading } = useSelector(getSubmitDocumentsState);
  const { id, label, type } = document;
  const { translate } = useTranslate();

  return (
    <Wrapper>
      <StyledDocumentIcon
        color={color('gray600')}
        size={units(4)}
        type={type}
      />

      <Type.Paragraph
        flex={1}
        fontWeight={400}
      >
        {label}
      </Type.Paragraph>

      <IconButton
        aria-label={translate('kyc.documents.view.deleteDocumentLabel')}
        disabled={isLoading}
        icon={deleteIcon}
        onClick={() => onDeleteDocument(id)}
      />
    </Wrapper>
  );
}

/**
 * Export `Document` component.
 */

export default Document;
