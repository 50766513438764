// @flow

/**
 * Module dependencies.
 */

import { createRequestStateSelector } from 'core-redux/request';

/**
 * Export `getFetchDocumentsState`.
 */

export const getFetchDocumentsState = createRequestStateSelector('fetchDocuments');
