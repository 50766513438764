// @flow

/**
 * Module dependencies.
 */

import type { KycInformation } from 'client/core/types/kyc';
import { fetchUserInformationTypes } from 'client/core/redux/action-types/fetch-user-information';
import { updateKycTypes } from 'client/core/redux/action-types/update-kyc';

/**
 * Export `kyc`.
 */

export function kyc(state: KycInformation = {}, action: Object = {}): KycInformation {
  const { payload, type } = action;

  switch (type) {
    case fetchUserInformationTypes.successType:
    case updateKycTypes.successType:
      return {
        ...state,
        ...payload
      };

    default:
      return state;
  }
}

/**
 * Export `kyc`.
 */

export default kyc;
