// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { presignUrlType } from 'client/core/redux/action-types/upload-documents';

/**
 * Export `presignUrl`.
 */

export default combineReducers(createRequestReducers({
  method: 'GET',
  type: presignUrlType
}));
