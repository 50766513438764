// @flow

/**
 * Module dependencies.
 */

import { type Dispatch } from 'react-redux';
import type { KycSubmitDocument } from 'client/core/types/kyc-documents';
import { Polling } from 'client/core/utils/polling';
import { deleteDocumentTypes } from 'client/core/redux/action-types/delete-document';
import { fetchDocuments } from './fetch-documents';
import { isEmpty } from 'lodash';
import { submitDocuments } from './submit-documents';

/**
 * `Values` type.
 */

type Values = {|
  kycDocuments: Array<KycSubmitDocument>
|};

/**
 * Export `performSubmitDocuments`.
 */

export const performSubmitDocuments = (values: Values) => (dispatch: Dispatch) => {
  const handlePromise = async (resolve, reject) => {
    try {
      await dispatch(submitDocuments(values));

      const polling = new Polling(async retry => {
        const { value: documents } = await dispatch(fetchDocuments());
        const pending = documents.some(({ status }) => status === 'pending');
        const failed = documents.filter(({ status }) => status === 'failed');

        if (pending) {
          return retry();
        }

        if (!isEmpty(failed)) {
          // Delete from the UI, the failed ones.
          failed.forEach(document => dispatch({
            payload: document,
            type: deleteDocumentTypes.successType
          }));

          reject({ code: 'failed_to_upload' });
        }

        resolve(documents);
      });

      polling.start();
    } catch (error) {
      reject(error);
    }
  };

  return new Promise<Array<Object>>(handlePromise);
};
