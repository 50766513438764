// @flow

/**
 * Export `changeImageSchema`.
 */

export default {
  properties: {
    file: {
      type: 'object'
    }
  },
  required: ['file']
};
