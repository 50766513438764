// @flow

/**
 * Module dependencies.
 */

import { combineReducers } from 'redux';
import { createRequestReducers } from 'core-redux/request';
import { fetchAuthProvidersType } from 'client/core/redux/action-types/fetch-auth-providers';

/**
 * Export `fetchAuthProviders`.
 */

export default combineReducers(createRequestReducers({
  type: fetchAuthProvidersType
}));
