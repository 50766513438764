// @flow

/**
 * Module dependencies.
 */

import type { KycStatus, User } from 'client/core/types/user';
import { storageKeys } from 'core/utils/storage-keys';
import config from 'config';
import cookies from 'browser-cookies';
import yn from 'yn';

/**
 * `PreviousUserSession` type.
 */

type PreviousUserSession = {|
  email: string,
  identityProvider?: ?string,
  kycStatus: ?KycStatus,
  name: string
|};

/**
 * `SaveUserSessionOptions` type.
 */

type SaveUserSessionOptions = {|
  identityProvider: ?string,
  token: string,
  user: User | PreviousUserSession
|};

/**
 * `StorageOptions` type.
 */

type StorageOptions = {
  expires?: number | string | Date
};

/**
 * Export `cookiesStorage`.
 */

export const cookiesStorage = {
  get: (storageKey: string) => cookies.get(storageKey),
  remove: (storageKey: string) => cookies.erase(storageKey),
  set: (storageKey: string, storageValue: string, options: StorageOptions = {}) => {
    return cookies.set(storageKey, storageValue, {
      ...options,
      samesite: config.get('session.sameSite'),
      secure: yn(config.get('session.secure'))
    });
  }
};

/**
 * Export `saveUserSession`.
 */

export const saveUserSession = ({ identityProvider, token, user }: SaveUserSessionOptions) => {
  cookiesStorage.set(storageKeys.token, token);
  cookiesStorage.set(storageKeys.previousSession, JSON.stringify({
    email: user.email,
    identityProvider,
    kycStatus: user.kycStatus,
    name: user.name ?? user.email
  }));
};

/**
 * Export `removeUserSession`.
 */

export const removeUserSession = () => {
  cookiesStorage.remove(storageKeys.token);
  cookiesStorage.remove(storageKeys.previousSession);
};

/**
 * Export `getUserPreviousSession`.
 */

export const getUserPreviousSession = (): ?PreviousUserSession => {
  try {
    const previousSessionRawData = cookiesStorage.get(storageKeys.previousSession);

    if (previousSessionRawData) {
      return JSON.parse(previousSessionRawData);
    }

    return null;
  } catch {
    return null;
  }
};
